<app-headermenu></app-headermenu>

<div class="loading-ng-insurance">Loading&#8230;</div>
<div class="container payment-status">

  <div class="form-success-payment" *ngIf="paymentStatus == 1">
    <div class="title-payment">ชำระเงินสำเร็จ</div>
    <!-- <div class="header-detail-payment" [ngClass]="{'plan-2': insuranceType=='2'}"> -->
    <div class="header-detail-payment" [ngClass]="{'plan-4': insuranceType=='4'}">
      <img class="header-insurance-img" src="assets/images/header-insurance.png" alt="" [ngClass]="{'d-block': insuranceType == '1'}">
      <img class="header-insurance-img" src="assets/images/plan-home.png" alt="" [ngClass]="{'d-block': insuranceType == '2'}">
      <img class="header-insurance-img" src="assets/images/header-insurance.png" alt="" [ngClass]="{'d-block': insuranceType == '3'}">
      <img class="header-insurance-img" src="assets/images/header-insurance.png" alt="" [ngClass]="{'d-block': insuranceType == '4'}">
      <img class="header-insurance-img" src="assets/images/header-insurance.png" alt="" [ngClass]="{'d-block': insuranceType == '5'}">
      <div class="text-detail">
        <span>ประกันภัย</span>
        <span *ngIf="insuranceType == '1'">ให้เช่าหายห่วง</span>
        <!-- <span *ngIf="insuranceType == '1'" >เพียง 1,999 บาท</span> -->
        <span *ngIf="insuranceType == '2'">อยู่เองหายห่วง</span>
        <!-- <span *ngIf="insuranceType == '2'" >เพียง 999 บาท</span> -->
        <span *ngIf="insuranceType == '3'">ให้เช่าหายห่วง</span>
        <span *ngIf="insuranceType == '4'">ให้เช่าหายห่วง</span>
        <span *ngIf="insuranceType == '5'">ให้เช่าหายห่วง</span>
      </div>
      <img class="shield-header" src="assets/images/shield-h.png" alt="">
      <img class="person-header" src="assets/images/person-msig.png" alt="">
    </div>
    <div class="payment-price">
      <span class="price" *ngIf="insuranceType == '1'">1,999 <span>บาท</span></span>
      <span class="price" *ngIf="insuranceType == '2'">999 <span>บาท</span></span>
      <span class="price" *ngIf="insuranceType == '3'">2,199 <span>บาท</span></span>
      <span class="price" *ngIf="insuranceType == '4'">3,399 <span>บาท</span></span>
      <span class="price" *ngIf="insuranceType == '5'">5,199 <span>บาท</span></span>
    </div>
    <div class="payment-desrciption">
      <span>ขอบคุณที่สนใจทำประกันภัย</span>
      <span *ngIf="insuranceType == '1'">“ให้เช่าหายห่วง”</span>
      <span *ngIf="insuranceType == '2'">อยู่เองหายห่วง</span>
    </div>
    <div class="payment-other" *ngIf="office_status == '0'">
      ทาง MSIG จะดำเนินการส่งกรมธรรม์ ไปยังอีเมลของท่านตามที่ได้ระบุไว้ภายใน 20 นาที
    </div>
    <!-- <div class="payment-other" *ngIf="office_status == '1'">
      ทาง MSIG จะดำเนินการส่งกรมธรรม์
      ไปยังอีเมลและที่อยู่ของท่านตามที่ได้ระบุไว้ ภายใน 3 วันทำการ
    </div> -->
    <div class="payment-contact">
      <span>หากไม่ได้รับการติดต่อกรุณาติดต่อ</span>
      <div class="contact-list">
        <div class="list"><img src="assets/images/phone.png" alt="Image">&nbsp;<a href="tel:+088 554 0725">088 554 0725</a></div>
        <div class="list"><img src="assets/images/line.png" alt="Image"> @Livinginsider</div>
      </div>
    </div>
  </div>

  <div class="form-error-payment" *ngIf="paymentStatus == 0">
    <div class="title-payment">ขออภัย ระบบเกิดข้อผิดพลาด</div>
    <div class="error-msg">
      {{ errorPayment }}
      <span style="width: 100%;display: block;">{{ errorShort }}</span>
    </div>
    <div class="image-payment-error">
      <img src="assets/images/payment-error.png" alt="Image">
    </div>
    <div class="payment-again" *ngIf="paymentAgain == 1">
      <button (click)="formPaymentApiAgain()">ชำระเงินใหม่อีกครั้ง</button>
    </div>
    <div class="payment-contact">
      <span>กรุณาติดต่อ</span>
      <div class="contact-list">
        <div class="list"><img src="assets/images/phone.png" alt="Image">&nbsp;<a href="tel:+088 554 0725">088 554 0725</a></div>
        <div class="list"><img src="assets/images/line.png" alt="Image"> @Livinginsider</div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

<app-modals [insuranceType]="insuranceTypeModal"></app-modals>

<router-outlet></router-outlet>
