<app-headermenu></app-headermenu>
<app-buy-insurance [insuranceType]="insuranceType"></app-buy-insurance>

<!-- ********* Home Banner Area Start ********* -->
<section id="home-page" class="home_banner_area">
  <a class="popup-youtube popup-video"  href="https://www.youtube.com/watch?v=PtpxysnXjOg" style="display:none;"></a>
  <!-- <video id="video" autoplay="true" loop muted="true" class="video-background" onloadedmetadata="this.muted = true">
    <source src="assets/videos/MSIG JumaJu_Cut For Web 25fps.mp4" type="video/mp4">
    <source src="assets/videos/MSIG JumaJu_Cut For Web 25fps.ogg" type="video/ogg">
  </video> -->

  <!-- insurance-banner -->

  <img class="banner-background d-none d-md-block" src="assets/images/MSIG_BANNERR1.jpg" data-d="desktop" alt="banner">
  <img class="banner-background d-block d-md-none" src="assets/images/MSIG_BANNERR1_MB.jpg" data-d="mobile" alt="banner">
  <!-- <div class="overlay-banner"></div> -->
  <div class="box-play-video">
    <div class="box-button-play" (click)="handlePlayVideo(0)">
      <img src="assets/images/icon_play1.png" alt="">
    </div>
  </div>
  <!-- <div class="banner_inner">
    <div class="container">

      <div class="row align-items-center">
        <div class="col-lg-12">
          <div class="banner_content">
            <h1>ประกันภัย<span class="highlight d-block">“ให้เช่าหายห่วง”</span>เริ่มต้นเพียง <span class="highlight">2,199</span> บาท/ปี
            </h1>
            <p class="detail-content">คุ้มครองทันทีที่ซื้อ ไม่ต้องตรวจสภาพทรัพย์สิน</p>
            <div>
              <a href="#plan-insurance-msig"  class="btn btn-cash btn-block plan-btn-insurance">สนใจซื้อ <i class="fa fa-angle-right" aria-hidden="true"></i> </a>
            </div>
            <div class="block-img-logo">
              <div class="img-item">
                <img src="assets/images/web-living-icon.png" alt="">
              </div>
              <div class="img-item">
                <img src="assets/images/msig-icon.png" alt="">
              </div>
            </div>
            <div class="button-play" (click)="handlePlayVideo(0)">
              <img src="assets/images/play-button.png" alt="">
              <span>เล่นวีดีโอ</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</section>

<!-- ********* Home Banner Area End ********* -->

<!-- ********* Welcome Area Start ********* -->
<section class="welcome_area" id="welcome_area">
  <div class="container">
    <div class="d-flex justify-content-center align-items-center bigbanner">
      <div class="block-img">
        <img src="assets/images/insurance-warranty.png" alt="">
      </div>
      <div class="block-detail">
        <p>
          <span>“คุ้มครองทันทีที่ซื้อ</span>
          <span>ไม่ต้องตรวจสภาพทรัพย์สิน</span>
          <span>สูงสุดถึง 500,000 บาท*“</span>
        </p>
      </div>
    </div>
    <!-- <div class="row block-warranty">
      <div class="col-12">
        <p class="title-header"><img src="assets/images/shield.png"> ความคุ้มครอง</p>
      </div>
      <div class="col-md-12 ">
        <div class="txt-content">
          คุ้มครองความเสียหายทั้งสิ่งปลูกสร้างตัวอาคาร และทรัพย์สินภายในสิ่งปลูกสร้างจากไฟไหม้ ฟ้าผ่า ภัยระเบิด ภัยเนื่องจากน้ำ ภัยต่อเครื่องไฟฟ้า ภัยโจรกรรม ภัยต่อกระจก
          ความรับผิดต่อบุคคลภายนอก อุบัติเหตุส่วนบุคคล และภัยอื่นๆ
        </div>
      </div>
    </div> -->

    <div class="list-card-mobile">
      <!-- <p class="title-header">ความคุ้มครอง</p> -->
      <div class="row list-item">
        <div class="col-4" *ngFor="let protect of protects">
          <div style="float: left;"><img src="assets/images/protect.png"></div>
          <p>{{protect.name}}</p>
        </div>
      </div>
    </div>


    <div class="row no-gutters list-card list-card-top">
      <div class="card-living {{(protect.bg_color)?'card-living-bg':''}}" *ngFor="let protect of protects">
        <div class="icon"><span class=""><img src="{{protect.img}}"></span></div>
          <div class="desc">
            <h5 class="mt-3 mb-2">{{protect.name}}</h5>
          </div>
      </div>
    </div>

    <p class="condition-insurance">*ภายใต้กฎเกณฑ์การพิจารณารับประกันของบริษัท</p>

  </div>
</section>
<!-- ********* Welcome Area End ********* -->

<!-- ********* explain Area Start ********* -->
<!-- <section class="explain_area" id="plan-insurance">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="" style="margin-bottom: 40px;">
          <p class="plan-insurance-online title-header">แผนประกันภัยออนไลน์</p>
        </div>
      </div>
    </div>
    <div class="expand-insurance-type">
      <div class="insurance-type1 insurance-type" style="">
        <div class="block-detail">
          <img src="assets/images/shield-big.png" alt="Image" style="margin-top: -60px;" class="float-right">
          <div class="text-left insurance-header">
            <p>ประกันภัย</p>
            <p class="insurance-highlight">"ให้เช่าหายห่วง"</p>
            <p>เพียง<span class="insurance-highlight-second"> 1,999 </span>บาท</p>
          </div>
          <div class="insurance-detail">
            <p class="title">คุ้มครองทันทีที่ซื้อ ไม่ต้องตรวจสภาพทรัพย์สิน</p>
            <p>
              ความคุ้มครองมากกว่า ใช้ได้ทั้งบ้านและคอนโด ครอบคุมทั้ง
  ภัยธรรมชาติ อัคคีภัย การโจรกรรมทรัพย์สิน และความเสียหายของเฟอร์นิเจอร์ที่เกิดจากความประมาทของผู้เช่า สูงสุดถึง 200,000 บาท*
            </p>
          </div>
        </div>
        <div class="insurance-button">
          <button type="button" class=" btn btn-block btn-cash btn-success-primary font-17" id="btn-insurance-type1" (click)="handleDetailInsurance(1,1)">
            สนใจซื้อ <i class="fa fa-angle-right" aria-hidden="true"></i>
          </button>
          <button type="button" class="btn btn-block btn-cash2" (click)="handleDetailInsurance(1)">
            ความคุ้มครอง <i class="fa fa-angle-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div class="insurance-type2 insurance-type" style="">
        <div class="block-detail">
          <div class="text-left insurance-header">
            <p>ประกันภัย</p>
            <p class="insurance-highlight">"อยู่เองหายห่วง"</p>
            <p>เพียง<span class="insurance-highlight-second"> 999 </span>บาท</p>
          </div>
          <div class="insurance-detail">
            <p class="title">คุ้มครองทันทีที่ซื้อ ไม่ต้องตรวจสภาพทรัพย์สิน</p>
            <p>
              ปกป้องบ้านและคอนโดของคุณจากความเสี่ยงที่ไม่คาดฝัน อาจเกิดขึ้นได้จากไฟไหม้ หรือ ถูกโจรกรรมทรัพย์สินที่ให้ความคุ้มครองกับความเสี่ยงของทุกเหตุการณ์ เพื่อปกป้องความเสียหายของบ้านและทรัพย์สินที่คุณรัก
            </p>
          </div>
        </div>
        <div class="insurance-button">
          <button type="button" class="col-md-6 btn btn-cash btn-success-primary font-17" id="btn-insurance-type2" (click)="handleDetailInsurance(2,1)">
            สนใจซื้อ <i class="fa fa-angle-right" aria-hidden="true"></i>
          </button>
          <button type="button" class="col-md-6 btn btn-cash2" (click)="handleDetailInsurance(2)">
            ความคุ้มครอง <i class="fa fa-angle-right" aria-hidden="true"></i>
          </button>
      </div>
      </div>
    </div>
  </div>
</section> -->
<!-- ********* End explain Area ********* -->

<div id="plan-insurance-msig">
  <section class="explain_area_new plan-insurance-new">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="" style="margin-bottom: 40px;">
            <p class="plan-insurance-online title-header">แผนประกันภัยออนไลน์</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 main-plan-list-left">
          <div class="plan-list plan-list-left">
            <div class="plan-detail align-content-end">
              <span><img class="icon-shield" src="assets/images/Shield-1.png" alt="icon">รายละเอียดความคุ้มครอง</span>
            </div>
            <div class="plan-detail plan-border-top">
              <span>ไฟไหม้, ฟ้าผ่า, ภัยระเบิด, ภัยจากยานพาหนะ
                ภัยเนื่องจากน้ำ (ไม่รวมน้ำท่วม)</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" data-placement="right" title="ไฟไหม้ ฟ้าผ่า (รวมถึงความเสียหายต่อเครื่องใช้ไฟฟ้า และอุปกรณ์ไฟฟ้าที่เกิดจากการลัดวงจรจากฟ้าผ่า) ภัยระเบิด ภัยจากการเฉี่ยวและ/หรือการชนของยวดยานพาหนะ ภัยจากอากาศยาน และภัยเนื่องจากน้ำ (ไม่รวมน้ำท่วม) เช่น ก๊อกน้ำแตก แล้วน้ำไหลไปโดนทรัพย์สินในห้องเสียหาย, การรั่วซึมจากน้ำฝนที่ผ่านเข้ามาในห้องจนทำให้ทรัพย์สินเสียหาย">
                  <path d="M6.06001 6.00001C6.21675 5.55446 6.52611 5.17875 6.93331 4.93943C7.34052 4.70012 7.81927 4.61264 8.28479 4.69248C8.75032 4.77233 9.17255 5.01436 9.47673 5.3757C9.7809 5.73703 9.94738 6.19436 9.94668 6.66668C9.94668 8.00001 7.94668 8.66668 7.94668 8.66668M8.00001 11.3333H8.00668M14.6667 8.00001C14.6667 11.6819 11.6819 14.6667 8.00001 14.6667C4.31811 14.6667 1.33334 11.6819 1.33334 8.00001C1.33334 4.31811 4.31811 1.33334 8.00001 1.33334C11.6819 1.33334 14.6667 4.31811 14.6667 8.00001Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="plan-detail">
              <span>ภัยธรรมชาติ (น้ำท่วม ลมพายุ แผ่นดินไหว ภูเขาไฟระเบิด สึนามิ และลูกเห็บ)</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" data-placement="right" title="ภัยจากน้ำท่วม ภัยจากลมพายุ ภัยจากแผ่นดินไหวหรือภูเขาไฟระเบิดหรือคลี่นใต้น้ำหรือสึนามิ และภัยจากลูกเห็บ รวมกันทุกภัยต่อครั้งและต่อปี">
                <path d="M6.06001 6.00001C6.21675 5.55446 6.52611 5.17875 6.93331 4.93943C7.34052 4.70012 7.81927 4.61264 8.28479 4.69248C8.75032 4.77233 9.17255 5.01436 9.47673 5.3757C9.7809 5.73703 9.94738 6.19436 9.94668 6.66668C9.94668 8.00001 7.94668 8.66668 7.94668 8.66668M8.00001 11.3333H8.00668M14.6667 8.00001C14.6667 11.6819 11.6819 14.6667 8.00001 14.6667C4.31811 14.6667 1.33334 11.6819 1.33334 8.00001C1.33334 4.31811 4.31811 1.33334 8.00001 1.33334C11.6819 1.33334 14.6667 4.31811 14.6667 8.00001Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="plan-detail">
              <span>โจรกรรม (ลักทรัพย์ที่ปรากฏร่องรอยงัดแงะ ชิงทรัพย์ ปล้นทรัพย์)</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" data-placement="right" title="ความเสียหายต่อทรัพย์สินที่เก็บอยู่ภายในอาคาร หรือประตู-หน้าต่าง รวมถึงค่าใช้จ่ายในการเปลี่ยนกุญแจ เช่น มีการงัดแงะประตู/หน้าต่างเพื่อมาขโมยทรัพย์สินภายในบ้านหรือห้องชุด ทำให้ทรัพย์สินเสียหายหรือต้องเปลี่ยนอุปกรณ์การล็อคใหม่">
                <path d="M6.06001 6.00001C6.21675 5.55446 6.52611 5.17875 6.93331 4.93943C7.34052 4.70012 7.81927 4.61264 8.28479 4.69248C8.75032 4.77233 9.17255 5.01436 9.47673 5.3757C9.7809 5.73703 9.94738 6.19436 9.94668 6.66668C9.94668 8.00001 7.94668 8.66668 7.94668 8.66668M8.00001 11.3333H8.00668M14.6667 8.00001C14.6667 11.6819 11.6819 14.6667 8.00001 14.6667C4.31811 14.6667 1.33334 11.6819 1.33334 8.00001C1.33334 4.31811 4.31811 1.33334 8.00001 1.33334C11.6819 1.33334 14.6667 4.31811 14.6667 8.00001Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="plan-detail">
              <span>การเสียชีวิต บาดเจ็บหรือเจ็บป่วย และทรัพย์สินของบุคคลภายนอก*</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" data-placement="right" title="ความรับผิดตามกฎหมายสำหรับการเสียชีวิต การบาดเจ็บหรือเจ็บป่วย และความสูญเสียหรือเสียหายต่อทรัพย์สินของบุคคลภายนอก (ภายในสถานที่เอาประกันภัย) เช่น ของตกจากระเบียงไปโดนรถยนต์หรือร่างกายผู้อื่นบาดเจ็บ, น้ำรั่วจากห้องเราไปทำความเสียหายห้องผู้อื่น">
                <path d="M6.06001 6.00001C6.21675 5.55446 6.52611 5.17875 6.93331 4.93943C7.34052 4.70012 7.81927 4.61264 8.28479 4.69248C8.75032 4.77233 9.17255 5.01436 9.47673 5.3757C9.7809 5.73703 9.94738 6.19436 9.94668 6.66668C9.94668 8.00001 7.94668 8.66668 7.94668 8.66668M8.00001 11.3333H8.00668M14.6667 8.00001C14.6667 11.6819 11.6819 14.6667 8.00001 14.6667C4.31811 14.6667 1.33334 11.6819 1.33334 8.00001C1.33334 4.31811 4.31811 1.33334 8.00001 1.33334C11.6819 1.33334 14.6667 4.31811 14.6667 8.00001Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="plan-detail plan-detail-nth">
              <span>ความเสียหายของทรัพย์สินภายในห้องชุด/บ้านที่ทำการปล่อยเช่า**</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" data-placement="right" data-html="true" title="ทรัพย์สินภายในห้องชุด/บ้าน (ไม่รวมทรัพย์สินส่วนบุคคล) ที่ทำการปล่อยเช่า โดยมีรายการดังนี้<br>1. กลุ่มเครื่องนอน : เบาะนอน ผ้าห่ม หมอน
              ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีกขาด จากอุบัติเหตุ แต่ทั้งนี้ไม่รวมการเปรอะเปื้อนจากประจำเดือนของสุภาพสตรี
              <br>2. กลุ่มเครื่องตกแต่ง : โคมไฟ กรอบรูป ผ้าม่าน
              ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก จากอุบัติเหตุ
              <br>3. กลุ่มสิ่งอำนวยความสะดวก : โต๊ะกลาง (หน้าโซฟา) ,โต๊ะทานข้าว, เก้าอี้, โซฟา, เครื่องครัว, โต๊ะทำงาน, ชั้นวางทีวี
              ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก จากอุบัติเหตุ
              <br>4. กลุ่มเครื่องใช้ไฟฟ้า : โทรทัศน์ เครื่องซักผ้า ตู้เย็น ไมโครเวฟ
              ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก จากอุบัติเหตุ แต่ไม่รวมความเสียหายจากการขัดข้องของกลไกภายใน (เช่น ตู้เย็นคอมเพรสเซอร์เสีย น้ำยารั่ว ไม่เย็น หรือเครื่องซักผ้ามอเตอร์ไม่หมุน เป็นต้น )
              ทั้งนี้ ไม่รวมเฟอร์นิเจอร์แบบบิ้วอินที่มาพร้อมโครงการ">
                <path d="M6.06001 6C6.21675 5.55444 6.52611 5.17873 6.93331 4.93942C7.34052 4.7001 7.81927 4.61262 8.28479 4.69247C8.75032 4.77232 9.17255 5.01434 9.47673 5.37568C9.7809 5.73702 9.94738 6.19434 9.94668 6.66666C9.94668 8 7.94668 8.66666 7.94668 8.66666M8.00001 11.3333H8.00668M14.6667 8C14.6667 11.6819 11.6819 14.6667 8.00001 14.6667C4.31811 14.6667 1.33334 11.6819 1.33334 8C1.33334 4.3181 4.31811 1.33333 8.00001 1.33333C11.6819 1.33333 14.6667 4.3181 14.6667 8Z" stroke="#F5A623" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="plan-detail plan-detail-nth plan-border-bottom">
              <span>ความเสียหายของทรัพย์สินจากการกัดแทะของสัตว์เลี้ยง (สุนัขและแมว) ของผู้เช่า**</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" data-placement="right" data-html="true" title="คุ้มครองการกัดแทะทรัพย์สินภายในห้องชุด/บ้าน (ไม่รวมทรัพย์สินส่วนบุคคล) ที่ทำการปล่อยเช่า โดยมีรายการดังนี้
              <br>1. กลุ่มเครื่องนอน : เบาะนอน ผ้าห่ม หมอน
              <br>2. กลุ่มเครื่องตกแต่ง : โคมไฟ กรอบรูป ผ้าม่าน
              <br>3. กลุ่มสิ่งอำนวยความสะดวก : โต๊ะกลาง (หน้าโซฟา) ,โต๊ะทานข้าว, เก้าอี้, โซฟา, เครื่องครัว, โต๊ะทำงาน, ชั้นวางทีวี
              <br>4. กลุ่มเครื่องใช้ไฟฟ้า : โทรทัศน์ เครื่องซักผ้า ตู้เย็น ไมโครเวฟ
              <br>ทั้งนี้ ไม่รวมเฟอร์นิเจอร์แบบบิ้วอินที่มาพร้อมโครงการ">
                <path d="M6.06001 6C6.21675 5.55444 6.52611 5.17873 6.93331 4.93942C7.34052 4.7001 7.81927 4.61262 8.28479 4.69247C8.75032 4.77232 9.17255 5.01434 9.47673 5.37568C9.7809 5.73702 9.94738 6.19434 9.94668 6.66666C9.94668 8 7.94668 8.66666 7.94668 8.66666M8.00001 11.3333H8.00668M14.6667 8C14.6667 11.6819 11.6819 14.6667 8.00001 14.6667C4.31811 14.6667 1.33334 11.6819 1.33334 8C1.33334 4.3181 4.31811 1.33333 8.00001 1.33333C11.6819 1.33333 14.6667 4.3181 14.6667 8Z" stroke="#F5A623" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="plan-detail-footer d-flex align-items-center justify-content-center">
              <span>เบี้ยประกันภัยต่อปี</span>
            </div>
            <div class="plan-detail-footer">
              <span></span>
            </div>
          </div>
        </div>
        <div class="col-md-7 main-plan-list-right">
          <div class="row">
            <div class="col-md-4 pl-10-block pr-0 plan-list-3">
              <div class="plan-price plan-list">
                <div class="plan-detail">
                  <span class="text-1">แผนที่ 1</span>
                  <span class="text-2">จำนวนเงินเอาประกันภัย (บาท/ปี)</span>
                  <!-- <span class="text-3" (click)="handlePlayVideo(1);">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 12V4L12 8L6 12Z" fill="#04C825"/>
                    <circle cx="8" cy="8" r="7.5" stroke="#04C825"/>
                    </svg>
                    เล่นวิดิโอ
                  </span> -->
                </div>
                <div class="plan-detail d-flex align-items-center justify-content-center">
                  <span>200,000</span>
                </div>
                <div class="plan-detail d-flex align-items-center justify-content-center">
                  <span>20,000</span>
                </div>
                <div class="plan-detail d-flex align-items-center justify-content-center">
                  <span>20,000</span>
                </div>
                <div class="plan-detail d-flex align-items-center justify-content-center">
                  <span>50,000</span>
                </div>
                <div class="plan-detail plan-detail-nth d-flex align-items-center justify-content-center">
                  <span>50,000</span>
                </div>
                <div class="plan-detail plan-detail-nth d-flex align-items-center justify-content-center">
                  <span>10,000</span>
                </div>
                <div class="plan-detail total-price d-flex align-items-center justify-content-center" style="flex-direction: column;">
                  <span>2,199</span>
                  <span class="mt-2">บาท</span>
                </div>
                <div class="plan-detail btn-price" (click)="handleDetailInsurance(3);">
                  <span>เลือกแผนนี้</span>
                </div>
              </div>
            </div>
            <div class="col-md-4 pl-10-block pr-0 plan-price-sticky plan-list-4">
              <div class="plan-price">
                <div class="plan-price plan-list">
                  <div class="plan-detail plan-detail-sticky">
                    <span class="text-1">แผนที่ 2</span>
                    <span class="text-2">จำนวนเงินเอาประกันภัย (บาท/ปี)</span>
                    <!-- <span class="text-3" (click)="handlePlayVideo(2);">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 12V4L12 8L6 12Z" fill="white"/>
                        <circle cx="8" cy="8" r="7.5" stroke="white"/>
                      </svg>
                      เล่นวิดิโอ
                    </span> -->
                  </div>
                  <div class="plan-detail d-flex align-items-center justify-content-center">
                    <span>300,000</span>
                  </div>
                  <div class="plan-detail d-flex align-items-center justify-content-center">
                    <span>20,000</span>
                  </div>
                  <div class="plan-detail d-flex align-items-center justify-content-center">
                    <span>30,000</span>
                  </div>
                  <div class="plan-detail d-flex align-items-center justify-content-center">
                    <span>80,000</span>
                  </div>
                  <div class="plan-detail plan-detail-nth d-flex align-items-center justify-content-center">
                    <span>100,000</span>
                  </div>
                  <div class="plan-detail plan-detail-nth d-flex align-items-center justify-content-center">
                    <span>15,000</span>
                  </div>
                  <div class="plan-detail total-price d-flex align-items-center justify-content-center" style="flex-direction: column;">
                    <span>3,399</span>
                    <span class="mt-2">บาท</span>
                  </div>
                  <div class="plan-detail btn-price btn-price-sticky" (click)="handleDetailInsurance(4);">
                    <span>เลือกแผนนี้</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 pl-10-block pr-0 plan-list-5">
              <div class="plan-price">
                <div class="plan-price plan-list">
                  <div class="plan-detail">
                    <span class="text-1">แผนที่ 3</span>
                    <span class="text-2">จำนวนเงินเอาประกันภัย (บาท/ปี)</span>
                    <!-- <span class="text-3" (click)="handlePlayVideo(3);">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 12V4L12 8L6 12Z" fill="#04C825"/>
                      <circle cx="8" cy="8" r="7.5" stroke="#04C825"/>
                      </svg>
                      เล่นวิดิโอ
                    </span> -->
                  </div>
                  <div class="plan-detail d-flex align-items-center justify-content-center">
                    <span>500,000</span>
                  </div>
                  <div class="plan-detail d-flex align-items-center justify-content-center">
                    <span>20,000</span>
                  </div>
                  <div class="plan-detail d-flex align-items-center justify-content-center">
                    <span>50,000</span>
                  </div>
                  <div class="plan-detail d-flex align-items-center justify-content-center">
                    <span>150,000</span>
                  </div>
                  <div class="plan-detail plan-detail-nth d-flex align-items-center justify-content-center">
                    <span>150,000</span>
                  </div>
                  <div class="plan-detail plan-detail-nth d-flex align-items-center justify-content-center">
                    <span>30,000</span>
                  </div>
                  <div class="plan-detail total-price d-flex align-items-center justify-content-center" style="flex-direction: column;">
                    <span>5,199</span>
                    <span class="mt-2">บาท</span>
                  </div>
                  <div class="plan-detail btn-price" (click)="handleDetailInsurance(5);">
                    <span>เลือกแผนนี้</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="explain_area_new plan-insurance-new-mobile" id="plan-insurance-msig">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="" style="margin-bottom: 40px;">
            <p class="plan-insurance-online title-header">แผนประกันภัยออนไลน์</p>
          </div>
        </div>
      </div>

      <ngx-slick-carousel class="carousel card-plan" #slickModal="slick-carousel" [config]="slideConfig" >
        <div class="plan-mobile-list plan-list-3" ngxSlickItem>
          <div class="plan-title">
            <div class="plan-title-1">
              <span class="text-1">แผนที่ 1</span>
              <span class="text-2">จำนวนเงินเอาประกันภัย (บาท/ปี)</span>
            </div>
            <div class="plan-title-2">
              <span class="text-1" (click)="handlePlayVideo(1);">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 12V4L12 8L6 12Z" fill="#04C825"/>
                <circle cx="8" cy="8" r="7.5" stroke="#04C825"/>
                </svg>
                เล่นวิดิโอ
              </span>
            </div>
          </div>
          <div class="plan-des">
            <span>รายละเอียดความคุ้มครอง และ
              จำนวนเงินเอาประกันภัย (บาท/ปี)
            </span>
          </div>
          <div class="plan-des-list">
            <div class="plan-des-list-1">
              <span>ไฟไหม, ฟ้าผ่า, ภัยระเบิด, ภัยจากยานพาหนะ
                ภัยเนื่องจากน้ำ (ไม่รวมน้ำท่วม)</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" title="ไฟไหม้ ฟ้าผ่า (รวมถึงความเสียหายต่อเครื่องใช้ไฟฟ้า และอุปกรณ์ไฟฟ้าที่เกิดจากการลัดวงจรจากฟ้าผ่า) ภัยระเบิด ภัยจากการเฉี่ยวและ/หรือการชนของยวดยานพาหนะ ภัยจากอากาศยาน และภัยเนื่องจากน้ำ (ไม่รวมน้ำท่วม) เช่น ก๊อกน้ำแตก แล้วน้ำรั่วทำพื้นห้องเสียหาย, การรั่วซึมจากน้ำฝนที่ผ่านเข้าในห้องจนทำให้ทรัพย์สินเสียหาย">
                  <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="plan-des-list-2">
              <span>200,000</span>
            </div>
          </div>
          <div class="plan-des-list">
            <div class="plan-des-list-1">
              <span>ภัยธรรมชาติ (น้ำท่วม ลมพายุ แผ่นดินไหว ภูเขาไฟระเบิด สึนามิ และลูกเห็บ)</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" title="ภัยจากน้ำท่วม ภัยจากลมพายุ ภัยจากแผ่นดินไหวหรือภูเขาไฟระเบิดหรือคลี่นใต้น้ำหรือสึนามิ และภัยจากลูกเห็บ รวมกันทุกภัยต่อครั้งและต่อปี">
                  <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="plan-des-list-2">
              <span>20,000</span>
            </div>
          </div>
          <div class="plan-des-list">
            <div class="plan-des-list-1">
              <span>โจรกรรม(ลักทรัพย์ที่ปรากฏร่อง
                รอยงัดแงะ ชิงทรัพย์ ปล้นทรัพย์)</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" title="ความเสียหายต่อทรัพย์สินที่เก็บอยู่ภายในอาคาร หรือประตู-หน้าต่าง รวมถึงค่าใช้จ่ายในการเปลี่ยนกุญแจ เช่น มีการงัดแงะประตู/หน้าต่างเพื่อมาขโมยทรัพย์สินภายในบ้านหรือห้องชุด ทำให้ทรัพย์สินเสียหายหรือต้องเปลี่ยนอุปกรณ์การล็อคใหม่">
                  <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="plan-des-list-2">
              <span>20,000</span>
            </div>
          </div>
          <div class="plan-des-list">
            <div class="plan-des-list-1">
              <span>การเสียชีวิต บาดเจ็บหรือเจ็บป่วย และทรัพย์สินของบุคคลภายนอก*</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" title="ความรับผิดตามกฎหมายสำหรับการเสียชีวิต การบาดเจ็บหรือเจ็บป่วย และความสูญเสียหรือเสียหายต่อทรัพย์สินของบุคคลภายนอก (ภายในสถานที่เอาประกันภัย) เช่น ของตกจากระเบียงไปโดนรถยนต์หรือร่างกายผู้อื่นบาดเจ็บ, น้ำรั่วจากห้องเราไปทำความเสียหายห้องผู้อื่น">
                  <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="plan-des-list-2">
              <span>50,000</span>
            </div>
          </div>
          <div class="plan-des-list plan-des-list-nth">
            <div class="plan-des-list-1">
              <span>ความเสียหายของทรัพย์สินภายในห้องชุด/บ้านที่ทำการปล่อยเช่า**</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" data-html="true" title="ทรัพย์สินภายในห้องชุด/บ้าน (ไม่รวมทรัพย์สินส่วนบุคคล) ที่ทำการปล่อยเช่า โดยมีรายการดังนี้<br>1. กลุ่มเครื่องนอน : เบาะนอน ผ้าห่ม หมอน
                ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีกขาด จากอุบัติเหตุ แต่ทั้งนี้ไม่รวมการเปรอะเปื้อนจากประจำเดือนของสุภาพสตรี
                <br>2. กลุ่มเครื่องตกแต่ง : โคมไฟ กรอบรูป ผ้าม่าน
                ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก จากอุบัติเหตุ
                <br>3. กลุ่มสิ่งอำนวยความสะดวก : โต๊ะกลาง (หน้าโซฟา) ,โต๊ะทานข้าว, เก้าอี้, โซฟา, เครื่องครัว, โต๊ะทำงาน, ชั้นวางทีวี
                ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก จากอุบัติเหตุ
                <br>4. กลุ่มเครื่องใช้ไฟฟ้า : โทรทัศน์ เครื่องซักผ้า ตู้เย็น ไมโครเวฟ
                ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก จากอุบัติเหตุ แต่ไม่รวมความเสียหายจากการขัดข้องของกลไกภายใน (เช่น ตู้เย็นคอมเพรสเซอร์เสีย น้ำยารั่ว ไม่เย็น หรือเครื่องซักผ้ามอเตอร์ไม่หมุน เป็นต้น )
                ทั้งนี้ ไม่รวมเฟอร์นิเจอร์แบบบิ้วอินที่มาพร้อมโครงการ">
                <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#F5A623" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="plan-des-list-2">
              <span>50,000</span>
            </div>
          </div>
          <div class="plan-des-list plan-des-list-nth">
            <div class="plan-des-list-1">
              <span>ความเสียหายของทรัพย์สินจากการกัดแทะของ สัตว์เลี้ยง (สุนัขและแมว) ของผู้เช่า**</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" data-html="true" title="คุ้มครองการกัดแทะทรัพย์สินภายในห้องชุด/บ้าน (ไม่รวมทรัพย์สินส่วนบุคคล) ที่ทำการปล่อยเช่า โดยมีรายการดังนี้
              <br>1. กลุ่มเครื่องนอน : เบาะนอน ผ้าห่ม หมอน
              <br>2. กลุ่มเครื่องตกแต่ง : โคมไฟ กรอบรูป ผ้าม่าน
              <br>3. กลุ่มสิ่งอำนวยความสะดวก : โต๊ะกลาง (หน้าโซฟา) ,โต๊ะทานข้าว, เก้าอี้, โซฟา, เครื่องครัว, โต๊ะทำงาน, ชั้นวางทีวี
              <br>4. กลุ่มเครื่องใช้ไฟฟ้า : โทรทัศน์ เครื่องซักผ้า ตู้เย็น ไมโครเวฟ
              <br>ทั้งนี้ ไม่รวมเฟอร์นิเจอร์แบบบิ้วอินที่มาพร้อมโครงการ">
                <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#F5A623" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="plan-des-list-2">
              <span>10,000</span>
            </div>
          </div>
          <div class="plan-price">
            <span class="text-1">
              เบี้ยประกันภัยต่อปี
            </span>
            <span class="text-2">
              2,199
            </span>
          </div>
          <div class="plan-btn-price" (click)="handleDetailInsurance(3);">
            <span>เลือกแผนนี้</span>
          </div>
        </div>

        <div class="plan-mobile-list sticky plan-list-4" ngxSlickItem>
          <div class="plan-title">
            <div class="plan-title-1">
              <span class="text-1">แผนที่ 2</span>
              <span class="text-2">จำนวนเงินเอาประกันภัย (บาท/ปี)</span>
            </div>
            <div class="plan-title-2">
              <span class="text-1" (click)="handlePlayVideo(2);">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 12V4L12 8L6 12Z" fill="white"/>
                  <circle cx="8" cy="8" r="7.5" stroke="white"/>
                </svg>
                เล่นวิดิโอ
              </span>
            </div>
          </div>
          <div class="plan-des">
            <span>รายละเอียดความคุ้มครอง และ
              จำนวนเงินเอาประกันภัย (บาท/ปี)
            </span>
          </div>
          <div class="plan-des-list">
            <div class="plan-des-list-1">
              <span>ไฟไหม, ฟ้าผ่า, ภัยระเบิด, ภัยจากยานพาหนะ
                ภัยเนื่องจากน้ำ (ไม่รวมน้ำท่วม)</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" title="ไฟไหม้ ฟ้าผ่า (รวมถึงความเสียหายต่อเครื่องใช้ไฟฟ้า และอุปกรณ์ไฟฟ้าที่เกิดจากการลัดวงจรจากฟ้าผ่า) ภัยระเบิด ภัยจากการเฉี่ยวและ/หรือการชนของยวดยานพาหนะ ภัยจากอากาศยาน และภัยเนื่องจากน้ำ (ไม่รวมน้ำท่วม) เช่น ก๊อกน้ำแตก แล้วน้ำรั่วทำพื้นห้องเสียหาย, การรั่วซึมจากน้ำฝนที่ผ่านเข้าในห้องจนทำให้ทรัพย์สินเสียหาย">
                  <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="plan-des-list-2">
              <span>300,000</span>
            </div>
          </div>
          <div class="plan-des-list">
            <div class="plan-des-list-1">
              <span>ภัยธรรมชาติ (น้ำท่วม ลมพายุ แผ่นดินไหว ภูเขาไฟระเบิด สึนามิ และลูกเห็บ)</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" title="ภัยจากน้ำท่วม ภัยจากลมพายุ ภัยจากแผ่นดินไหวหรือภูเขาไฟระเบิดหรือคลี่นใต้น้ำหรือสึนามิ และภัยจากลูกเห็บ รวมกันทุกภัยต่อครั้งและต่อปี">
                  <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="plan-des-list-2">
              <span>20,000</span>
            </div>
          </div>
          <div class="plan-des-list">
            <div class="plan-des-list-1">
              <span>โจรกรรม(ลักทรัพย์ที่ปรากฏร่อง
                รอยงัดแงะ ชิงทรัพย์ ปล้นทรัพย์)</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" title="ความเสียหายต่อทรัพย์สินที่เก็บอยู่ภายในอาคาร หรือประตู-หน้าต่าง รวมถึงค่าใช้จ่ายในการเปลี่ยนกุญแจ เช่น มีการงัดแงะประตู/หน้าต่างเพื่อมาขโมยทรัพย์สินภายในบ้านหรือห้องชุด ทำให้ทรัพย์สินเสียหายหรือต้องเปลี่ยนอุปกรณ์การล็อคใหม่">
                  <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="plan-des-list-2">
              <span>30,000</span>
            </div>
          </div>
          <div class="plan-des-list">
            <div class="plan-des-list-1">
              <span>การเสียชีวิต บาดเจ็บหรือเจ็บป่วย และทรัพย์สินของบุคคลภายนอก*</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" title="ความรับผิดตามกฎหมายสำหรับการเสียชีวิต การบาดเจ็บหรือเจ็บป่วย และความสูญเสียหรือเสียหายต่อทรัพย์สินของบุคคลภายนอก (ภายในสถานที่เอาประกันภัย) เช่น ของตกจากระเบียงไปโดนรถยนต์หรือร่างกายผู้อื่นบาดเจ็บ, น้ำรั่วจากห้องเราไปทำความเสียหายห้องผู้อื่น">
                  <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="plan-des-list-2">
              <span>80,000</span>
            </div>
          </div>
          <div class="plan-des-list plan-des-list-nth">
            <div class="plan-des-list-1">
              <span>ความเสียหายของทรัพย์สินภายในห้องชุด/บ้านที่ทำการปล่อยเช่า**</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" data-html="true" title="ทรัพย์สินภายในห้องชุด/บ้าน (ไม่รวมทรัพย์สินส่วนบุคคล) ที่ทำการปล่อยเช่า โดยมีรายการดังนี้<br>1. กลุ่มเครื่องนอน : เบาะนอน ผ้าห่ม หมอน
                ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีกขาด จากอุบัติเหตุ แต่ทั้งนี้ไม่รวมการเปรอะเปื้อนจากประจำเดือนของสุภาพสตรี
                <br>2. กลุ่มเครื่องตกแต่ง : โคมไฟ กรอบรูป ผ้าม่าน
                ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก จากอุบัติเหตุ
                <br>3. กลุ่มสิ่งอำนวยความสะดวก : โต๊ะกลาง (หน้าโซฟา) ,โต๊ะทานข้าว, เก้าอี้, โซฟา, เครื่องครัว, โต๊ะทำงาน, ชั้นวางทีวี
                ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก จากอุบัติเหตุ
                <br>4. กลุ่มเครื่องใช้ไฟฟ้า : โทรทัศน์ เครื่องซักผ้า ตู้เย็น ไมโครเวฟ
                ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก จากอุบัติเหตุ แต่ไม่รวมความเสียหายจากการขัดข้องของกลไกภายใน (เช่น ตู้เย็นคอมเพรสเซอร์เสีย น้ำยารั่ว ไม่เย็น หรือเครื่องซักผ้ามอเตอร์ไม่หมุน เป็นต้น )
                ทั้งนี้ ไม่รวมเฟอร์นิเจอร์แบบบิ้วอินที่มาพร้อมโครงการ">
                <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#F5A623" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="plan-des-list-2">
              <span>100,000</span>
            </div>
          </div>
          <div class="plan-des-list plan-des-list-nth">
            <div class="plan-des-list-1">
              <span>ความเสียหายของทรัพย์สินจากการกัดแทะของ สัตว์เลี้ยง (สุนัขและแมว) ของผู้เช่า**</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" data-html="true" title="คุ้มครองการกัดแทะทรัพย์สินภายในห้องชุด/บ้าน (ไม่รวมทรัพย์สินส่วนบุคคล) ที่ทำการปล่อยเช่า โดยมีรายการดังนี้
              <br>1. กลุ่มเครื่องนอน : เบาะนอน ผ้าห่ม หมอน
              <br>2. กลุ่มเครื่องตกแต่ง : โคมไฟ กรอบรูป ผ้าม่าน
              <br>3. กลุ่มสิ่งอำนวยความสะดวก : โต๊ะกลาง (หน้าโซฟา) ,โต๊ะทานข้าว, เก้าอี้, โซฟา, เครื่องครัว, โต๊ะทำงาน, ชั้นวางทีวี
              <br>4. กลุ่มเครื่องใช้ไฟฟ้า : โทรทัศน์ เครื่องซักผ้า ตู้เย็น ไมโครเวฟ
              <br>ทั้งนี้ ไม่รวมเฟอร์นิเจอร์แบบบิ้วอินที่มาพร้อมโครงการ">
                <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#F5A623" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="plan-des-list-2">
              <span>15,000</span>
            </div>
          </div>
          <div class="plan-price">
            <span class="text-1">
              เบี้ยประกันภัยต่อปี
            </span>
            <span class="text-2">
              3,399
            </span>
          </div>
          <div class="plan-btn-price" (click)="handleDetailInsurance(4);">
            <span>เลือกแผนนี้</span>
          </div>
        </div>

        <div class="plan-mobile-list plan-list-5" ngxSlickItem>
          <div class="plan-title">
            <div class="plan-title-1">
              <span class="text-1">แผนที่ 3</span>
              <span class="text-2">จำนวนเงินเอาประกันภัย (บาท/ปี)</span>
            </div>
            <div class="plan-title-2">
              <span class="text-1" (click)="handlePlayVideo(3);">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 12V4L12 8L6 12Z" fill="#04C825"/>
                <circle cx="8" cy="8" r="7.5" stroke="#04C825"/>
                </svg>
                เล่นวิดิโอ
              </span>
            </div>
          </div>
          <div class="plan-des">
            <span>รายละเอียดความคุ้มครอง และ
              จำนวนเงินเอาประกันภัย (บาท/ปี)
            </span>
          </div>
          <div class="plan-des-list">
            <div class="plan-des-list-1">
              <span>ไฟไหม, ฟ้าผ่า, ภัยระเบิด, ภัยจากยานพาหนะ
                ภัยเนื่องจากน้ำ (ไม่รวมน้ำท่วม)</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" title="ไฟไหม้ ฟ้าผ่า (รวมถึงความเสียหายต่อเครื่องใช้ไฟฟ้า และอุปกรณ์ไฟฟ้าที่เกิดจากการลัดวงจรจากฟ้าผ่า) ภัยระเบิด ภัยจากการเฉี่ยวและ/หรือการชนของยวดยานพาหนะ ภัยจากอากาศยาน และภัยเนื่องจากน้ำ (ไม่รวมน้ำท่วม) เช่น ก๊อกน้ำแตก แล้วน้ำรั่วทำพื้นห้องเสียหาย, การรั่วซึมจากน้ำฝนที่ผ่านเข้าในห้องจนทำให้ทรัพย์สินเสียหาย">
                  <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="plan-des-list-2">
              <span>500,000</span>
            </div>
          </div>
          <div class="plan-des-list">
            <div class="plan-des-list-1">
              <span>ภัยธรรมชาติ (น้ำท่วม ลมพายุ แผ่นดินไหว ภูเขาไฟระเบิด สึนามิ และลูกเห็บ)</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" title="ภัยจากน้ำท่วม ภัยจากลมพายุ ภัยจากแผ่นดินไหวหรือภูเขาไฟระเบิดหรือคลี่นใต้น้ำหรือสึนามิ และภัยจากลูกเห็บ รวมกันทุกภัยต่อครั้งและต่อปี">
                  <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="plan-des-list-2">
              <span>20,000</span>
            </div>
          </div>
          <div class="plan-des-list">
            <div class="plan-des-list-1">
              <span>โจรกรรม(ลักทรัพย์ที่ปรากฏร่อง
                รอยงัดแงะ ชิงทรัพย์ ปล้นทรัพย์)</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" title="ความเสียหายต่อทรัพย์สินที่เก็บอยู่ภายในอาคาร หรือประตู-หน้าต่าง รวมถึงค่าใช้จ่ายในการเปลี่ยนกุญแจ เช่น มีการงัดแงะประตู/หน้าต่างเพื่อมาขโมยทรัพย์สินภายในบ้านหรือห้องชุด ทำให้ทรัพย์สินเสียหายหรือต้องเปลี่ยนอุปกรณ์การล็อคใหม่">
                  <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="plan-des-list-2">
              <span>50,000</span>
            </div>
          </div>
          <div class="plan-des-list">
            <div class="plan-des-list-1">
              <span>การเสียชีวิต บาดเจ็บหรือเจ็บป่วย และทรัพย์สินของบุคคลภายนอก*</span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" title="ความรับผิดตามกฎหมายสำหรับการเสียชีวิต การบาดเจ็บหรือเจ็บป่วย และความสูญเสียหรือเสียหายต่อทรัพย์สินของบุคคลภายนอก (ภายในสถานที่เอาประกันภัย) เช่น ของตกจากระเบียงไปโดนรถยนต์หรือร่างกายผู้อื่นบาดเจ็บ, น้ำรั่วจากห้องเราไปทำความเสียหายห้องผู้อื่น">
                  <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="plan-des-list-2">
              <span>150,000</span>
            </div>
          </div>
          <div class="plan-des-list plan-des-list-nth">
            <div class="plan-des-list-1">
              <span>ความเสียหายของทรัพย์สินภายในห้องชุด/บ้านที่ทำการปล่อยเช่า**</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" data-html="true" title="ทรัพย์สินภายในห้องชุด/บ้าน (ไม่รวมทรัพย์สินส่วนบุคคล) ที่ทำการปล่อยเช่า โดยมีรายการดังนี้<br>1. กลุ่มเครื่องนอน : เบาะนอน ผ้าห่ม หมอน
                ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีกขาด จากอุบัติเหตุ แต่ทั้งนี้ไม่รวมการเปรอะเปื้อนจากประจำเดือนของสุภาพสตรี
                <br>2. กลุ่มเครื่องตกแต่ง : โคมไฟ กรอบรูป ผ้าม่าน
                ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก จากอุบัติเหตุ
                <br>3. กลุ่มสิ่งอำนวยความสะดวก : โต๊ะกลาง (หน้าโซฟา) ,โต๊ะทานข้าว, เก้าอี้, โซฟา, เครื่องครัว, โต๊ะทำงาน, ชั้นวางทีวี
                ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก จากอุบัติเหตุ
                <br>4. กลุ่มเครื่องใช้ไฟฟ้า : โทรทัศน์ เครื่องซักผ้า ตู้เย็น ไมโครเวฟ
                ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก จากอุบัติเหตุ แต่ไม่รวมความเสียหายจากการขัดข้องของกลไกภายใน (เช่น ตู้เย็นคอมเพรสเซอร์เสีย น้ำยารั่ว ไม่เย็น หรือเครื่องซักผ้ามอเตอร์ไม่หมุน เป็นต้น )
                ทั้งนี้ ไม่รวมเฟอร์นิเจอร์แบบบิ้วอินที่มาพร้อมโครงการ">
                <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#F5A623" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="plan-des-list-2">
              <span>150,000</span>
            </div>
          </div>
          <div class="plan-des-list plan-des-list-nth">
            <div class="plan-des-list-1">
              <span>ความเสียหายของทรัพย์สินจากการกัดแทะของ สัตว์เลี้ยง (สุนัขและแมว) ของผู้เช่า**</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" data-html="true" title="คุ้มครองการกัดแทะทรัพย์สินภายในห้องชุด/บ้าน (ไม่รวมทรัพย์สินส่วนบุคคล) ที่ทำการปล่อยเช่า โดยมีรายการดังนี้
              <br>1. กลุ่มเครื่องนอน : เบาะนอน ผ้าห่ม หมอน
              <br>2. กลุ่มเครื่องตกแต่ง : โคมไฟ กรอบรูป ผ้าม่าน
              <br>3. กลุ่มสิ่งอำนวยความสะดวก : โต๊ะกลาง (หน้าโซฟา) ,โต๊ะทานข้าว, เก้าอี้, โซฟา, เครื่องครัว, โต๊ะทำงาน, ชั้นวางทีวี
              <br>4. กลุ่มเครื่องใช้ไฟฟ้า : โทรทัศน์ เครื่องซักผ้า ตู้เย็น ไมโครเวฟ
              <br>ทั้งนี้ ไม่รวมเฟอร์นิเจอร์แบบบิ้วอินที่มาพร้อมโครงการ">
                <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#F5A623" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="plan-des-list-2">
              <span>30,000</span>
            </div>
          </div>
          <div class="plan-price">
            <span class="text-1">
              เบี้ยประกันภัยต่อปี
            </span>
            <span class="text-2">
              5,199
            </span>
          </div>
          <div class="plan-btn-price" (click)="handleDetailInsurance(5);">
            <span>เลือกแผนนี้</span>
          </div>
        </div>

      </ngx-slick-carousel>
    </div>
  </section> -->

  <section class="explain_area_new plan-insurance-new-mobile">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="" style="margin-bottom: 20px;">
            <p class="plan-insurance-online title-header">แผนประกันภัยออนไลน์</p>
          </div>
        </div>
      </div>

      <div class="plan-mobile-list list-new plan-list-3">
        <div class="plan-title">
          <div class="plan-title-1"><img class="icon-shield" src="assets/images/Shield-1.png" alt="icon">รายละเอียดความคุ้มครอง</div>
          <div class="plan-title-2">
            <span class="text-plan">แผนที่ 1</span>
            <!-- <span class="text-1" (click)="handlePlayVideo(1);">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 12V4L12 8L6 12Z" fill="#04C825"/>
              <circle cx="8" cy="8" r="7.5" stroke="#04C825"/>
              </svg>
              <span>เล่นวิดิโอ</span>
            </span> -->
          </div>
          <div class="plan-title-3">
            <span class="text-plan">แผนที่ 2</span>
            <!-- <span class="text-1" (click)="handlePlayVideo(1);">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 12V4L12 8L6 12Z" fill="white"/>
                <circle cx="8" cy="8" r="7.5" stroke="white"/>
              </svg>
              <span>เล่นวิดิโอ</span>
            </span> -->
          </div>
          <div class="plan-title-4">
            <span class="text-plan">แผนที่ 3</span>
            <!-- <span class="text-1" (click)="handlePlayVideo(1);">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 12V4L12 8L6 12Z" fill="#04C825"/>
              <circle cx="8" cy="8" r="7.5" stroke="#04C825"/>
              </svg>
              <span>เล่นวิดิโอ</span>
            </span> -->
          </div>
        </div>
        <div class="plan-des">
          <span class="plan-des-1"></span>
          <span class="plan-des-2">จำนวนเงินเอาประกันภัย (บาท/ปี)</span>
        </div>
        <div class="plan-des-list">
          <div class="plan-des-list-1">
            <span>ไฟไหม้, ฟ้าผ่า, ภัยระเบิด, ภัยจากยานพาหนะ
              ภัยเนื่องจากน้ำ (ไม่รวมน้ำท่วม)</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" title="ไฟไหม้ ฟ้าผ่า (รวมถึงความเสียหายต่อเครื่องใช้ไฟฟ้า และอุปกรณ์ไฟฟ้าที่เกิดจากการลัดวงจรจากฟ้าผ่า) ภัยระเบิด ภัยจากการเฉี่ยวและ/หรือการชนของยวดยานพาหนะ ภัยจากอากาศยาน และภัยเนื่องจากน้ำ (ไม่รวมน้ำท่วม) เช่น ก๊อกน้ำแตก แล้วน้ำไหลไปโดนทรัพย์สินในห้องเสียหาย, การรั่วซึมจากน้ำฝนที่ผ่านเข้ามาในห้องจนทำให้ทรัพย์สินเสียหาย">
                <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </div>
          <div class="plan-des-list-2">
            <span>200,000</span>
          </div>
          <div class="plan-des-list-3">
            <span>300,000</span>
          </div>
          <div class="plan-des-list-4">
            <span>500,000</span>
          </div>
        </div>
        <div class="plan-des-list">
          <div class="plan-des-list-1">
            <span>ภัยธรรมชาติ (น้ำท่วม ลมพายุ แผ่นดินไหว ภูเขาไฟระเบิด สึนามิ และลูกเห็บ)</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" title="ภัยจากน้ำท่วม ภัยจากลมพายุ ภัยจากแผ่นดินไหวหรือภูเขาไฟระเบิดหรือคลี่นใต้น้ำหรือสึนามิ และภัยจากลูกเห็บ รวมกันทุกภัยต่อครั้งและต่อปี">
                <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </div>
          <div class="plan-des-list-2">
            <span>20,000</span>
          </div>
          <div class="plan-des-list-3">
            <span>20,000</span>
          </div>
          <div class="plan-des-list-4">
            <span>20,000</span>
          </div>
        </div>
        <div class="plan-des-list">
          <div class="plan-des-list-1">
            <span>โจรกรรม(ลักทรัพย์ที่ปรากฏร่อง
              รอยงัดแงะ ชิงทรัพย์ ปล้นทรัพย์)</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" title="ความเสียหายต่อทรัพย์สินที่เก็บอยู่ภายในอาคาร หรือประตู-หน้าต่าง รวมถึงค่าใช้จ่ายในการเปลี่ยนกุญแจ เช่น มีการงัดแงะประตู/หน้าต่างเพื่อมาขโมยทรัพย์สินภายในบ้านหรือห้องชุด ทำให้ทรัพย์สินเสียหายหรือต้องเปลี่ยนอุปกรณ์การล็อคใหม่">
                <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </div>
          <div class="plan-des-list-2">
            <span>20,000</span>
          </div>
          <div class="plan-des-list-3">
            <span>30,000</span>
          </div>
          <div class="plan-des-list-4">
            <span>50,000</span>
          </div>
        </div>
        <div class="plan-des-list">
          <div class="plan-des-list-1">
            <span>การเสียชีวิต บาดเจ็บหรือเจ็บป่วย และทรัพย์สินของบุคคลภายนอก*</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" title="ความรับผิดตามกฎหมายสำหรับการเสียชีวิต การบาดเจ็บหรือเจ็บป่วย และความสูญเสียหรือเสียหายต่อทรัพย์สินของบุคคลภายนอก (ภายในสถานที่เอาประกันภัย) เช่น ของตกจากระเบียงไปโดนรถยนต์หรือร่างกายผู้อื่นบาดเจ็บ, น้ำรั่วจากห้องเราไปทำความเสียหายห้องผู้อื่น">
                <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#C0CCEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </div>
          <div class="plan-des-list-2">
            <span>50,000</span>
          </div>
          <div class="plan-des-list-3">
            <span>80,000</span>
          </div>
          <div class="plan-des-list-4">
            <span>150,000</span>
          </div>
        </div>
        <div class="plan-des-list plan-des-list-nth">
          <div class="plan-des-list-1">
            <span>ความเสียหายของทรัพย์สินภายในห้องชุด/บ้านที่ทำการปล่อยเช่า**</span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" data-html="true" title="ทรัพย์สินภายในห้องชุด/บ้าน (ไม่รวมทรัพย์สินส่วนบุคคล) ที่ทำการปล่อยเช่า โดยมีรายการดังนี้<br>1. กลุ่มเครื่องนอน : เบาะนอน ผ้าห่ม หมอน
              ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีกขาด จากอุบัติเหตุ แต่ทั้งนี้ไม่รวมการเปรอะเปื้อนจากประจำเดือนของสุภาพสตรี
              <br>2. กลุ่มเครื่องตกแต่ง : โคมไฟ กรอบรูป ผ้าม่าน
              ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก จากอุบัติเหตุ
              <br>3. กลุ่มสิ่งอำนวยความสะดวก : โต๊ะกลาง (หน้าโซฟา) ,โต๊ะทานข้าว, เก้าอี้, โซฟา, เครื่องครัว, โต๊ะทำงาน, ชั้นวางทีวี
              ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก จากอุบัติเหตุ
              <br>4. กลุ่มเครื่องใช้ไฟฟ้า : โทรทัศน์ เครื่องซักผ้า ตู้เย็น ไมโครเวฟ
              ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก จากอุบัติเหตุ แต่ไม่รวมความเสียหายจากการขัดข้องของกลไกภายใน (เช่น ตู้เย็นคอมเพรสเซอร์เสีย น้ำยารั่ว ไม่เย็น หรือเครื่องซักผ้ามอเตอร์ไม่หมุน เป็นต้น )
              ทั้งนี้ ไม่รวมเฟอร์นิเจอร์แบบบิ้วอินที่มาพร้อมโครงการ">
              <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#F5A623" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="plan-des-list-2">
            <span>50,000</span>
          </div>
          <div class="plan-des-list-3">
            <span>100,000</span>
          </div>
          <div class="plan-des-list-4">
            <span>150,000</span>
          </div>
        </div>
        <div class="plan-des-list plan-des-list-nth">
          <div class="plan-des-list-1">
            <span>ความเสียหายของทรัพย์สินจากการกัดแทะของ สัตว์เลี้ยง (สุนัขและแมว) ของผู้เช่า**</span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-toggle="tooltip" data-html="true" title="คุ้มครองการกัดแทะทรัพย์สินภายในห้องชุด/บ้าน (ไม่รวมทรัพย์สินส่วนบุคคล) ที่ทำการปล่อยเช่า โดยมีรายการดังนี้
            <br>1. กลุ่มเครื่องนอน : เบาะนอน ผ้าห่ม หมอน
            <br>2. กลุ่มเครื่องตกแต่ง : โคมไฟ กรอบรูป ผ้าม่าน
            <br>3. กลุ่มสิ่งอำนวยความสะดวก : โต๊ะกลาง (หน้าโซฟา) ,โต๊ะทานข้าว, เก้าอี้, โซฟา, เครื่องครัว, โต๊ะทำงาน, ชั้นวางทีวี
            <br>4. กลุ่มเครื่องใช้ไฟฟ้า : โทรทัศน์ เครื่องซักผ้า ตู้เย็น ไมโครเวฟ
            <br>ทั้งนี้ ไม่รวมเฟอร์นิเจอร์แบบบิ้วอินที่มาพร้อมโครงการ">
              <path d="M6.06004 6.00004C6.21678 5.55449 6.52614 5.17878 6.93334 4.93946C7.34055 4.70015 7.8193 4.61267 8.28483 4.69252C8.75035 4.77236 9.17259 5.01439 9.47676 5.37573C9.78093 5.73706 9.94741 6.19439 9.94671 6.66671C9.94671 8.00004 7.94671 8.66671 7.94671 8.66671M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z" stroke="#F5A623" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="plan-des-list-2">
            <span>10,000</span>
          </div>
          <div class="plan-des-list-3">
            <span>15,000</span>
          </div>
          <div class="plan-des-list-4">
            <span>30,000</span>
          </div>
        </div>
        <div class="plan-price">
          <span class="text-1">
            เบี้ยประกันภัยต่อปี
          </span>
          <span class="text-2">
            2,199
            <span>บาท</span>
          </span>
          <span class="text-2">
            3,399
            <span>บาท</span>
          </span>
          <span class="text-2">
            5,199
            <span>บาท</span>
          </span>
        </div>
        <div class="plan-btn-price">
          <div class="plan-btn-price-1"></div>
          <button class="plan-btn-price-2 plan-btn-3" (click)="handleDetailInsurance(3);">เลือก</button>
          <button class="plan-btn-price-3 plan-btn-4" (click)="handleDetailInsurance(4);">เลือก</button>
          <button class="plan-btn-price-4 plan-btn-5" (click)="handleDetailInsurance(5);">เลือก</button>
        </div>
      </div>

    </div>
  </section>
</div>
<!-- ******* เงื่อนไขการรับประกัน ***********-->

<!-- ******* waranty 1 year ******* -->
<section>
  <div class="warranty-once-year ">
    <div class="warranty-once-year-top"></div>

    <div class="container">
      <div class="main-warranty-terms">
        <div class="row">
          <div class="col-12">
            <p id="warranty-terms" class="topic-title collapsed text-center"  data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">เงื่อนไขการรับประกันภัย <i class="fas fa-angle-down"></i></p>
            <ul class="dashed collapse" id="collapse4" aria-labelledby="warranty-terms" data-parent="#insurance-qa">
              <li>1. ชดใช้ตามความเสียหายที่เกิดขึ้นจริง และไม่เกินวงเงินความคุ้มครอง<br>
                2. *ความรับผิดส่วนแรก 3,000 บาท/ครั้ง สำหรับความรับผิดส่วนบุคคลต่อบุคคลภายนอก อันเกิดจากภัยเนื่องจากน้ำ<br>
                3. **ความรับผิดส่วนแรก 3,000 บาท/ครั้ง สำหรับความเสียหายของทรัพย์สินภายในห้องชุด/บ้านที่ทำการปล่อยเช่า และ/หรือจากการกัดแทะของสัตว์เลี้ยง(สุนัขและแมว) ของผู้เช่า<br>
                4. แผนประกันภัยนี้คุ้มครองเฉพาะทรัพย์สินภายในสิ่งปลูกสร้าง เช่น เฟอร์นิเจอร์ เครื่องตกแต่งติดตั้งตรึงตรา เครื่องใช้ไฟฟ้า และทรัพย์สินอื่นๆ เพื่อการอยู่อาศัย (รวมถึงวอลเปเปอร์)<br>
                5. แผนประกันภัยนี้ไม่คุ้มครองทรัพย์สินส่วนบุคคล เช่น โทรศัพท์มือถือ เครื่องคอมพิวเตอร์พกพา และ/หรืออุปกรณ์อิเล็กโทรนิกส์ อุปกรณ์สื่อสารพกพาทุกประเภท และ/หรือ กล้องถ่ายรูป วีดีโอ และ/หรือ สื่อบันทึกต่างๆ<br>
                6. แผนประกันภัยนี้สำหรับห้องชุด/บ้านราคาไม่เกิน 10 ล้านบาท<br>
                7. บริษัทฯ ขอสงวนสิทธิ์ในการรับประกันภัย 1 กรมธรรม์ต่อ 1 หลัง/ยูนิต<br>
                8. แผนประกันภัยนี้คุ้มครองเฉพาะทรัพย์สินภายในบ้าน/ห้องชุดในคอนโดมิเนียมที่ใช้เพื่อการอยู่อาศัยเท่านั้น<br>
                9. บริษัทฯ จะชดใช้ความเสียหายตามมูลค่าทรัพย์สินที่เป็นของใหม่ (Replacement Cost Valuation)<br>
                10. โปรดศึกษารายละเอียดความคุ้มครองและข้อยกเว้นประกันภัยที่อยู่อาศัยแบบพิเศษ แผนให้เช่าหายห่วง <a target="_blank" href="assets/pdf/plan_insurance.pdf" class="link">คลิก</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-2 video-plan">
      <div class="row">
        <div class="col-lg-4 col-md-12 mt-2 mt-md-2 mt-lg-0">
          <div class="video-list-plan">
            <div class="video-list-title" (click)="handlePlayVideo(3);">
              <!-- <img src="https://img.youtube.com/vi/ur3QpWWMaPs/mqdefault.jpg" alt="plan"> -->
              <img src="assets/images/plan-video-1.jpg" alt="plan">
              <div class="icon-play">
                <svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="56" height="40" rx="12" fill="#FF5353"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.75 12.1799C22.75 11.2481 23.7885 10.6923 24.5638 11.2092L35.2939 18.3626C35.9866 18.8244 35.9866 19.8423 35.2939 20.3041L24.5638 27.4575C23.7885 27.9743 22.75 27.4185 22.75 26.4867V12.1799Z" fill="white"/>
                  </svg>
              </div>
            </div>
            <div class="video-list-des">
              ดูวีดีโอเงื่อนไขเบี้ยประกัน 2,199 บาท
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 mt-2 mt-md-2 mt-lg-0">
          <div class="video-list-plan sticky">
            <div class="video-list-title" (click)="handlePlayVideo(4);">
              <!-- <img src="https://img.youtube.com/vi/2uTexN8XGw8/mqdefault.jpg" alt="plan"> -->
              <img src="assets/images/plan-video-2.jpg" alt="plan">
              <div class="icon-play">
                <svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="56" height="40" rx="12" fill="#FF5353"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.75 12.1799C22.75 11.2481 23.7885 10.6923 24.5638 11.2092L35.2939 18.3626C35.9866 18.8244 35.9866 19.8423 35.2939 20.3041L24.5638 27.4575C23.7885 27.9743 22.75 27.4185 22.75 26.4867V12.1799Z" fill="white"/>
                  </svg>
              </div>
            </div>
            <div class="video-list-des">
              ดูวีดีโอเงื่อนไขเบี้ยประกัน 3,399 บาท
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 mt-2 mt-md-2 mt-lg-0">
          <div class="video-list-plan">
            <div class="video-list-title" (click)="handlePlayVideo(5);">
              <!-- <img src="https://img.youtube.com/vi/2XgSPlbH94M/mqdefault.jpg" alt="plan"> -->
              <img src="assets/images/plan-video-3.jpg" alt="plan">
              <div class="icon-play">
                <svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="56" height="40" rx="12" fill="#FF5353"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.75 12.1799C22.75 11.2481 23.7885 10.6923 24.5638 11.2092L35.2939 18.3626C35.9866 18.8244 35.9866 19.8423 35.2939 20.3041L24.5638 27.4575C23.7885 27.9743 22.75 27.4185 22.75 26.4867V12.1799Z" fill="white"/>
                  </svg>
              </div>
            </div>
            <div class="video-list-des">
              ดูวีดีโอเงื่อนไขเบี้ยประกัน 5,199 บาท
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row explain-bar1">
        <div class="col-md-12">
          <div class="d-flex align-items-center" style="">
            <img src="assets/images/shield-plus.png" alt="Image" >
            <div class="explain-bar1-desc">
              <span> ฟรี </span>
              บริการช่วยเหลือฉุกเฉินภายในบ้านตลอด 24 ชั่วโมง
              <span>1 บริการ/ปี</span>
              ในวงเงินการให้บริการสูงสุดไม่เกิน
              <span>1,000 บาท/ครั้ง ทุกแผนประกันภัย</span>
            </div>
          </div>
        </div>
      </div>

      <div class="list-card-mobile" style="background: #fff">
        <div class="list-item no-gutters d-flex flex-wrap">
          <div class="col-4" *ngFor="let service of protects">
            <div style="float: left;"><img src="assets/images/protect-free.png"></div>
            <p>{{service.name}}</p>
          </div>
        </div>
      </div>

      <div class="row no-gutters warranty-list list-card">
        <div class="card-living text-center" *ngFor="let service of services">
          <div class="icon"><img src="{{service.img}}"></div>
          <div class="desc">
            <h5 class="mt-3 mb-2">{{service.name}}</h5>
          </div>
        </div>
      </div>

    </div>
    <div class="warranty-once-year-bottom"></div>
</div>
<!-- end container -->


</section>

<!-- Section Q&A -->
<section id="insurance-qa">
  <div class="insurance-qa">
    <div class="container">
      <p class="title-header">คำถามที่พบบ่อย</p>
      <div class="row no-gutters" id="faq">
        <div class="col-12" >
          <p  id="headingOne" class="topic-title collapsed"  data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">ประกันภัยให้เช่าหายห่วงเหมาะกับใคร ? <i class="fas fa-angle-down"></i></p>
          <ul class="dashed collapse" id="collapseOne" aria-labelledby="headingOne" data-parent="#insurance-qa">
            <li>เหมาะกับทุกคนที่ต้องการปล่อยเช่าทรัพย์สินของท่าน โดยเฉพาะบ้านและคอนโด เพราะแม้เราจะมีเงินประกัน 2 เดือนจากผู้เช่า แต่ก็มีหลายครั้งที่ความเสียหายทีเกิดขึ้นมากกว่าเงินประกัน ทำให้ไม่สามารถตกลงกันได้ และหลายครั้งที่ผู้เช่าไม่รับผิดชอบและหนีหายไปเลย</li>
          </ul>
        </div>
        <div class="col-12">
          <p  id="heading2" class="topic-title collapsed"  data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">ประกันแบบนี้ มีขายที่อื่นไหม ? <i class="fas fa-angle-down"></i></p>
          <ul class="dashed collapse" id="collapse2" aria-labelledby="heading2" data-parent="#insurance-qa">
            <li>ประกันภัยสำหรับการให้เช่า บ้านและคอนโด มีที่นี่เป็นครั้งแรกในประเทศไทย และยังไม่มีเจ้าไหนคิดแผนประกันภัยที่ครอบคลุมและราคาถูกเท่าที่นี่ ส่วนใหญ่ที่มีคือ ประกันความเสียหายของสิ่งปลูกสร้างอสังหาริมทรัพย์แบบทั่วไป ซึ่งเรามักโดนบังคับให้ซื้อตอนกู้ซื้อบ้านกับธนาคารซึ่งแผนประกันนั้นไม่ได้ให้ความคุ้มครองจากการปล่อยเช่าแบบประกันของเรา</li>
          </ul>
        </div>
        <div class="col-12">
          <p  id="heading3" class="topic-title collapsed"  data-toggle="collapse" data-target="#collapse3" aria-expanded="false" aria-controls="collapse3">ใครควรเป็นคนต้องซื้อประกัน เจ้าของหรือผู้เช่า ? <i class="fas fa-angle-down"></i></p>
          <ul class="dashed collapse" id="collapse3" aria-labelledby="heading3" data-parent="#insurance-qa">
            <li>สามารถซื้อได้ทั้ง 2 ฝ่าย ทั้งเจ้าของและผู้เช่า เพราะบางครั้งผู้เช่าที่ระมัดระวังและไม่ต้องการเสียเงินประกันจากความเสียหายจากตนเอง ก็ซื้อประกันนี้คุ้มครองได้ด้วย </li>
          </ul>
        </div>
        <div class="col-12">
          <p  id="heading4" class="topic-title collapsed"  data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">คุ้มครองเมื่อไหร่และนานแค่ไหน ? <i class="fas fa-angle-down"></i></p>
          <ul class="dashed collapse" id="collapse4" aria-labelledby="heading4" data-parent="#insurance-qa">
            <li>คุ้มครองทันทีที่ชำระค่าเบี้ยประกัน โดยมีระยะเวลา 1 ปี </li>
          </ul>
        </div>
        <div class="col-12">
          <p  id="heading5" class="topic-title collapsed"  data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">ทำประกันภัยให้เช่าหายห่วงกับอสังหาริมทรัพย์แบบไหนได้บ้าง ? <i class="fas fa-angle-down"></i></p>
          <ul class="dashed collapse" id="collapse5" aria-labelledby="heading5" data-parent="#insurance-qa">
            <li>ตอนนี้ทางเราได้ออกแบบประกันมาเฉพาะ บ้านและคอนโด เท่านั้น ในอนาคตจะมีประเภทอื่นๆออกมาอีก</li>
          </ul>
        </div>
        <div class="col-12">
          <p  id="heading6" class="topic-title collapsed"  data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">ชำระเบี้ยประกันแบบไหนได้บ้าง ? <i class="fas fa-angle-down"></i></p>
          <ul class="dashed collapse" id="collapse6" aria-labelledby="heading6" data-parent="#insurance-qa">
            <li>ท่านสามารถชำระเบี้ยประกันภัยผ่านช่องทางออนไลน์หลังจากกรอกข้อมูลเสร็จ โดยสามารถชำระได้ทั้งบัตรเครดิตและบัตรเดบิต รวมถึงเงินสดผ่าน Mobile Banking</li>
          </ul>
        </div>
        <div class="col-12">
          <p  id="heading7" class="topic-title collapsed"  data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapse7">หากเกิดความเสียหายแจ้งเคลมได้ยังไง ? <i class="fas fa-angle-down"></i></p>
          <ul class="dashed collapse" id="collapse7" aria-labelledby="heading7" data-parent="#insurance-qa">
            <li>แจ้งเคลมออนไลน์ได้ที่นี่เลย เพียงกดปุ่ม <a href="javascript:void(0);" (click)="openRequestClaimInsurance()">แจ้งเคลม</a> จากนั้นกรอกรายละเอียดให้ครบถ้วน ข้อมูลของท่านจะถูกส่งถึงบริษัทประกันภัยทันที ทำได้ง่ายๆไม่เกิน 2 นาทีเสร็จ </li>
          </ul>
        </div>
        <div class="col-12">
          <p  id="heading8" class="topic-title collapsed"  data-toggle="collapse" data-target="#collapse8" aria-expanded="false" aria-controls="collapse8">หากซื้อประกันไว้แล้ว จะเช็กรายละเอียดกรมธรรม์จากที่ไหน ? <i class="fas fa-angle-down"></i></p>
          <ul class="dashed collapse" id="collapse8" aria-labelledby="heading8" data-parent="#insurance-qa">
            <li>หลังจากชำระเงินสำเร็จ ระบบจะส่งไฟล์กรมธรรม์ให้ท่านทางอีเมล (สามารถเข้าไปดูได้ที่ Inbox หรือ Junk Mail) และท่านสามารถตรวจสอบรายละเอียดกรมธรรม์ได้ตลอดเวลา เพียงเข้าระบบได้ที่เว็บไซต์นี้ด้วยเบอร์โทรของท่าน ท่านจะเห็นรายละเอียดกรมธรรม์ที่ท่านเคยซื้อทั้งหมดได้ทันที
            </li>
            <span class="ml-3 mt-3 d-block" style="color: #292b54;font-family: Sarabun;">หมายเหตุ : สำหรับแผนประกันภัยให้เช่าหายห่วง ลูกค้าจะได้รับกรมธรรม์จำนวน 2 กรมธรรม์ คือ 1. กรมธรรม์ประกันภัย "บ้านแทนรัก" 2. กรมธรรม์ประกันภัยความเสี่ยงภัยทุกชนิด
              ซึ่งค่าเบี้ยประกันภัยรวมทั้ง 2 กรมธรรม์ จะเท่ากับจำนวนเบี้ยประกันภัยบนตารางความคุ้มครองที่ระบุไว้บนหน้าเว็บไซต์</span>
          </ul>
        </div>
      </div>

    </div>
  </div>

</section>

<!-- Article -->
<section>
  <div class="article">
    <div class="container">
      <p class="title-header">บทความ</p>
       <ngx-slick-carousel class="carousel block-article" #slickModal="slick-carousel" [config]="slideConfig" >
        <app-card-article ngxSlickItem *ngFor="let itemArticle of article" image="{{itemArticle.web_display_picture}}" title="{{itemArticle.web_title}}" urlLink="{{itemArticle.url}}">

        </app-card-article>
      </ngx-slick-carousel>
    </div>
  </div>
</section>
<!-- /Article -->

<!-- ********* Commented Area Start ********* -->
<section id="commented-area" class="commented_area">
  <div class="container">
    <div class="row align-items-center block-title">
        <div class="col-12 col-md-8">
            <div class="mt-3 no-js">
                <p class="title-header">ความคิดเห็นจากลูกค้า</p>
            </div>
        </div>

        <div class="col-12 col-md-4">
            <div class="mb-80">
                <div class="commented-rating">
                    <span class="rating-score">4.9/5</span> <span style="font-size: 16px;color: #828c9e;"> คะแนน </span>
                    <div class="rating-score-all" data-score="4.5" style="white-space: nowrap;"></div>
                </div>
            </div>
        </div>
    </div>

    <ngx-slick-carousel class="carousel card-review-list" #slickModal="slick-carousel" [config]="slideConfig" >
      <app-card-customer-review ngxSlickItem *ngFor="let review of reviews" [userProfile]="review.profile"  [userName]="review.name" [userAddress]="review.address" [userImage]="review.img" [userDetail]="review.detail" [userScore]="review.score"></app-card-customer-review>
    </ngx-slick-carousel>
  </div>
</section>
<!-- ********* Commented Area End ********* -->
<app-footer></app-footer>

<app-modals [insuranceType]="insuranceTypeModal"></app-modals>
