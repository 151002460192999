<!-- Modal -->
<div class="modal fade" id="otpModal" tabindex="-1" role="dialog" aria-labelledby="otpModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <img src="assets/images/web-living-icon.png" alt="Image" class="w-25"><img src="assets/images/msig-icon.png" alt="Image" width="15%"> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="z-index: 100;">
          <img src="assets/images/times-white.png" alt="">
        </button>

        <div class="profile-detail">
          <div class="user-detail">
            <div class="user-img">
              <img src="assets/images/user-icon.png" alt="">
            </div>
            <div class="user-name">
              <p class="welcome">สวัสดีคุณ</p>
              <p>-</p>
            </div>
          </div>
          <p class="require-phone">ระบุหมายเลขโทรศัพท์ ที่ทำกรมธรรม์</p>
          <div class="insurance-list" style="display: none;">
            <div class="d-flex justify-content-between">
              <span>กรมธรรม์ของคุณ</span>
              <span>(0) รายการ</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body" >
        <!-- form request otp -->
        <form class="form-input" [formGroup]="requestOTP" (ngSubmit)="handleRequestOTP()" >
          <div class="form-group">
            <label for="">ระบุหมายเลขโทรศัพท์ที่ทำกรมธรรม์</label>
            <input type="text" class="form-control form-control-lg" [ngClass]="{'is-invalid': !requestOTP.get('phone').valid && requestOTP.get('phone').touched}" formControlName="phone" placeholder="ระบุหมายเลขโทรศัพท์ของคุณ" maxlength="10" >
          </div>
          <div class="block-validate">
            <span class="text-danger d-none" [ngClass]="{'d-block': !requestOTP.get('phone').valid && requestOTP.get('phone').touched}">
              {{responseRequestOTP?.result_msg}}
            </span>
          </div>
          <div class="info-text">
           <span>รหัส OTP สำหรับยืนยันตัวตนของคุณ</span> จะถูกส่ง SMS ไปที่หมายเลขโทรศัพท์ที่คุณระบุ
          </div>
          <button type="submit" class="btn btn-secondary btn-block shadow-secondary" [disabled]="!requestOTP.valid">ส่งรหัส OTP</button>
        </form>

        <!-- form confirm otp -->
        <form class="form-otp" [formGroup]="confirmOTP" (ngSubmit)="handleSubmitConfirmOTP()" style="display:none;">
          <div class="form-group text-center">
            <label for="">ระบุ OTP ที่ได้รับทาง SMS</label>
            <ng-otp-input #otpInput (onInputChange)="onOtpChange($event)"  [config]="{length:6}" [config]="config"></ng-otp-input>
          </div>
          <div class="info-text password-otp">
            รหัส OTP มีอายุการใช้งาน 30 นาที
           </div>
           <p class="text-center">
             <button type="button" class="link" (click)="handleRequestOTP()" [disabled]="(timingRequestOPT > 0)"><img src="assets/images/rotate-ccw.png" alt=""> ขอรหัส OTP อีกครั้ง</button>
           </p>
           <div class="info-text count-request-otp">
            ไมไ่ด้รับรหัส OTP ขอรหัสอีกครั้งใน {{timingRequestOPT}} วินาที
           </div>
          <button type="submit" class="btn btn-secondary btn-block shadow-secondary" [disabled]="!confirmOTP.valid">ยืนยัน</button>
        </form>

      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="insuranceListModal" tabindex="-1" role="dialog" aria-labelledby="insuranceListModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="display:none;">
        <!-- <img src="assets/images/web-living-icon.png" alt="Image" class="w-25"><img src="assets/images/msig-icon.png" alt="Image" width="15%"> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="z-index: 100;">
          <img src="assets/images/times-white.png" alt="">
        </button>

        <div class="profile-detail">
          <div class="user-detail">
            <div class="user-img">
              <img src="assets/images/user-icon.png" alt="">
            </div>
            <div class="user-name">
              <p class="welcome">สวัสดีคุณ</p>
              <p>{{userDetail?.name}}</p>
            </div>
          </div>
          <div class="insurance-list">
            <div class="d-flex justify-content-between">
              <span>กรมธรรม์ของคุณ</span>
              <span>({{insuranceList.length}}) รายการ</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body" style="display:none;">

        <!-- form detail -->
        <div class="section-detail">
          <div class="card-item">
            <app-card-insurance
                *ngFor="let item of insuranceList"
                (openDetail)="detailInsurance($event)"
                (openHistory)="historyInsurance($event)"
                (openClaim)="claimInsurance($event)"
                [insuranceID]='item.insurance_id'
                [insuranceType]='item.insurance_type'
                [postFrom]='item.insurance_type'
                [address]='item.address'
                [buildingType]='item.building_type'
                [buildingName]='item.building_type'
                [dateStart]='item.insurance_start'
                [dateEnd]='item.insurance_end'
                [insuranceStatus]='item.insurance_status'
                [insuranceMSIGCode]='item.insurance_msig_code'
                [countClaim]='item.claim_count'
                [firstName]='item.firstname'
                [lastName]='item.lastname'
                [email]='item.email'
                [phone]='item.phone'
                [idCard]='item.id_card'
                [address]='item.address'
                [homeNo]='item.home_no'
                [district]='item.district'
                [province]='item.province'
                [zipCode]='item.zipcode'
                [benefitFirstName]='item.benefit_firstname'
                [benefitLastName]='item.benefit_lastname'
                [benefitIDCard]='item.benefit_id_card'
                [benefitAddress]='item.benefit_address'
                [benefitHomeNo]='item.benefit_home_no'
                [benefitDistrict]='item.benefit_district'
                [benefitProvince]='item.benefit_province'
                [benefitZipCode]='item.benefit_zipcode'
                [insureBy]='item.insure_by'
            ></app-card-insurance>
          </div>
        </div>

      </div>

      <!-- claim insurance -->
      <div class="modal-claim-insurance" style="display:none;">
        <div class="header-detail">
          <img src="assets/images/chevron-left.png" alt="" (click)="closeDetail()">
          <p>แจ้งเคลมออนไลน์</p>
        </div>
        <form class="form-claim" [formGroup]="requestClaim" (ngSubmit)="handleSubmitRequestClaim()" enctype="multipart/form-data">
          <p class="title-header">รายละเอียดเกี่ยวกับกรมธรรม์ประกันภัย </p>
          <div class="block-info">
            <p class="title-header"  *ngIf="tempClaimInfo?.postFrom == 1" >ประกันภัย ให้เช่าหายห่วง</p>
            <p class="title-header"  *ngIf="tempClaimInfo?.postFrom == 2">ประกันภัย อยู่เองหายห่วง</p>
            <div class="detail-insurance">
              <div class="row no-gutters">
                <div class="form-group col-12">
                  <p class="title-sub">ที่อยู่เอาประกันภัย</p>
                  <p>{{tempClaimInfo?.address}}</p>
                </div>
                <div class="form-group col-6">
                  <p class="title-sub">วันเริ่มกรมธรรม์</p>
                  <p *ngIf="!tempClaimInfo.dateStartStatus" >-</p>
                  <p *ngIf="tempClaimInfo.dateStartStatus" >{{tempClaimInfo?.dateStart | thaidate}}</p>
                </div>
                <div class="form-group col-6">
                  <p class="title-sub">วันสิ้นสุดกรมธรรม์</p>
                  <p *ngIf="!tempClaimInfo.dateEndStatus" >-</p>
                  <p *ngIf="tempClaimInfo.dateEndStatus" >{{tempClaimInfo?.dateEnd | thaidate}}</p>
                </div>
                <div class="form-group col-12">
                  <p class="title-sub">เลขกรมธรรม์</p>
                  <p>{{tempClaimInfo?.insuranceMSIGCode}}</p>
                </div>
              </div>
          </div>
          </div>
          <div class="info-recommend">
            <img src="" alt="">
            <span>กรุณาตอบคำถามและกรอกข้อความให้สมบูรณ์ และจัดเก็บรักษา
              ทรัพย์สินที่เสียหายไว้เพื่อบริษัทตรวจสอบ</span>
          </div>
          <!-- Form Request Claim  -->
          <div class="group-form-request-claim">
            <div class="form-request-claim">
              <p class="title-header">รายละเอียดเบื้องต้นของเหตุการณ์ที่เกิดขึ้น</p>
              <div class="form-group">
                <label for="">วันที่เกิดเหตุ</label>
                <input type="text" class="form-control" (onChange)="validationDateFormat($event)" formControlName="accidentDate" placeholder="ระบุวันที่เกิดเหตุ วัน/เดือน/ปี" [dpDayPicker]="configDatePicker" theme="dp-material" [ngClass]="{'is-invalid': !requestClaim.get('accidentDate').valid && requestClaim.get('accidentDate').touched}">
              </div>
              <div class="form-group">
                <label for="">ลักษณะเหตุการณ์เบื้องต้น</label>
                <textarea type="text" class="form-control" formControlName="accidentDetail" placeholder="ระบุลักษณะเหตุการณ์เบื้องต้น เช่น ผู้เช่าทำข้าวของเสียหาย, สัตว์เลี้ยงทำข้าวของเสียหาย, พายุเข้าลมพัดข้าว ของเสียหาย" rows="5" [ngClass]="{'is-invalid': !requestClaim.get('accidentDetail').valid && requestClaim.get('accidentDetail').touched}"></textarea>
              </div>
              <div class="form-group">
                <label for="">ค่าความเสียหายเบื้องต้น </label> <span class="secondary-label"> - หากไม่ทราบ ไม่ระบุก็ได้</span>
                <input type="text" class="form-control" formControlName="accidentPrice" placeholder="ระบุค่าเสียหายของทรัพย์สินโดยประมาณ" (focus)="addCommas()" (keyup)="addCommas()" numbersOnly>
              </div>
              <div class="form-group">
                <label for="">ภาพถ่ายทรัพย์สินที่เสียหาย</label>  <span class="secondary-label text-danger" *ngIf="!requestClaim.get('imagePhoto').valid && requestClaim.get('imagePhoto').touched" > โปรดอัพโหลดรูปภาพ</span>
                <div class="image-list">
                  <div class="image-item" *ngFor="let item of tempImageRequestClaim">
                    <div class="remove-image" (click)="removeImage(item)"><i class="fas fa-times"></i></div>
                    <img src="{{item.urlImage}}" alt="">
                  </div>

                </div>
                <input
                  style="display: none"
                  type="file" (change)="onFileUpload($event)"
                  accept="image/*"
                  multiple
                  #fileInput>
                <div class="upload-image" (click)="fileInput.click()">
                  <div class="block-title">
                    <img src="assets/images/icon-image-black.png" alt="">
                    <img src="assets/images/icon-camera-gray.png" alt="">
                    <span>เพิ่มรูปภาพ</span>
                  </div>
                </div>
              </div>
              <p class="title-header">ข้อมูลสำหรับติดต่อกลับ </p>
              <div class="form-group">
                <label for="">หมายเลขโทรศัพท์ที่สามารถติดต่อได้</label>
                <input type="text" class="form-control" formControlName="phone" placeholder="ระบุหมายเลขโทรศัพท์" [ngClass]="{'is-invalid': !requestClaim.get('phone').valid && requestClaim.get('phone').touched}" [maxLength]="10" numbersOnly>
              </div>
              <div class="form-group">
                <label for="">อีเมล</label>
                <input type="text" class="form-control" formControlName="email" placeholder="ระบุอีเมล" [ngClass]="{'is-invalid': (!requestClaim.get('email').valid && requestClaim.get('email').touched)}">
              </div>
              <div class="form-group confirm-accept">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="confirmAcceptRequest" formControlName="acceptConfirm">
                  <label class="custom-control-label" for="confirmAcceptRequest" [ngClass]="{'text-danger': (!requestClaim.get('acceptConfirm').value && requestClaim.get('acceptConfirm').touched)}">
                    ข้าพเจ้าขอรับรองว่าข้อความข้างต้นเป็นความจริง
                    ถูกต้องและสมบูรณ์</label>
                </div>
              </div>
            </div>
            <div class="footer-button">
              <button type="button" class="btn btn-block btn-secondary shadow-secondary" (click)="handleSaveRequestClaim()">ยืนยัน</button>
            </div>
          </div>


          <!-- Detail Request Claim -->
          <div class="group-detail-request-claim" style="display:none">
            <div class="detail-request-claim">
              <p class="title-header">รายละเอียดเบื้องต้นของเหตุการณ์ที่เกิดขึ้น</p>
              <div class="row no-gutters">
                <div class="col-4 header-detail">วันที่เกิดเหตุ</div>
                <div class="col-8 detail">{{ tempRequestClaim.accidentDate | thaidate }}</div>
                <div class="col-4 header-detail">ลักษณะเหตุการณ์</div>
                <div class="col-8 detail">
                  {{ tempRequestClaim.accidentDetail }}
                </div>
                <div class="col-4 header-detail">ค่าความเสียหาย</div>
                <div class="col-8 detail" *ngIf="!tempRequestClaim.accidentPrice" >-</div>
                <div class="col-8 detail" *ngIf="tempRequestClaim.accidentPrice" >{{ tempRequestClaim.accidentPrice | number }} บาท</div>
              </div>
              <p class="header-detail mt-1 mb-0">ภาพถ่ายทรัพย์สินที่เสียหาย</p>
              <div class="image-list mb-2">
                <div class="image-item" *ngFor="let item of tempImageRequestClaim">
                  <img src="{{item.urlImage}}" alt="">
                </div>
              </div>
              <p class="title-header">รายละเอียดเบื้องต้นของเหตุการณ์ที่เกิดขึ้น</p>
              <div class="row no-gutters mb-4">
                <div class="col-4 header-detail">เบอร์โทรศัพท์</div>
                <div class="col-8 detail">{{ tempRequestClaim.phone }}</div>
                <div class="col-4 header-detail">อีเมล</div>
                <div class="col-8 detail">{{ tempRequestClaim.email }}</div>
              </div>
            </div>
            <div class="footer-button">
              <button type="button" class="btn btn-block btn-gray text-white" (click)="backToFormRequestClaim()" >ย้อนกลับ</button>
              <button class="btn btn-block btn-secondary shadow-secondary">ส่งเรื่องเคลม</button>
            </div>
          </div>


        </form>

      </div>

      <!--  -->

      <!-- history insurance -->
      <div class="modal-history" style="display: none;">
        <div class="header-detail">
          <img src="assets/images/chevron-left.png" alt="" (click)="closeDetail()">
          <p>รายละเอียดกรมธรรม์</p>
        </div>
        <div class="body-detail">
          <div class="block-empty" *ngIf="historyClaimList?.length == 0" >
            <span>คุณยังไม่มีประวัติการแจ้งเคลม</span>
          </div>
          <app-card-history-claim
          *ngFor="let item of historyClaimList"
          (openImage)='pageViewImage($event)'
          [historyList]='item'
          ></app-card-history-claim>
        </div>
      </div>

      <!-- view history image view -->
      <div class="modal-view-image" style="display: none;">
        <div class="header-detail" style="height: 40px;">
          <img src="assets/images/chevron-left.png" alt="" (click)="closeViewImage()">
        </div>
        <div class="body-detail parent-container">
          <a href="{{item}}" alt="" *ngFor="let item of viewImageHistory?.claim_photo">
            <img src="{{item}}" alt="" width="100%">
          </a>
        </div>
      </div>

      <!-- detail insurance -->
      <div class="modal-detail-insurance" style="display:none;">
        <div class="header-detail">
          <img src="assets/images/chevron-left.png" alt="" (click)="closeDetail()">
          <p>รายละเอียดกรมธรรม์</p>
        </div>
        <div class="detail">
          <p class="title-header">ข้อมูลกรมธรรม์</p>
          <div class="row">
            <div class="col-5 header">เลขกรมธรรม์</div>
            <div class="col-7">{{ tempClaimInfo.insuranceMSIGCode }}</div>
            <div class="col-5 header">แผนกรมธรรม์</div>
            <div class="col-7" *ngIf="tempClaimInfo?.postFrom == 1">ประกันให้เช่าหายห่วง</div>
            <div class="col-7" *ngIf="tempClaimInfo?.postFrom == 2">ประกันอยู่เองหายห่วง</div>
            <div class="col-5 header">เบี้ยกรมธรรม์</div>
            <div class="col-7" *ngIf="tempClaimInfo?.postFrom == 1">1,999 บาท</div>
            <div class="col-7" *ngIf="tempClaimInfo?.postFrom == 2">999 บาท</div>
            <div class="col-5 header">วันเริ่มกรมธรรม์</div>
            <div class="col-7" *ngIf="!tempClaimInfo.dateStartStatus">-</div>
            <div class="col-7" *ngIf="tempClaimInfo.dateStartStatus">{{ tempClaimInfo.dateStart | thaidate }}</div>
            <div class="col-5 header">วันสิ้นสุดกรมธรรม์</div>
            <div class="col-7" *ngIf="!tempClaimInfo.dateEndStatus">-</div>
            <div class="col-7" *ngIf="tempClaimInfo.dateEndStatus">{{ tempClaimInfo.dateEnd | thaidate }}</div>
          </div>
          <p class="title-header">ข้อมูลผู้เอาประกันภัย</p>
          <div class="row">
            <div class="col-5 header">ชื่อ-นามสกุล</div>
            <div class="col-7">{{ tempClaimInfo.firstName }} {{ tempClaimInfo.lastName }}</div>
            <div class="col-5 header">เลขบัตรประชาชน</div>
            <div class="col-7">{{ tempClaimInfo.idCard }}</div>
          </div>
          <p class="title-header">ข้อมูลการติดต่อ</p>
          <div class="row">
            <div class="col-5 header">เบอร์โทรศัพท์</div>
            <div class="col-7">{{ tempClaimInfo.phone }}</div>
            <div class="col-5 header">อีเมล</div>
            <div class="col-7">{{ tempClaimInfo.email }}</div>
          </div>
          <p class="title-header">ข้อมูลสถานที่ / ทรัพย์สินที่เอาประกันภัย</p>
          <div class="row">
            <div class="col-5 header">ประเภทของที่อยู่</div>
            <div class="col-7" *ngIf="tempClaimInfo.buildingType == 1" >คอนโด</div>
            <div class="col-7" *ngIf="tempClaimInfo.buildingType == 2" >บ้าน</div>
            <div class="col-5 header">ผู้เอาประกันภัยเป็น</div>
            <div class="col-7" *ngIf="tempClaimInfo.insureBy == 1" >เจ้าของ</div>
            <div class="col-7" *ngIf="tempClaimInfo.insureBy == 2" >ผู้เช่า</div>
            <!-- <div class="col-5 header">บ้านเลขที่</div>
            <div class="col-7">{{ tempClaimInfo.homeNo }}</div> -->
            <div class="col-5 header">รายละเอียดที่อยู่</div>
            <div class="col-7">{{ tempClaimInfo.address }} {{ tempClaimInfo.province }}</div>
            <div class="col-5 header">รหัสไปรษณีย์</div>
            <div class="col-7">{{ tempClaimInfo.zipCode }}</div>
          </div>
          <p class="title-header">ข้อมูลผู้รับผลประโยชน์</p>
          <div class="row">
            <div class="col-5 header">ชื่อผุ้รับผลประโยชน์</div>
            <div class="col-7">{{ tempClaimInfo.benefitFirstName }} {{tempClaimInfo.benefitLastName}}</div>
          </div>
          <p class="title-header">ข้อมูลที่อยู่สำหรับจัดส่งเอกสาร</p>
          <div class="row">
            <!-- <div class="col-5 header">บ้านเลขที่</div>
            <div class="col-7">{{ tempClaimInfo.benefitHomeNo }}</div> -->
            <div class="col-5 header">รายละเอียดที่อยู่</div>
            <div class="col-7">{{ tempClaimInfo.benefitAddress }} {{ tempClaimInfo.benefitProvince }}</div>
            <div class="col-5 header">รหัสไปรษณีย์</div>
            <div class="col-7">{{ tempClaimInfo.benefitZipCode }}</div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>


<div class="card-alert" style="display: none">
  <p>
     <span>กรมธรรม์ของคุณหมดอายุ</span>
    ไม่สามารถแจ้งเคลมได้
  </p>
  <button class="btn btn-danger btn-block" (click)="closeAlert()">ปิด</button>
</div>

<div class="card-alert-success text-center" style="display: none">
  <div class="block-detail">
    <div class="text-success">
       <span>แจ้งเคลมออนไลน์สำเร็จ</span>
    </div>
    <span class="secondary text-gray">หลังจากเราได้รับข้อมูลของคุณแล้ว
      ทางเราจะติดต่อกลับเพื่อทำรายการต่อไป
      ภายใน 24 ชั่วโมง</span>
  </div>
  <img src="assets/images/people-use-ipad.png" class="m-auto" alt="">
  <button class="btn btn-success btn-block" (click)="closeAlertSuccess()">ปิด</button>
</div>

