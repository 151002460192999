<!-- modal ota buy -->
<div class="modal fade" id="otpModalBuy" tabindex="-1" role="dialog" aria-labelledby="otpModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <img src="assets/images/web-living-icon.png" alt="Image" class="w-25"><img src="assets/images/msig-icon.png" alt="Image" width="15%"> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="z-index: 100;" (click)="handleClose()">
          <img src="assets/images/times-white.png" alt="">
        </button>

        <div class="profile-detail">
          <!-- <div class="user-detail">
            <div class="user-img">
              <img src="assets/images/user-icon.png" alt="">
            </div>
            <div class="user-name">
              <p class="welcome">สวัสดีคุณ</p>
              <p>-</p>
            </div>
          </div> -->
          <p class="require-phone" style="display: none;">หมายเลขโทรศัพท์มือถือ เพื่อยืนยันตัวตน</p>
          <p class="require-phone-otp" style="display: none;">ระบุ OTP ที่ได้รับทาง SMS เพื่อยืนยันตัวตน</p>
          <!-- <div class="insurance-list" style="display: none;">
            <div class="d-flex justify-content-between">
              <span>กรมธรรม์ของคุณ</span>
              <span>(0) รายการ</span>
            </div>
          </div> -->
        </div>
      </div>
      <div class="modal-body" >
        <!-- form request otp -->
        <form class="form-input text-center" [formGroup]="requestOTP" (ngSubmit)="handleRequestOTP()" >
          <div class="form-group">
            <label for="">เลขโทรศัพท์มือถือของคุณ</label>
            <input type="text" class="form-control form-control-lg" [ngClass]="{'is-invalid': !requestOTP.get('phone').valid && requestOTP.get('phone').touched}" formControlName="phone" placeholder="ระบุหมายเลขโทรศัพท์ของคุณ" maxlength="10" [readonly]="true">
          </div>
          <div class="block-validate">
            <span class="text-danger d-none" [ngClass]="{'d-block': !requestOTP.get('phone').valid && requestOTP.get('phone').touched}">
              {{errorOtpBuy}}
            </span>
          </div>
          <div class="info-text">
           <span>รหัส OTP สำหรับยืนยันตัวตนของคุณ</span> จะถูกส่ง SMS ไปที่หมายเลขโทรศัพท์ที่คุณระบุ
          </div>
          <button type="submit" class="btn btn-secondary btn-block shadow-secondary btnOtpBuy" [disabled]="!requestOTP.valid">ส่งรหัส OTP</button>
        </form>

        <!-- form confirm otp -->
        <form class="form-otp" [formGroup]="confirmOTP" (ngSubmit)="handleSubmitConfirmOTP()" style="display:none;">
          <div class="form-group text-center">
            <label for="">ระบุ OTP ที่ได้รับทาง SMS</label>
            <ng-otp-input #otpInput (onInputChange)="onOtpChange($event)"  [config]="{length:6}" [config]="config"></ng-otp-input>
          </div>
          <p *ngIf="textErrorOtp == 1" class="text-center text-danger">{{ textErrorOtpMsg }}</p>
          <div class="info-text password-otp">
            รหัส OTP มีอายุการใช้งาน 5 นาที
           </div>
           <p class="text-center">
             <button type="button" class="link" (click)="handleRequestOTP()" [disabled]="(timingRequestOPT > 0)"><img src="assets/images/rotate-ccw.png" alt=""> ขอรหัส OTP อีกครั้ง</button>
           </p>
           <div class="info-text count-request-otp" *ngIf="timingRequestOPT > 0">
            ไมไ่ด้รับรหัส OTP ขอรหัสอีกครั้งใน {{timingRequestOPT}} วินาที
           </div>
          <button type="submit" class="btn btn-secondary btn-block shadow-secondary" [disabled]="!confirmOTP.valid">ยืนยัน</button>
        </form>

      </div>

    </div>
  </div>
</div>


<div class="modal fade" id="buyInsuranceModal" tabindex="-1" role="dialog"  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <img src="assets/images/web-living-icon.png" alt="Image" class="w-25"><img src="assets/images/msig-icon.png" alt="Image" width="15%"> -->
        <button type="button" class="close" (click)="handleClose()"  style="z-index: 100;">
          <img src="assets/images/times-white-gray.png" alt="">
        </button>
        <div class="title-success title-header" style="margin-bottom: 16px; display: none;">ขอบคุณที่สนใจบริการของเรา</div>
        <!-- <div class="header-detail" [ngClass]="{'plan-2': insuranceType=='2'}"> -->
        <div class="header-detail" [ngClass]="{'plan-4': insuranceType=='4'}">
          <img class="header-insurance-img" src="assets/images/header-insurance.png" alt="" [ngClass]="{'d-block': insuranceType == '1'}">
          <img class="header-insurance-img" src="assets/images/plan-home.png" alt="" [ngClass]="{'d-block': insuranceType == '2'}">
          <img class="header-insurance-img" src="assets/images/header-insurance.png" alt="" [ngClass]="{'d-block': insuranceType == '3'}">
          <img class="header-insurance-img" src="assets/images/header-insurance.png" alt="" [ngClass]="{'d-block': insuranceType == '4'}">
          <img class="header-insurance-img" src="assets/images/header-insurance.png" alt="" [ngClass]="{'d-block': insuranceType == '5'}">
          <div class="text-detail">
            <span>ประกันภัย</span>
            <span *ngIf="insuranceType == '1'">ให้เช่าหายห่วง</span>
            <!-- <span *ngIf="insuranceType == '1'" >เพียง 1,999 บาท</span> -->
            <span *ngIf="insuranceType == '2'">อยู่เองหายห่วง</span>
            <!-- <span *ngIf="insuranceType == '2'" >เพียง 999 บาท</span> -->
            <span *ngIf="insuranceType == '3'">ให้เช่าหายห่วง</span>
            <span *ngIf="insuranceType == '3'" >เพียง 2,199 บาท</span>
            <span *ngIf="insuranceType == '4'">ให้เช่าหายห่วง</span>
            <span *ngIf="insuranceType == '4'" >เพียง 3,399 บาท</span>
            <span *ngIf="insuranceType == '5'">ให้เช่าหายห่วง</span>
            <span *ngIf="insuranceType == '5'" >เพียง 5,199 บาท</span>
          </div>
          <img class="shield-header" src="assets/images/shield-h.png" alt="">
        </div>
        <div class="header-confirm" style="display:none">
          <p class="title-header text-center">ยืนยันความถูกต้องของข้อมูล</p>
        </div>
        <div class="border-bottom"></div>
      </div>
      <div class="modal-body">
        <!-- form user detail -->
        <!-- <div class="group-policy">
          <div class="policy-msig">
            <img class="shocked-policy" src="assets/images/shocked.png" alt="">
            คําเตือนของสํานักงานคณะกรรมการกํากับและส่งเสริมการประกอบ
            ธุรกิจประกันภัย (คปภ.)<br>ให้ตอบคําถามข้างต้นตามความเป็นจริงทุกข้อ
            หากผู้เอาประกันภัยปกปิดข้อความจริง หรือแถลงข้อความอันเป็นเท็จ
            จะมีผลให้สัญญาประกันภัยนี้ตกเป็นโมฆียะ ซึ่งบริษัทมีสิทธิ์บอก
            ล้างสัญญาประกันภัยตามประมวลกฎหมายแพ่งและพาณิชย์
            มาตรา 865
          </div>
          <div class="border-bottom"></div>
        </div> -->
        <form class="form-user" [formGroup]="userDetail" (ngSubmit)="handleSubmitUser()">
          <!-- user -->
          <p class="title-header mt-3">ข้อมูลผู้เอาประกันภัย</p>
          <div class="row">
            <div class="form-group col-12">
              <!-- <label for="" class="d-block">ผู้เอาประกันภัยเป็น</label> -->
              <label for="" class="d-block">สถานะ</label>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="postFromOwner" formControlName="postFrom" value="1" class="custom-control-input" checked="true" (change)="clearBeneficiaryFormName(1)">
                <label class="custom-control-label" for="postFromOwner">เจ้าของห้อง/บ้าน</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline" *ngIf="insuranceType == '1'" (change)="clearBeneficiaryFormName(2)">
                <input type="radio" id="postFromRent" formControlName="postFrom" value="2" class="custom-control-input">
                <label class="custom-control-label" for="postFromRent">ผู้เช่าห้อง/บ้าน</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline" *ngIf="insuranceType == '3'" (change)="clearBeneficiaryFormName(2)">
                <input type="radio" id="postFromRent" formControlName="postFrom" value="2" class="custom-control-input">
                <label class="custom-control-label" for="postFromRent">ผู้เช่าห้อง/บ้าน</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline" *ngIf="insuranceType == '4'" (change)="clearBeneficiaryFormName(2)">
                <input type="radio" id="postFromRent" formControlName="postFrom" value="2" class="custom-control-input">
                <label class="custom-control-label" for="postFromRent">ผู้เช่าห้อง/บ้าน</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline" *ngIf="insuranceType == '5'" (change)="clearBeneficiaryFormName(2)">
                <input type="radio" id="postFromRent" formControlName="postFrom" value="2" class="custom-control-input">
                <label class="custom-control-label" for="postFromRent">ผู้เช่าห้อง/บ้าน</label>
              </div>
            </div>
            <!-- <div class="form-group col-12" *ngIf="userDetail.get('postFrom').value == '2'" >
              <label for="">ชื่อเจ้าของทรัพย์(กรณี ผู้เช่าซื้อ)</label>
            </div> -->

            <!-- <div class="form-group col-6" *ngIf="userDetail.get('postFrom').value == '2'">
              <label for="">ชื่อ</label>
              <input type="text" class="form-control form-control-lg" formControlName="insureOwnerFirstName" placeholder="ระบุชื่อของคุณ" [ngClass]="{'is-invalid': !userDetail.get('insureOwnerFirstName').valid && userDetail.get('insureOwnerFirstName').touched}">
            </div>
            <div class="form-group col-6" *ngIf="userDetail.get('postFrom').value == '2'">
              <label for="">นามสกุล</label>
              <input type="text" class="form-control form-control-lg" formControlName="insureOwnerLastName" placeholder="ระบุนามสกุลของคุณ" [ngClass]="{'is-invalid': !userDetail.get('insureOwnerLastName').valid && userDetail.get('insureOwnerLastName').touched}">
            </div> -->

            <div class="form-group col-6">
              <label for="">ชื่อ</label>
              <input type="text" class="form-control form-control-lg" formControlName="firstName" placeholder="ระบุชื่อของคุณ" [ngClass]="{'is-invalid': !userDetail.get('firstName').valid && userDetail.get('firstName').touched}">
            </div>
            <div class="form-group col-6">
              <label for="">นามสกุล</label>
              <input type="text" class="form-control form-control-lg" formControlName="lastName" placeholder="ระบุนามสกุลของคุณ" [ngClass]="{'is-invalid': !userDetail.get('lastName').valid && userDetail.get('lastName').touched}">
            </div>
            <div class="form-group col-12">
              <label for="" class="d-block">เพศ</label>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="genderTypeMale" formControlName="genderType" value="1" class="custom-control-input">
                <label class="custom-control-label" for="genderTypeMale">ชาย</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="genderTypeFemale" formControlName="genderType" value="2" class="custom-control-input">
                <label class="custom-control-label" for="genderTypeFemale">หญิง</label>
              </div>
            </div>

            <div class="row birdday">
              <div class="form-group col-12 mm-0">
                <label for="">วัน - เดือน - ปี เกิด</label>
              </div>
              <div class="form-group col-4">
                <select id="day" name="day" class="form-control" formControlName="day" (change)="checkDateIsValid(1)" [ngClass]="{'is-invalid': !userDetail.get('day').valid && userDetail.get('day').touched}">
                  <option *ngFor="let day of days" value="{{day.Id}}" >{{day.Name}}</option>
                </select>
              </div>
              <div class="form-group col-4">
                <select id="month" name="month" class="form-control" formControlName="month" (change)="checkDateIsValid(1)" [ngClass]="{'is-invalid': !userDetail.get('month').valid && userDetail.get('month').touched}">
                  <option *ngFor="let month of months" value="{{month.Id}}" >{{month.Name}}</option>
                </select>
              </div>
              <div class="form-group col-4">
                <select id="year" name="year" class="form-control" formControlName="year" (change)="checkDateIsValid(1)" [ngClass]="{'is-invalid': !userDetail.get('year').valid && userDetail.get('year').touched}">
                  <option *ngFor="let year of years" value="{{year.Id}}" >{{year.Name}}</option>
                </select>
              </div>
            </div>

            <div class="form-group col-12">
              <label for="" class="d-block">สัญชาติ</label>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="nationalityTypeThai" formControlName="nationalityType" value="1" class="custom-control-input">
                <label class="custom-control-label" for="nationalityTypeThai">ไทย</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="nationalityTypeOther" formControlName="nationalityType" value="2" class="custom-control-input">
                <label class="custom-control-label" for="nationalityTypeOther">อื่นๆ</label>
              </div>
            </div>

            <div class="form-group col-12" *ngIf="userDetail.get('nationalityType').value == '1'">
              <label for="">เลขบัตรประชาชน 13 หลัก</label>
              <input type="text" class="form-control form-control-lg" (keyup)="checkCardId(1)" formControlName="idCard" maxlength="13" placeholder="ระบุเลขบัตรประชาชน 13 หลักของคุณ" [ngClass]="{'is-invalid': !userDetail.get('idCard').valid && userDetail.get('idCard').touched}" numbersOnly>
              <div class="wording-complpte">เลขบัตรประชาชนถูกต้อง</div>
              <div class="wording-wrong">เลขบัตรประชาชนไม่ถูกต้อง</div>
              <div class="block-info">
                <img src="assets/images/tool-tips.png" alt="">
                กรุณาระบุข้อมูลให้ครบถ้วน และตรงกับความเป็นจริง
              </div>
            </div>

            <div class="form-group col-12" *ngIf="userDetail.get('nationalityType').value == '2'">
              <label for="">สัญชาติ</label>
              <select2 [data]="nationality" placeholder="ระบุสัญชาติ" formControlName="nationalityName"></select2>
              <!-- <input type="text" class="form-control form-control-lg" formControlName="nationalityName" placeholder="ระบุสัญชาติ" [ngClass]="{'is-invalid': !userDetail.get('nationalityName').valid && userDetail.get('nationalityName').touched}"> -->
            </div>

            <div class="form-group col-12" *ngIf="userDetail.get('nationalityType').value == '2'">
              <label for="">พาสปอร์ต</label>
              <input type="text" class="form-control form-control-lg" formControlName="idCardPassport" maxlength="9" placeholder="ระบุพาสปอร์ต" [ngClass]="{'is-invalid': !userDetail.get('idCardPassport').valid && userDetail.get('idCardPassport').touched}">
              <div class="block-info">
                <img src="assets/images/tool-tips.png" alt="">
                กรุณาระบุข้อมูลให้ครบถ้วน และตรงกับความเป็นจริง
              </div>
            </div>

          </div>
          <!-- phone & email -->
          <p class="title-header">ข้อมูลการติดต่อ</p>
          <div class="row no-gutters">
            <div class="form-group col-12">
              <label for="">เบอร์โทรศัพท์มือถือ</label>
              <input type="text" class="form-control form-control-lg" formControlName="phone" maxlength="10" placeholder="ระบุเบอร์โทรศัพท์มือถือของคุณ" [ngClass]="{'is-invalid': !userDetail.get('phone').valid && userDetail.get('phone').touched}" numbersOnly>
              <div class="block-info">
                <img src="assets/images/tool-tips.png" alt="">
                ระบบจะส่งรหัส OTP สำหรับยืนยันตัวตนให้คุณทาง SMS
                ไปที่หมายเลขโทรศัพท์ที่คุณระบุ
              </div>
            </div>
            <div class="form-group col-12">
              <label for="">อีเมล</label>
              <input type="text" class="form-control form-control-lg" formControlName="email" placeholder="ระบุอีเมลของคุณ" [ngClass]="{'is-invalid': !userDetail.get('email').valid && userDetail.get('email').touched}">
              <div class="block-info">
                <img src="assets/images/tool-tips.png" alt="">
                <!-- บริษัทจะจัดส่งกรมธรรม์ประกันภัยให้ตามอีเมลที่ระบุ กรุณาระบุ
                อีเมลของผู้เอาประกันภัยเท่านั้น ไม่อนุญาตให้ระบุอีเมลผู้ที่ไม่ใช่
                ผู้เอาประกันภัย -->
                บริษัทจะจัดส่งกรมธรรม์ประกันภัยให้ตามอีเมลที่ระบุ กรุณาระบุ
                อีเมลของผู้เอาประกันภัยเท่านั้น
              </div>
            </div>
          </div>

          <!-- form footer -->
          <div class="block-footer">
            <button type="submit" class="btn btn-success btn-block" [disabled]="!userDetail.valid">ต่อไป</button>
          </div>
        </form>
        <!-- /form user detail -->

        <!-- form address -->
        <form class="form-address" [formGroup]="address" (ngSubmit)="handleSubmitAddress()" style="display:none">
          <!-- <p class="title-header">ข้อมูลที่อยู่สำหรับจัดส่งเอกสาร</p> -->
          <p class="title-header mt-3">ที่อยู่ผู้เอาประกันภัย/จัดส่งเอกสาร</p>
          <div class="row">
            <!-- <div class="form-group col-12">
              <label for="">บ้านเลขที่ <span style="color: red;">*</span></label>
              <input type="text" class="form-control form-control-lg" formControlName="houseNumber" placeholder="ระบุบ้านเลขที่" (keyup)="handleChangeAddress($event);" >
            </div> -->
            <div class="form-group col-12">
              <label class="mb-0" for="">รายละเอียดที่อยู่ <span style="color: red;">*</span></label><br>
              <small class="mb-2 d-block" for="">(ไม่ต้องระบุอำเภอ/เขต และตำบล/แขวง)</small>
              <textarea type="text" class="form-control form-control-lg" rows="5" formControlName="addressDetail" placeholder="บ้านเลขที่, ห้องเลขที่, ชื่อหมู่บ้าน, ชื่อคอนโด, อาคาร, ชั้น, ชื่อถนน" (keyup)="handleChangeAddress($event);"></textarea>
              <div class="block-info">
                <img src="assets/images/tool-tips.png" alt="">
                รายละเอียดที่อยู่สำหรับจัดส่งเอกสาร
              </div>
            </div>
            <div class="form-group col-12">
              <label for="">จังหวัด <span style="color: red;">*</span></label>
              <select2 [data]="province2" placeholder="ระบุจังหวัด" formControlName="province" (update)="handleChangeAddress($event); handleChangeProvice($event, '2')"></select2>
            </div>
            <div class="form-group col-12">
              <label for="">อำเภอ/เขต <span style="color: red;">*</span></label>
              <!-- <input type="text" class="form-control"  placeholder="ระบุอำเภอ/เขต"  formControlName="district" (keyup)="handleChangeAddress($event);"> -->
              <select2 [data]="district2" placeholder="ระบุอำเภอ/เขต" formControlName="district" (update)="handleChangePostcode($event, '2'); handleChangeDistrict($event, '2');"></select2>
            </div>
            <div class="form-group col-12">
              <label for="">ตำบล/แขวง <span style="color: red;">*</span></label>
              <!-- <input type="text" class="form-control"  placeholder="ระบุตำบล/เขต"  formControlName="subdistrict" (keyup)="handleChangeAddress($event);"> -->
              <select2 [data]="subdistrict2" placeholder="ระบุตำบล/เขต" formControlName="subdistrict" (keyup)="handleChangeAddress($event);"></select2>
            </div>
            <div class="form-group col-12">
              <label for="">รหัสไปรษณีย์ <span style="color: red;">*</span></label>
              <!-- <input type="text" class="form-control" placeholder="ระบุรหัสไปรษณีย์"  formControlName="postalCode" [maxlength]="5" value="" numbersOnly> -->
              <input type="text" class="form-control" placeholder="ระบุรหัสไปรษณีย์"  formControlName="postalCode" [maxlength]="5" numbersOnly>
              <!-- <select2 [data]="postalCode2" placeholder="ระบุรหัสไปรษณีย์" formControlName="postalCode" (update)="handleChangeAddress($event)"></select2> -->
            </div>
          </div>

          <!-- form footer -->
          <div class="block-footer">
            <button type="button" class="btn btn-gray btn-block" (click)="FormUser()">กลับ</button>
            <button type="submit" class="btn btn-success btn-block" [disabled]="!address.valid">ต่อไป</button>
          </div>

        </form>
        <!-- /form address -->

        <!-- form building detail -->
        <form class="form-building" [formGroup]="building" (ngSubmit)="handleSubmitBuilding()" style="display:none">
          <p class="title-header mt-3">ที่ตั้งทรัพย์สินที่เอาประกันภัย</p>
          <div class="row">
            <div class="form-group col-12">
              <label for="" class="d-block">ประเภทของที่อยู่</label>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="buildingTypeCondo" formControlName="buildingType" value="1" class="custom-control-input">
                <label class="custom-control-label" for="buildingTypeCondo">คอนโด</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="buildingTypeHouse" formControlName="buildingType" value="2" class="custom-control-input">
                <label class="custom-control-label" for="buildingTypeHouse">บ้าน</label>
              </div>
              <div class="block-info">
                <img src="assets/images/tool-tips.png" alt="">
                บริษัทไม่รับประกันภัยสิ่งปลูกสร้างที่เป็นไม้ล้วน
              </div>
            </div>
            <div class="form-group col-12">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="beneficiary" value="1" formControlName="beneficiary" (change)="handleToggleUser()">
                <label class="custom-control-label" for="beneficiary">ที่เดียวกับที่อยู่ผู้เอาประกันภัย/จัดส่งเอกสาร</label>
              </div>
            </div>

            <!-- <div class="form-group col-12">
              <label for="">บ้านเลขที่ <span style="color: red;">*</span></label>
              <input type="text" class="form-control form-control-lg" placeholder="ระบุบ้านเลขที่" formControlName="houseNumber" [ngClass]="{'is-invalid': !building.get('houseNumber').valid && building.get('houseNumber').touched}">
            </div> -->
            <div class="form-group col-12">
              <label for="" class="mb-0">รายละเอียดที่อยู่ <span style="color: red;">*</span></label><br>
              <small class="mb-2 d-block" for="">(ไม่ต้องระบุอำเภอ/เขต และตำบล/แขวง)</small>
              <textarea type="text" class="form-control form-control-lg" rows="5" formControlName="addressDetail" placeholder="บ้านเลขที่, ห้องเลขที่, ชื่อหมู่บ้าน, ชื่อคอนโด, อาคาร, ชั้น, ชื่อถนน" [ngClass]="{'is-invalid': !building.get('addressDetail').valid && building.get('addressDetail').touched}"></textarea>
            </div>
            <div class="form-group col-12">
              <label for="">จังหวัด <span style="color: red;">*</span></label>
              <select2 [data]="province" placeholder="ระบุจังหวัด" formControlName="province" (update)="handleChangeProvice($event, '1')"></select2>
            </div>
            <div class="form-group col-12">
              <label for="">อำเภอ/เขต <span style="color: red;">*</span></label>
              <!-- <input type="text" class="form-control"  placeholder="ระบุอำเภอ/เขต"  formControlName="district"> -->
              <select2 [data]="district" placeholder="ระบุอำเภอ/เขต" formControlName="district" (update)="handleChangePostcode($event, '1'); handleChangeDistrict($event, '1');"></select2>
              <!-- <select2 [data]="district" placeholder="ระบุอำเภอ/เขต" formControlName="district" (update)="handleChangeDistrict($event, '1')"></select2> -->
            </div>
            <div class="form-group col-12">
              <label for="">ตำบล/แขวง <span style="color: red;">*</span></label>
              <!-- <input type="text" class="form-control"  placeholder="ระบุตำบล/เขต"  formControlName="subdistrict"> -->
              <select2 [data]="subdistrict" placeholder="ระบุตำบล/เขต" formControlName="subdistrict" (keyup)="handleChangeAddress($event);"></select2>
            </div>
            <div class="form-group col-12">
              <label for="">รหัสไปรษณีย์ <span style="color: red;">*</span></label>
              <input type="text" class="form-control" placeholder="ระบุรหัสไปรษณีย์"  [maxLength]="5" formControlName="postalCode" numbersOnly>
              <!-- <select2 [data]="postalCode" placeholder="ระบุรหัสไปรษณีย์" formControlName="postalCode"></select2> -->
            </div>
          </div>
          <!-- <p class="title-header">ชื่อผู้รับผลประโยชน์</p> -->
          <p class="title-header" style="width: 100%;position: relative;" *ngIf="userDetail.get('postFrom').value == '2'">
            ชื่อเจ้าของห้อง/บ้าน
            <span class="tooltip-insur"><i class="far fa-question-circle"></i>
              <span class="tooltiptext">สามารถตรวจสอบข้อมูลเบื้องต้นได้ในสัญญาเช่า</span>
            </span>
          </p>
          <div class="row" formGroupName="beneficiaryForm" *ngIf="userDetail.get('postFrom').value == '2'">
            <!-- <div class="form-group col-12">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="beneficiary" value="1" formControlName="beneficiary" (change)="handleToggleUser()">
                <label class="custom-control-label" for="beneficiary">ชื่อเดียวกับผู้เอาประกันภัย</label>
              </div>
            </div> -->
            <div class="form-group col-6">
              <label for="">ชื่อ <span style="color: red;">*</span></label>
              <input type="text" class="form-control form-control-lg" formControlName="firstName" placeholder="ระบุชื่อของคุณ" [ngClass]="{'is-invalid': !building.get('beneficiaryForm').get('firstName').valid && building.get('beneficiaryForm').get('firstName').touched}">
            </div>
            <div class="form-group col-6">
              <label for="">นามสกุล <span style="color: red;">*</span></label>
              <input type="text" class="form-control form-control-lg" formControlName="lastName" placeholder="ระบุนามสกุลของคุณ" [ngClass]="{'is-invalid': !building.get('beneficiaryForm').get('lastName').valid && building.get('beneficiaryForm').get('lastName').touched}">
            </div>

            <!-- <div class="form-group col-12">
              <label for="" class="d-block">เพศ</label>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="genderTypeMaleBF" formControlName="genderTypeBf" value="1" class="custom-control-input">
                <label class="custom-control-label" for="genderTypeMaleBF">ชาย</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="genderTypeFemaleBf" formControlName="genderTypeBf" value="2" class="custom-control-input">
                <label class="custom-control-label" for="genderTypeFemaleBf">หญิง</label>
              </div>
            </div> -->

            <!-- <div class="row birdday">
              <div class="form-group col-12 mm-0">
                <label for="">วัน - เดือน - ปี เกิด</label>
              </div>
              <div class="form-group col-4">
                <select id="day" name="day" class="form-control" formControlName="day" (change)="checkDateIsValid(2)" [ngClass]="{'is-invalid': !building.get('beneficiaryForm').get('day').valid && building.get('beneficiaryForm').get('day').touched}">
                  <option *ngFor="let day of days" value="{{day.Id}}" >{{day.Name}}</option>
                </select>
              </div>
              <div class="form-group col-4">
                <select id="month" name="month" class="form-control" formControlName="month" (change)="checkDateIsValid(2)" [ngClass]="{'is-invalid': !building.get('beneficiaryForm').get('month').valid && building.get('beneficiaryForm').get('month').touched}">
                  <option *ngFor="let month of months" value="{{month.Id}}" >{{month.Name}}</option>
                </select>
              </div>
              <div class="form-group col-4">
                <select id="year" name="year" class="form-control" formControlName="year" (change)="checkDateIsValid(2)" [ngClass]="{'is-invalid': !building.get('beneficiaryForm').get('year').valid && building.get('beneficiaryForm').get('year').touched}">
                  <option *ngFor="let year of years" value="{{year.Id}}" >{{year.Name}}</option>
                </select>
              </div>
            </div> -->

            <!-- <div class="form-group col-12">
              <label for="" class="d-block">สัญชาติ <span style="color: red;">*</span></label>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="nationalityTypeThaiBf" formControlName="nationalityTypeBf" value="1" class="custom-control-input">
                <label class="custom-control-label" for="nationalityTypeThaiBf">ไทย</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="nationalityTypeOtherBf" formControlName="nationalityTypeBf" value="2" class="custom-control-input">
                <label class="custom-control-label" for="nationalityTypeOtherBf">อื่นๆ</label>
              </div>
            </div> -->

            <!-- <div class="form-group col-12" *ngIf="building.get('beneficiaryForm').get('nationalityTypeBf').value == '1'"> -->
            <div class="form-group col-12">
              <label for="">เลขบัตรประชาชน 13 หลัก <span style="color: red;">*</span></label>
              <input type="text" class="form-control form-control-lg" (keyup)="checkCardId(2)"  formControlName="idCard" maxlength="13" placeholder="ระบุเลขบัตรประชาชน 13 หลักของคุณ" [ngClass]="{'is-invalid': !building.get('beneficiaryForm').get('idCard').valid && building.get('beneficiaryForm').get('idCard').touched}" numbersOnly>
              <div class="wording-wrong-duplicate">เลขบัตรประชาชนซ้ำกับผู้เช่า</div>
              <div class="wording-complpte">เลขบัตรประชาชนถูกต้อง</div>
              <div class="wording-wrong">เลขบัตรประชาชนไม่ถูกต้อง</div>
              <div class="block-info">
                <img src="assets/images/tool-tips.png" alt="">
                กรุณาระบุข้อมูลให้ครบถ้วน และตรงกับความเป็นจริง
              </div>
            </div>

            <!-- <div class="form-group col-12" *ngIf="building.get('beneficiaryForm').get('nationalityTypeBf').value == '2'">
              <label for="">สัญชาติ <span style="color: red;">*</span></label>
              <select2 [data]="nationality" name="nationalityName" placeholder="ระบุสัญชาติ" formControlName="nationalityName"></select2>
            </div>

            <div class="form-group col-12" *ngIf="building.get('beneficiaryForm').get('nationalityTypeBf').value == '2'">
              <label for="">พาสปอร์ต</label>
              <input type="text" class="form-control form-control-lg" formControlName="idCardPassport" maxlength="9" placeholder="ระบุพาสปอร์ต" [ngClass]="{'is-invalid': !building.get('beneficiaryForm').get('idCardPassport').valid && building.get('beneficiaryForm').get('idCardPassport').touched}" >
              <div class="block-info">
                <img src="assets/images/tool-tips.png" alt="">
                กรุณาระบุข้อมูลให้ครบถ้วน และตรงกับความเป็นจริง
              </div>
            </div> -->

          </div>

           <!-- form footer -->
           <div class="block-footer">
            <button type="button" class="btn btn-gray btn-block" (click)="FormAddress()">กลับ</button>
            <button type="submit" class="btn btn-success btn-block" [disabled]="!building.valid">ต่อไป</button>
          </div>
        </form>
        <!-- /form building detail -->

        <!-- form confirm -->
        <div class="form-confirm" style="display: none;">
          <p class="title-header">ข้อมูลผู้เอาประกันภัย</p>
          <div class="row">
            <div class="col-6 header">ชื่อ-นามสกุล</div>
            <div class="col-6">{{userDetail.get('firstName').value}} {{userDetail.get('lastName').value}}</div>
            <div class="col-6 header">เพศ</div>
            <div class="col-6" *ngIf="userDetail.get('genderType').value == '1'">ชาย</div>
            <div class="col-6" *ngIf="userDetail.get('genderType').value == '2'">หญิง</div>
            <div class="col-6 header">วัน-เดือน-ปี เกิด</div>
            <div class="col-6">{{userDetail.get('day').value}} {{nameMonthsP1}} {{userDetail.get('year').value}}</div>
            <div class="col-6 header">สัญชาติ</div>
            <div class="col-6" *ngIf="userDetail.get('nationalityType').value == '1'">ไทย</div>
            <div class="col-6" *ngIf="userDetail.get('nationalityType').value == '2'">{{nationalityKeyName[userDetail.get('nationalityName').value]}}</div>

            <div class="col-6 header" *ngIf="userDetail.get('nationalityType').value == '1'">เลขบัตรประชาชน</div>
            <div class="col-6" *ngIf="userDetail.get('nationalityType').value == '1'">{{userDetail.get('idCard').value}}</div>

            <div class="col-6 header" *ngIf="userDetail.get('nationalityType').value == '2'">พาสปอร์ต</div>
            <div class="col-6" *ngIf="userDetail.get('nationalityType').value == '2'">{{userDetail.get('idCardPassport').value}}</div>
          </div>
          <p class="title-header">ข้อมูลการติดต่อ</p>
          <div class="row">
            <div class="col-6 header">เบอร์โทรศัพท์มือถือ</div>
            <div class="col-6">{{userDetail.get('phone').value}}</div>
            <div class="col-6 header">อีเมล</div>
            <div class="col-6">{{userDetail.get('email').value}}</div>
            <div class="col-12 mt-0 mb-0">
              <div class="block-info">
                <img src="assets/images/tool-tips.png" alt="">
                <!-- บริษัทจะจัดส่งกรมธรรม์ประกันภัยให้ตามอีเมลที่ระบุ กรุณาระบุ
                อีเมลของผู้เอาประกันภัยเท่านั้น ไม่อนุญาตให้ระบุอีเมลผู้ที่ไม่ใช่
                ผู้เอาประกันภัย -->
                บริษัทจะจัดส่งกรมธรรม์ประกันภัยให้ตามอีเมลที่ระบุ กรุณาระบุ
                อีเมลของผู้เอาประกันภัยเท่านั้น
              </div>
            </div>
          </div>

          <!-- <p class="title-header">ข้อมูลสถานที่ / ทรัพย์สินที่เอาประกันภัย</p> -->
          <p class="title-header">ที่อยู่ผู้เอาประกันภัย/จัดส่งเอกสาร</p>
          <div class="row">
            <!-- <div class="col-6 header" *ngIf="userDetail.get('postFrom').value == '2'">ชื่อเจ้าของทรัพย์</div>
            <div class="col-6" *ngIf="userDetail.get('postFrom').value == '2'">{{userDetail.get('insureOwnerFirstName').value + ' ' + userDetail.get('insureOwnerLastName').value}}</div> -->
            <!-- <div class="col-6 header">บ้านเลขที่</div>
            <div class="col-6">{{address.get('houseNumber').value}}</div> -->
            <div class="col-6 header">รายละเอียดที่อยู่</div>
            <div class="col-6">{{address.get('addressDetail').value}}</div>
            <div class="col-6 header">จังหวัด</div>
            <div class="col-6">{{ tempAddress.province2 }}</div>
            <div class="col-6 header">ตำบล/แขวง</div>
            <!-- <div class="col-6">{{ building.get('subdistrict').value }}</div> -->
            <div class="col-6">{{ tempAddress.subdistrict2 }}</div>
            <div class="col-6 header">อำเภอ/เขต</div>
            <!-- <div class="col-6">{{ building.get('district').value }}</div> -->
            <div class="col-6">{{ tempAddress.district2 }}</div>

            <!-- <div class="col-6">{{ tempAddress.district }}</div> -->
            <div class="col-6 header">รหัสไปรษณีย์</div>
            <div class="col-6">{{ address.get('postalCode').value }}</div>
            <!-- <div class="col-6">{{ tempAddress.postalCode }}</div> -->

          </div>

          <div class="form-group confirm-accept" style="margin-top: 0px;">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="confirmPostOffice">
              <label class="custom-control-label" for="confirmPostOffice">ต้องการให้จัดส่งเอกสารกรมธรรม์ทางไปรษณีย์</label>
            </div>
          </div>

          <!-- <p class="title-header">ข้อมูลที่อยู่สำหรับจัดส่งเอกสาร</p> -->
          <p class="title-header">ที่ตั้งทรัพย์สินที่เอาประกันภัย</p>
          <div class="row">
            <div class="col-6 header">ประเภทของที่อยู่</div>
            <div class="col-6 " *ngIf="building.get('buildingType').value == '1'">คอนโด</div>
            <div class="col-6 " *ngIf="building.get('buildingType').value == '2'">บ้าน</div>
            <!-- <div class="col-6 header">บ้านเลขที่</div>
            <div class="col-6 ">{{building.get('houseNumber').value}}</div> -->
            <div class="col-6 header">รายละเอียดที่อยู่</div>
            <div class="col-6 ">{{building.get('addressDetail').value}}</div>
            <div class="col-6 header">จังหวัด</div>
            <div class="col-6 ">{{tempAddress.province }}</div>
            <div class="col-6 header">ตำบล/แขวง</div>
            <!-- <div class="col-6">{{ address.get('subdistrict').value }}</div> -->
            <div class="col-6">{{ tempAddress.subdistrict }}</div>
            <div class="col-6 header">อำเภอ/เขต</div>
            <!-- <div class="col-6">{{ address.get('district').value }}</div> -->
            <div class="col-6">{{ tempAddress.district }}</div>
            <!-- <div class="col-6 ">{{tempAddress.district2}}</div> -->
            <div class="col-6 header">รหัสไปรษณีย์</div>
            <div class="col-6">{{ building.get('postalCode').value }}</div>
            <!-- <div class="col-6 ">{{tempAddress.postalCode2}}</div> -->
          </div>

          <p class="title-header" *ngIf="userDetail.get('postFrom').value == '2'">ชื่อเจ้าของห้อง/บ้าน</p>
          <div class="row" *ngIf="userDetail.get('postFrom').value == '2'">
            <div class="col-6 header">ชื่อ-นามสกุล</div>
            <div class="col-6 ">{{building.get('beneficiaryForm').get('firstName').value}} {{building.get('beneficiaryForm').get('lastName').value}}</div>
            <!-- <div class="col-6 header">เพศ</div>
            <div class="col-6" *ngIf="building.get('beneficiaryForm').get('genderTypeBf').value == '1'">ชาย</div>
            <div class="col-6" *ngIf="building.get('beneficiaryForm').get('genderTypeBf').value == '2'">หญิง</div>
            <div class="col-6 header">วัน-เดือน-ปี เกิด</div>
            <div class="col-6">{{building.get('beneficiaryForm').get('day').value}} {{nameMonthsP2}} {{building.get('beneficiaryForm').get('year').value}}</div> -->
            <!-- <div class="col-6 header">สัญชาติ</div> -->
            <!-- <div class="col-6" *ngIf="building.get('beneficiaryForm').get('nationalityTypeBf').value == '1'">ไทย</div>
            <div class="col-6" *ngIf="building.get('beneficiaryForm').get('nationalityTypeBf').value == '2'">{{nationalityKeyName[building.get('beneficiaryForm').get('nationalityName').value]}}</div> -->
            <!-- <div class="col-6 header" *ngIf="building.get('beneficiaryForm').get('nationalityTypeBf').value == '1'">เลขบัตรประชาชน</div>
            <div class="col-6" *ngIf="building.get('beneficiaryForm').get('nationalityTypeBf').value == '1'">{{building.get('beneficiaryForm').get('idCard').value}}</div> -->
            <div class="col-6 header">เลขบัตรประชาชน</div>
            <div class="col-6">{{building.get('beneficiaryForm').get('idCard').value}}</div>
            <!-- <div class="col-6 header" *ngIf="building.get('beneficiaryForm').get('nationalityTypeBf').value == '2'">พาสปอร์ต</div>
            <div class="col-6" *ngIf="building.get('beneficiaryForm').get('nationalityTypeBf').value == '2'">{{building.get('beneficiaryForm').get('idCardPassport').value}}</div> -->
          </div>

          <p class="title-header">เงื่อนไขและข้อตกลงของบริษัท</p>
          <div class="condition">ข้าพเจ้าในฐานะผู้ซื้อ / ผู้ขอเอาประกันภัยขอรับรองว่า ข้อมูลที่
            ข้าพเจ้าได้ให้ไว้ข้างต้นนี้เป็นความจริงทุกประการ และข้าพเจ้า
            มีความประสงค์จะขอเอาประกันภัยกับบริษัทฯ ตามเงื่อนไขของ
            กรมธรรม์ประกันภัยที่บริษัทฯ มีไว้สำหรับการประกันภัยนี้ อนึ่ง
            ข้าพเจ้ายินยอมให้บริษัทฯ จัดส่งกรมธรรม์ประ...  <span class="btn-link" (click)="openCondition()">อ่านเพิ่มเติม</span>
          </div>

          <div class="form-group confirm-accept">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="confirmAccept">
              <label class="custom-control-label" for="confirmAccept">ข้าพเจ้ายอมรับ / รับทราบ เงื่อนไขของบริษัท</label>
            </div>
            <span id="errConfirm" class="text-danger" style="font-size: 12px; font-weight: 300; display:none;">กรุณากดยอมรับเงื่อนไขเพื่อดำเนินการต่อ</span>
          </div>
          <a target="_blank" href="assets/pdf/plan_insurance.pdf" class="link mb-3 d-block" style="font-size:13px;">อ่านรายละเอียดและเงื่อนไขทั้งหมดของกรมธรรม์คลิกที่นี่</a>

          <!-- form footer -->
          <!-- <div class="block-footer" style="flex-wrap: wrap;justify-content: space-between;"> -->
          <div class="block-footer" style="justify-content: space-between;">
            <button type="button" style="width: 40%;" class="btn btn-gray btn-block" (click)="FormBuilding()">กลับ</button>
            <!-- <button type="button" style="width: 55%;" class="btn btn-success btn-block" (click)="handleSubmitConfirm()" [disabled]="isConfirm">ยืนยันข้อมูลและชำระเงิน</button> -->
            <button type="button" style="width: 55%;" class="btn btn-success btn-block" (click)="handleSubmitConfirmPro()" [disabled]="isConfirm">ยืนยันข้อมูลและชำระเงิน</button>
            <!-- <button type="button" class="btn btn-success btn-block" style="display: none;margin-top: 5px;width: 100%;" (click)="handleSubmitConfirmPro()">ยืนยันข้อมูลและชำระเงิน(1)</button> -->
          </div>
        </div>
        <!-- /form confirm -->

        <!-- form payment -->
        <div class="form-payment" style="display: none;">
          <span class="payment-price" *ngIf="insuranceType == '1'" >1,999 บาท</span>
          <span class="payment-price" *ngIf="insuranceType == '2'" >999 บาท</span>

          <span class="payment-price" *ngIf="insuranceType == '3'" >2,199 บาท</span>
          <span class="payment-price" *ngIf="insuranceType == '4'" >3,399 บาท</span>
          <span class="payment-price" *ngIf="insuranceType == '5'" >5,199 บาท</span>
          <div class="group-payment">
            <!-- <label for="">เลือกวิธีการชำระเงิน</label> -->
            <div class="option-payment">
              <!-- <div class="list-payment" (click)="formPaymentApi('1')">
                PromptPay QR
              </div>
              <div class="list-payment" (click)="formPaymentApi('2')">
                ธนาคารกสิกรไทย K Plus
              </div> -->
              <div class="list-payment color-payment-1" (click)="formPaymentApi('3')" *ngIf="insuranceType == '1'">
                ชำระเงิน
              </div>
              <div class="list-payment color-payment-2" (click)="formPaymentApi('3')" *ngIf="insuranceType == '2'">
                ชำระเงิน
              </div>
              <div class="list-payment color-payment-2" (click)="formPaymentApi('3')" *ngIf="insuranceType == '3'">
                ชำระเงิน
              </div>
              <div class="list-payment color-payment-2" (click)="formPaymentApi('3')" *ngIf="insuranceType == '4'">
                ชำระเงิน
              </div>
              <div class="list-payment color-payment-2" (click)="formPaymentApi('3')" *ngIf="insuranceType == '5'">
                ชำระเงิน
              </div>
            </div>

            <div class="footer-payment">
              <div class="group-list-payment">
                <img class="image-payment-n1" src="assets/images/payment-n1.png" alt="">
                <img class="image-payment-n2" src="assets/images/payment-n2.png" alt="">
              </div>
              <span>ระบบการชำระเงินของ 2C2P มีความปลอดภัย เชื่อถือได้ และ
                ได้รับการรับรองจากธนาคารแห่งประเทศไทย</span>
            </div>
          </div>
        </div>
        <!-- form payment -->

        <!-- form Success -->
        <div class="form-success" style="display:none;">
          <p>ตอนนี้ทาง MSIG ได้ส่งขั้นตอนการชำระเงิน
            ให้ท่านทางอีเมลแล้วเรียบร้อยแล้ว
          </p>
          <p>โดยสามารถชำระเบี้ยประกันได้ทันที
            ซึ่งประกันภัยนี้จะมีผลทันทีที่ท่านชำระเบี้ยประกัน
          </p>
          <div class="contact">
            <p>หากไม่ได้รับอีเมลกรุณาติดต่อ</p>
            <div class="contact-direct">
              <div class="contact-item">
                <img src="assets/images/contact-phone.png" alt="">
                <span>088-554-0725</span>
              </div>
              <div class="contact-item">
                <img src="assets/images/contact-line.png" alt="">
                <span>@Livinginsider</span>
              </div>
            </div>
          </div>

          <button class="btn btn-gray text-white btn-block" (click)="handleClose()">ปิด</button>

        </div>
        <!-- /form Success -->
      </div>
    </div>
  </div>
</div>

<div class="alert-block" style="display: none;">
  <p class="title-header text-danger">ต้องการออกจากหน้านี้
    หรือไม่ ?</p>
  <p class="title-secondary">ข้อมูลของคุณจะยังไม่ถูกบันทึก
    <!-- หากออกจากหน้านี้</p> -->
  <div class="block-button">
    <button class="btn btn-dark" (click)="confirmAlert(false)">ยกเลิก</button>
    <button class="btn btn-danger" (click)="confirmAlert(true)">ออก</button>
  </div>
</div>

<div class="card" id="conditionInsurance" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="padding-bottom: 0px;">
        <!-- <img src="assets/images/web-living-icon.png" alt="Image" class="w-25"><img src="assets/images/msig-icon.png" alt="Image" width="15%"> -->
        <button type="button" class="close" style="z-index: 100;" (click)="closeCondition()">
          <img src="assets/images/times.svg" alt="">
        </button>
      </div>
      <div class="modal-body" >
        <div class="block-condition">
          <p class="title-header">เงื่อนไขและข้อตกลงของบริษัท</p>
          <p>ข้าพเจ้าในฐานะผู้ซื้อ / ผู้ขอเอาประกันภัยขอรับรองว่า ข้อมูลที่ข้าพเจ้าได้ให้ไว้ข้างต้นนี้เป็นความจริงทุกประการ และข้าพเจ้ามีความประสงค์จะขอเอาประกันภัยกับบริษัทฯ ตามเงื่อนไขของกรมธรรม์ประกันภัยที่บริษัทฯ มีไว้สำหรับการประกันภัยนี้ อนึ่ง ข้าพเจ้ายินยอมให้บริษัทฯ จัดส่งกรมธรรม์ประกันภัยไปยังที่อยู่อิเล็กทรอนิกส์ที่ได้ระบุไว้ โดยให้ถือว่าข้อมูลทั้งหมดนี้เป็นส่วนหนึ่งของสัญญาประกันภัยระหว่างข้าพเจ้ากับบริษัทฯ ด้วย</p>
          <p>ข้าพเจ้าขอรับรองว่า ข้อมูลที่ระบุนั้นเป็นข้อมูลของข้าพเจ้า เพื่อซื้อประกันภัยนี้รวมถึงแจ้งผลการพิจารณารับประกันภัย และขอยืนยันว่า ข้อมูล และคำแถลงข้างต้นเป็นความจริงทุกประการ และให้ถือเป็นส่วนหนึ่งของสัญญาประกันภัยระหว่างข้าพเจ้ากับบริษัทฯ และ
            ข้าพเจ้ายินยอมให้บริษัท เอ็ม เอส ไอ จี ประกันภัย (ประเทศไทย) จำกัด (มหาชน) (“บริษัทฯ”) ดำเนินการดังนี้
          </p>
          <p>
            1. เก็บรวบรวม ใช้ และเปิดเผยข้อเท็จจริงเกี่ยวกับทรัพย์สินที่เอาประกันภัย และข้อมูลส่วนบุคคลของข้าพเจ้าให้แก่สำนักงานคณะกรรมการกำกับ และส่งเสริมการประกอบธุรกิจประกันภัย (คปภ.) เพื่อประโยชน์ในการกำกับดูแลธุรกิจประกันภัย
          </p>
          <p>
            2. เก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลซึ่งรวมถึงที่อยู่อิเล็กทรอนิกส์ของข้าพเจ้าให้แก่บุคคลอื่นที่เกี่ยวข้อง เพื่อวัตถุประสงค์ในการแจ้งผลการพิจารณารับประกันภัย และการให้บริการเฉพาะที่เกี่ยวข้องกับการประกันภัยนี้เพื่อประโยชน์ของผู้เอาประกันภัย
          </p>
          <p>คําเตือนของสํานักงานคณะกรรมการกํากับและส่งเสริมการประกอบ ธุรกิจประกันภัย (คปภ.) ให้ตอบคําถามข้างต้นตามความเป็นจริงทุกข้อ หากผู้เอาประกันภัยปกปิดข้อความจริง หรือแถลงข้อความอันเป็นเท็จ จะมีผลให้สัญญาประกันภัยนี้ตกเป็นโมฆียะ ซึ่งบริษัทมีสิทธิ์บอก ล้างสัญญาประกันภัยตามประมวลกฎหมายแพ่งและพาณิชย์ มาตรา 865</p>
          <div class="footer">
            <button class="btn btn-gray text-white btn-block" (click)="closeCondition()">ปิด</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
