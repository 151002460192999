
<div class="card-review">
  <div class="user-profile">
    <div class="user-image">
      <img src="{{userProfile}}" alt="">
    </div>
    <div class="user-profile-detail">
      <p class="title">{{userName}}</p>
      <p>{{userAddress}}</p>
    </div>
  </div>

  <!-- <div class="image-full-frame">
    <img src="{{userImage}}" alt="">
  </div> -->

  <div class="score-rating" data-score="5">
  </div>

  <div class="detail">
    <span>Living Insurance</span> {{userDetail}}
  </div>


</div>
