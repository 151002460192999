import { AfterViewInit, Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { formRequestOTP, checkExpireOtpBuy } from "../components/buy-insurance/buy-insurance.component";
import { ActivatedRoute } from '@angular/router';

import Swal from 'sweetalert2';
declare var $: any;
declare var WhWidgetSendButton;
// declare var validator: any;
const PROTECTS = [
  {img: 'assets/images/fire.png', name: 'อัคคีภัย', bg_color: false},
  {img: 'assets/images/natural-disaster.png', name: 'ภัยธรรมชาติ', bg_color: false},
  {img: 'assets/images/flood.png', name: 'ภัยจากน้ำ', bg_color: false},
  {img: 'assets/images/thief.png', name: 'โจรกรรม ลักทรัพย์', bg_color: false},
  {img: 'assets/images/external-person.png', name: 'บุคคลภายนอก', bg_color: false},
  {img: 'assets/images/furniture.png', name: 'เฟอร์นิเจอร์เสียหาย', bg_color: true},
  {img: 'assets/images/pet-broken.png', name: 'สัตว์เลี้ยงทำลายข้าวของ', bg_color: false},

];

const SERVICES = [
  {img: 'assets/images/air.png', name: 'เครื่องปรับอากาศ'},
  {img: 'assets/images/water.png', name: 'ระบบประปาภายในบ้าน'},
  {img: 'assets/images/plug.png', name: 'ระบบไฟฟ้าภายในบ้าน'},
  {img: 'assets/images/snake.png', name: 'สัตว์ดุร้ายเข้าบ้าน'},
  {img: 'assets/images/cool.png', name: 'ตู้เย็นและเครื่องทำน้ำร้อน'},
  {img: 'assets/images/key.png', name: 'กุญแจบ้าน'},
];

const REVIEWS = [
  {img: 'assets/images/profile/jeff-wall-the-destroyed-room-1.png', name: 'Thanapob', address: 'พระราม 9 – กทม.', profile: 'assets/images/profile/Thanapob.jpg', detail : 'ซื้อแล้วหายห่วงครับ เราเป็นคนเช่าพึ่งโดนเจ้าของห้องหักค่าประกันเพราะไปทำโซฟาขาดโดยไม่ตั้งใจ โดนไป 15,000 บาท ตอนนี้เข็ดมาก ตัดสินใจซื้อไว้กันเหนียวเลย ดีกว่าโดนหักเงินประกัน', score: 5},
  {img: 'assets/images/profile/jeff-wall-the-destroyed-room-2.png', name: 'Neena', address: 'รัชดาภิเษก – กทม.', profile: 'assets/images/profile/neena.jpg', detail : 'อยากได้ประกันแบบนี้มานานแล้ว มีคอนโดให้เช่าหลายที่ค่ะ เจอผู้เช่าทำของเสียหาย เงินประกันที่มีก็ไม่พอ พอให้มาจ่ายเพิ่มก็หายไปเลย', score: 5},
  {img: 'assets/images/profile/jeff-wall-the-destroyed-room-3.png', name: 'เทพพิทักษ์', address: 'ทองหล่อ – กทม.', profile: 'assets/images/profile/เทพพิทักษ์.jpg', detail : 'เคยหาซื้อประกันแบบนี้ แต่ไม่มีเลย ส่วนใหญ่จะให้เลือกตามทุนประกันภัย ซึ่งเราไม่รู้ว่าควรเป็นเท่าไร แต่ประกันให้เช่าหายห่วงแบบนี้ดีมาก เพราะเข้าใจง่ายๆ ว่าคุ้มครองอะไรที่จำเป็นบ้างและคุ้มครองเท่าไร ', score: 4.5},
  {img: 'assets/images/profile/jeff-wall-the-destroyed-room-4.png', name: 'รัศมีแข', address: 'ลาดพร้าว – กทม.', profile: 'assets/images/profile/รัศมีแข.jpg', detail : '1,999 บาทต่อปี ตกเดือนละ 160 กว่าบาท คุ้มมากค่ะ เราเคยจะหาซื้อประกันแบบนี้ แต่ที่อื่นไม่มี อันนี้เข้าใจง่ายๆ ไม่ต้องกรอกอะไรเยอะแยะ ซื้อได้ทันทีเลย ง่ายมาก', score: 5},
]

@Component({
  selector: 'app-home',
  templateUrl: "./home.component.html",
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  insuranceType = 3;
  insuranceTypeModal = 3;
  protects = PROTECTS;
  services = SERVICES;
  reviews = REVIEWS;
  env = environment;
  queryDev = false;

  article = [];

  slideConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "centerPadding": '60px',
    "variableWidth": true,
    "infinite": false,
    "focusOnSelect": true,
    "responsive": [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  constructor(
    private http:HttpClient,
    public route: ActivatedRoute
  ) { }

  public registerModal (type: number) {
    if (type == 1) {
      $('#insurance_type').val('1');
      $("#modal-content-insurance-type1").show();
      $("#modal-content-insurance-type2").hide();
      $("#registerSuccessModal .modal-content-insurance-type1").show();
      $("#registerSuccessModal .modal-content-insurance-type2").hide();
      $('#reportModal').modal();
    } else if (type == 2) {
      $('#insurance_type').val('2');
      $("#modal-content-insurance-type2").show();
      $("#modal-content-insurance-type1").hide();
      $("#registerSuccessModal .modal-content-insurance-type1").hide();
      $("#registerSuccessModal .modal-content-insurance-type2").show();
      $('#reportModal').modal();
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      // production dev
      // if(typeof params.dev === 'undefined' && params.dev != 'test' && window.location.pathname != '/payments/status'){
      //   this.queryDev = true;
      //   window.location.href = 'https://livinginsider.com/';
      // }
      // console.log(this.queryDev);
    });
    this.getQueryArticle();
    $('[data-toggle="tooltip"]').tooltip();
  }

  ngAfterViewInit() {
    // oncanplay="this.play()"
    // (function () {
    //   var options = {
    //       line: " http://line.me/ti/p/%40txw7722f", // Line QR code URL
    //       call_to_action: "Message us", // Call to action
    //       position: "right", // Position may be 'right' or 'left'
    //   };
    //   var proto = document.location.protocol, host = "getbutton.io", url = proto + "//static." + host;
    //   var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = url + '/widget-send-button/js/init.js';
    //   s.onload = function () { WhWidgetSendButton.init(host, proto, options); };
    //   var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x);
    // })();

    $('#insurance-qa').on('shown.bs.collapse', function () {
      $('#insurance-qa').find('[data-toggle="collapse"][aria-expanded="true"] i').css({'transform':'rotate(180deg)'})
    }).on('hidden.bs.collapse', () => {
      $('#insurance-qa').find('[data-toggle="collapse"][aria-expanded="false"] i').css({'transform':'rotate(0deg)'})

    })

    window.onload = function () {
      // let elementVideo = <HTMLVideoElement> document.getElementById('video');
      // elementVideo.muted = true;
      // $('#video').trigger('play');
    }

    $(document).ready(function(){
      $('#reportModal').click(() => {
        $('#reportModal input').removeClass('is-invalid');
        $('#reportModal .form-group span.error').remove();

      })

      $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        // disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
      });

      $('.rating-score-all').each(function() {
        $(this).starRating({
          starSize: 30,
          totalStars: 5,
          strokeWidth: 0,
          readOnly: true,
          initialRating: $(this).attr('data-score'), //score
          activeColor: '#f5a623',
          emptyColor: '#D1DEF5',
          useGradient: false,
        });
      })

      $("#insuranceForm").validate({
        errorElement: "span",
        rules: {
          firstname: 'required',
          lastname: 'required',
          phone: 'required',
          email: {
                   required: true,
                   email: true,//add an email rule that will ensure the value entered is valid email id.
                   maxlength: 255,
                }
        },
        messages: {
          firstname:'ระบุข้อมูลเพื่อทำรายการต่อ',
          lastname:'ระบุข้อมูลเพื่อทำรายการต่อ',
          phone: 'ระบุข้อมูลเพื่อทำรายการต่อ',
          email: {
                      required: "ระบุข้อมูลเพื่อทำรายการต่อ",
                      email: "กรุณาระบุ e-mail ให้ถูกต้อง"
                  }
        },
        submitHandler: function(form) {
          // do other things for a valid form
          $.ajax({
            url: 'http://localhost:13337/v1/insurance/create',
            type: 'POST',                           // Any URL
            data: $('#insuranceForm').serialize(),  // Serialize the form data
            success: function (data, textStatus, xhr) {
              $('#thankyou-insuranct-type').show();
              $('#thankyou-insuranct-contact').show();
              $('#form-insurance').hide();
            },
            error: function (request, status, error)
            {
              Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'กรุณาลองใหม่อีกครั้ง',
              })
            }
          });
        },
        highlight: function(element, errorClass) {
          $(element).addClass('is-invalid');
        },
        invalidHandler: function(event, validator) {
          // 'this' refers to the form
          var errors = validator.numberOfInvalids();
          if (errors) {

          }
        }
      });

    });

  }

  getQueryArticle():void {
    this.http.get(this.env.api+'plus_article.php').subscribe(data => {
      if (data['result_code'] == 0) {
        this.article = data['topic'];
      }
    })
  }

  handleDetailInsurance(type, interestedBuy = 0): void {
    this.insuranceTypeModal = type;
    this.insuranceType = type;
    // console.log('type : ', type);
    // console.log('this.insuranceType : ', this.insuranceType);
    // $('#detail-buy-insurance').modal('show');
    setTimeout(() => {
      $('.policy-msig~.border-bottom').show();
      $('.policy-msig').show();
      $('#buyInsuranceModal').modal('show');
      setTimeout(() => {
        let formUser = $('#buyInsuranceModal .form-user');
        $('#buyInsuranceModal .form-user').animate({
          scrollTop: formUser.top - 30
        }, "slow");
      }, 150);
    }, 600);
    // if (interestedBuy == 1) {
    //   setTimeout(() => {
    //     var modalDetail = $('.scroll-detail-modal').offset();
    //     $('.scroll-detail-modal').animate({
    //       scrollTop: modalDetail.top - 30
    //     }, "slow");
    //     setTimeout(() => {
    //       var modal = $('#detail-buy-insurance').offset();
    //       $('#detail-buy-insurance').animate({
    //         scrollTop: modal.top - 30
    //       }, "slow");
    //     }, 500);
    //   }, 350);
    // } else {
    //   setTimeout(() => {
    //     $('.scroll-detail-modal').animate({
    //       scrollTop: 0
    //     }, "slow");
    //     setTimeout(() => {
    //       $('#detail-buy-insurance').animate({
    //         scrollTop: 0
    //       }, "slow");
    //     }, 500);
    //   }, 350);
    // }
  }

  handleBuyInsurance(type: number, interestedBuy = 0): void {
    this.insuranceType = type;
    $('#buyInsuranceModal').modal('show');
    if (interestedBuy == 1) {
      setTimeout(() => {
        var modalDetail = $('.scroll-detail-modal').offset();
        $('.scroll-detail-modal').animate({
          scrollTop: modalDetail.top - 30
        }, "slow");
        setTimeout(() => {
          var modal = $('#detail-buy-insurance').offset();
          $('#detail-buy-insurance').animate({
            scrollTop: modal.top - 30
          }, "slow");
        }, 500);
      }, 350);
    } else {
      setTimeout(() => {
        $('.scroll-detail-modal').animate({
          scrollTop: 0
        }, "slow");
        setTimeout(() => {
          $('#detail-buy-insurance').animate({
            scrollTop: 0
          }, "slow");
        }, 500);
      }, 350);
    }
    // checkExpireOtpBuy();
    // if (localStorage.getItem('session_token_buy')) {
    //   $('#buyInsuranceModal').modal('show');
    // } else {
    //   $('#otpModalBuy').modal('show');
    //   formRequestOTP();
    // }
  }

  handlePlayVideo(id=0):void {
    // let url = "https://www.youtube.com/watch?v=PtpxysnXjOg";
    let url = "https://www.youtube.com/watch?v=M_zZV1RjC6g";
    let videoModal = document.querySelector('.popup-video');
    if(id == 3){
      url = "https://www.youtube.com/watch?v=doAfCDRXgOg";
    }else if(id == 4){
      url = "https://www.youtube.com/watch?v=fZ_ThtyXJtA";
    }else if(id == 5){
      url = "https://www.youtube.com/watch?v=l4cltfkRyN4";
    }
    if(videoModal){
      videoModal.setAttribute('href', url);
    }
    // $('#video').get(0).play();
    $('.popup-video').click();
    // $('.button-play').hide();
  }

  openRequestClaimInsurance(): void {
    $('#contactModal').modal();

    // if (localStorage.getItem('session_token')) {
    //   this.userDetail = JSON.parse(localStorage.getItem('session_token'));
    //   this.formDetail();
    //   $('#insuranceListModal').modal();
    // } else {
    //   this.formRequestOTP();
    //   // this.formOTP();
    //   // $('#otpModal').modal();
    // }
  }

}

// export function newHandleDetailInsurance(type, interestedBuy = 0): void {
//   const fun = new HomeComponent(null);
//   console.log(type);
//   fun.handleDetailInsurance(type);
// }
