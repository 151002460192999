
<!-- Modal Claim  -->
<div class="modal fade" id="claimModal" tabindex="-1" role="dialog" aria-labelledby="claimModal" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="padding-bottom: 0px;">
        <!-- <img src="assets/images/web-living-icon.png" alt="Image" class="w-25"><img src="assets/images/msig-icon.png" alt="Image" width="15%"> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="z-index: 100;">
          <img src="assets/images/times.svg" alt="">
        </button>
      </div>
      <div class="modal-body" style="margin-top: -30px;">

      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="detail-buy-insurance" tabindex="-1" role="dialog" aria-labelledby="#" aria-hidden="true">
  <div class="modal-dialog" role="document" style="max-width: 560px;">
    <div class="modal-content">
      <div class="modal-header" style="padding-bottom: 0px;">
        <!-- <img src="assets/images/banner-living-msig-logo.png" alt="Image"> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="z-index: 100;">
          <img src="assets/images/times.svg" alt="">
        </button>
      </div>
      <div class="modal-body">
        <div class="insurance-type-rent" *ngIf="insuranceType == '1'">
          <img src="assets/images/shield-card.png" class="logo-shield" alt="">
          <p>
            ประกันภัย
            <span class="highlight">ให้เช่าหายห่วง</span>
            เพียง 1,999 บาท
            <img class="logo" src="assets/images/banner-living-msig-logo.png" alt="Image">
          </p>
        </div>
        <div class="insurance-type-self" *ngIf="insuranceType == '2'" >
          <img src="assets/images/shield-card.png" class="logo-shield" alt="">
          <p>
            ประกันภัย
            <span class="highlight">อยู่เองหายห่วง</span>
            เพียง 999 บาท
            <img class="logo" src="assets/images/banner-living-msig-logo.png" alt="Image">
          </p>
        </div>
        <!-- <div class="insurance-type-buy"></div> -->

        <p class="title-header">ความคุ้มครองที่ได้รับ</p>
        <div class="scroll-detail-modal">
          <div class="block-protect">
            <div class="item-protect">
              <div class="image"><img src="assets/images/protect-fire.png" alt=""></div>
              <div class="detail">
                <p class="title">อัคคีภัย | ภัยระเบิด | ภัยยวดยาน</p>
                <p class="sub-title">เช่น ไฟไหม้ ฟ้าผ่าทำให้ไฟฟ้าลัดวงจร  ฟ้าผ่า ระเบิด ภัยจากการเฉี่ยว
                  หรือการชนของยวดยานพาหนะ ภัยจากอากาศยาน
                </p>
                <p class="title-protect">คุ้มครองไม่เกิน 200,000 บาท</p>
              </div>
            </div>
            <div class="item-protect">
              <div class="image"><img src="assets/images/protect-water.png" alt=""></div>
              <div class="detail">
                <p class="title">ภัยจากน้ำ</p>
                <p class="sub-title">
                  เช่น ก๊อกน้ำแตก แล้วน้ำรั่วทำพื้นห้องเสียหาย, น้ำรั่วซึมจากฝนตกเข้ามาให้ห้องเสียหาย
                </p>
                <p class="title-protect">คุ้มครองไม่เกิน 200,000 บาท</p>
              </div>
            </div>
            <div class="item-protect">
              <div class="image"><img src="assets/images/protect-water-floor.png" alt=""></div>
              <div class="detail">
                <p class="title">ภัยธรรมชาติจากน้ำท่วม</p>
                <p class="title-protect">คุ้มครองไม่เกิน 20,000 บาท</p>
              </div>
            </div>
            <div class="item-protect">
              <div class="image"><img src="assets/images/protect-natural-disaster.png" alt=""></div>
              <div class="detail">
                <p class="title">ภัยพายุ ภัยจากควัน แผ่นดินไหว ภูเขาไฟระเบิด สึนามิ ลูกเห็บ</p>
                <p class="title-protect">คุ้มครองไม่เกิน 20,000 บาท</p>
              </div>
            </div>
          </div>

          <div class="block-protect">
            <div class="item-protect">
              <div class="image"><img src="assets/images/protect-thief.png" alt=""></div>
              <div class="detail">
                <p class="title">โจรกรรม ลักทรัพย์ ที่ปรากฎร่องรอยงัดแงะ</p>
                <p class="sub-title">
                  เช่น มีการงัดแงะมาขโมยทรัพย์สินภายในบ้านหรือห้องชุด
                </p>
                <p class="title-protect">คุ้มครองไม่เกิน 20,000 บาท</p>
              </div>
            </div>
          </div>

          <div class="block-protect">
            <div class="item-protect">
              <div class="image"><img src="assets/images/protect-external-person.png" alt=""></div>
              <div class="detail">
                <p class="title">ชีวิต ร่างกายและทรัพย์สินของบุคคลภายนอก</p>
                <p class="sub-title">
                  เช่น ของตกจากระเบียงไปโดนรถยนต์หรือร่างกายผู้อื่นบาดเจ็บ,
                  น้ำรั่วจากห้องเราไปทำความเสียหายห้องผู้อื่น
                </p>
                <p class="title-protect">คุ้มครองไม่เกิน 50,000 บาท</p>
              </div>
            </div>
          </div>

          <!-- info -->
          <div class="block-info" *ngIf="insuranceType == '1'">
            <!-- <img src="assets/images/info.png" alt=""> -->
            <p class="title">หมายเหตุ</p>
            <p>*ภัยจากน้ำ ผู้เอาประกันต้องรับผิดชอบความเสียหายส่วนแรก 3,000 บาท</p>
            <p>*ภัยโจรกรรม ไม่รวม ทรัพย์สินส่วนบุคคล เช่น โทรศัพท์มือถือ
              เครื่องคอมพิวเตอร์พกพาและ/หรืออุปกรณ์อิเล็กโทรนิกส์
              อุปกรณ์สื่อสารพกพาทุกประเภท และ/หรือ กล้องถ่ายรูป
              ถ่ายวีดีโอ และ/หรือ สื่อบันทึกต่างๆ</p>
          </div>

          <!-- block protect -->
          <div class="block-protect" *ngIf="insuranceType == '1'" >
            <div class="item-protect">
              <div class="image"><img src="assets/images/protect-furniture.png" alt=""></div>
              <div class="detail">
                <p class="title ">ความคุ้มครองทรัพย์สินภายในบ้านหรือห้องชุด
                  ที่ทำการปล่อยเช่า ประกอบด้วย 4 หมวด โดยมีรายละเอียดดังนี้</p>

                <p class="title mb-0">เครื่องนอน : ฟูก ผ้าห่ม หมอน</p>
                <p class="sub-title">
                  ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีกขาด จากอุบัติเหตุ
                  แต่ทั้งนี้ไม่รวมการเปรอะเปื้อนจากประจำเดือนของสุภาพสตรี
                </p>

                <p class="title mb-0">เครื่องตกแต่ง : โคมไฟ กรอบรูป ผ้าม่าน </p>
                <p class="sub-title">
                  ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก
                  จากอุบัติเหตุ
                </p>

                <p class="title mb-0">ชุดอำนวยความสะดวก : โซฟา, โต๊ะทานข้าว, โต๊ะทำงาน,
                  โต๊ะกลาง(หน้าโซฟา), เก้าอี้, ชั้นวางทีวี </p>
                <p class="sub-title">
                  ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก
                  จากอุบัติเหตุ
                </p>

                <p class="title mb-0">กลุ่มเครื่องใช้ไฟฟ้า : โทรทัศน์ ตู้เย็น ไมโครเวฟ เครื่องซักผ้า </p>
                <p class="sub-title">
                  ต้องมีความเสียหายทางกายภาพชัดเจน เช่น ฉีก ขาด แตกหัก
                  ไม่รวมความเสียหายจากการขัดข้องของกลไกภายใน
                  (ไม่คุ้มครองเช่น ตู้เย็น/เครื่องซักผ้า เสียไม่ทำงาน เป็นต้น )
                  จากอุบัติเหตุ
                </p>

                <p class="title-protect">คุ้มครองไม่เกิน 50,000 บาท</p>
              </div>
            </div>
            <div class="item-protect">
              <div class="image"><img src="assets/images/protect-pet-broken.png" alt=""></div>
              <div class="detail">
                <p class="title">สำหรับความคุ้มครองสุดพิเศษเพิ่มความคุ้มครองกรณีสัตว์เลี้ยง
                  (สุนัข/แมว ) ของผู้เช่าทำความเสียหายจากการกัด แทะ
                  ต่ออุปกรณ์ใน 4 หมวดข้างต้นที่กล่าวมา
                  **จะเริ่มมีคุ้มครองให้ตั้งแต่วันที่ 16 ธค 63 </p>
                <p class="title-protect">คุ้มครองไม่เกิน 10,000 บาท</p>
              </div>
            </div>
          </div>
        </div>

        <!-- info -->
        <div class="block-info" *ngIf="insuranceType == '2'">
          <!-- <img src="assets/images/info.png" alt=""> -->
          <p class="title">หมายเหตุ</p>
          <p>*ภัยจากน้ำ ผู้เอาประกันต้องรับผิดชอบความเสียหายส่วนแรก 3,000 บาท</p>
          <p>*ภัยโจรกรรม ไม่รวม ทรัพย์สินส่วนบุคคล เช่น โทรศัพท์มือถือ
            เครื่องคอมพิวเตอร์พกพาและ/หรืออุปกรณ์อิเล็กโทรนิกส์
            อุปกรณ์สื่อสารพกพาทุกประเภท และ/หรือ กล้องถ่ายรูป
            ถ่ายวีดีโอ และ/หรือ สื่อบันทึกต่างๆ</p>
        </div>

        <!-- info -->
        <div class="block-info" *ngIf="insuranceType == '1'">
          <!-- <img src="assets/images/info.png" alt=""> -->
          <p class="title">หมายเหตุ</p>
          <p>ผู้เอาประกันต้องรับผิดชอบความเสียหายส่วนแรก 5,000 บาท</p>
          <p>- ไม่รวมความเสียหายต่อเฟอร์นิเจอร์บิ้วอินต่างๆที่โครงการติดตั้งมาให้</p>
          <p>- ไม่รวมรอยขีดช่วนและบุบที่พื้นผิวของทรัพย์สิน</p>
          <p>- ไม่รวมการขัดข้องภายในระบบกลไกของทรัพย์สินเอง</p>
          <p>- ไม่รวมการสึกหรอ เสื่อมสภาพ ผุกร่อน เปรอะเปรื้อนตามการใช้งานปกติ</p>
        </div>

        <!-- <a target="_blank" href="assets/pdf/insurance_1999.pdf" class="link" *ngIf="insuranceType == '1'">อ่านรายละเอียดและเงื่อนไขทั้งหมดของกรมธรรม์คลิกที่นี่</a>
        <a target="_blank" href="assets/pdf/insurance_999.pdf" class="link" *ngIf="insuranceType == '2'">อ่านรายละเอียดและเงื่อนไขทั้งหมดของกรมธรรม์คลิกที่นี่</a> -->

        <div class="buy-insurance-comfirm">
          <span *ngIf="insuranceType == '1'">สนใจซื้อประกันภัยให้เช่าหายห่วง 1,999 บาท แล้วใช่ไหม ?</span>
          <span *ngIf="insuranceType == '2'">สนใจซื้อประกันภัยให้เช่าหายห่วง 999 บาท แล้วใช่ไหม ?</span>
          <div class="buy-insurance-detail">
            <div class="">
              <input type="checkbox" id="comfirmBuy" (change)="comfirmBuy()">
              ข้าพเจ้ายอมรับ
              <a href="assets/pdf/insurance_1999.pdf" target="_blank" *ngIf="insuranceType == '1'">เงื่อนไขและข้อกำหนด</a>
              <a href="assets/pdf/insurance_999.pdf" target="_blank" *ngIf="insuranceType == '2'">เงื่อนไขและข้อกำหนด</a>
              การทำประกันภัยนี้
              <!-- <span>*</span> -->
            </div>
            <div class="buy-insurance-wronging">
              กรุณาคลิกเลือก เพื่อยอมรับ เงื่อนไขและข้อกำหนด ของการทำประกันภัย
            </div>
            <button type="submit" class="btn btn-success btn-block" id="comfirmBuyAssert" (click)="handleInsuranceOpen()">สนใจซื้อ ></button>
          </div>
        </div>

        <!-- <div class="buy-insurance-type-rent" *ngIf="insuranceType == '1'" >
          <div class="block-detail">
            <p>
              <span>สนใจซื้อประกันภัย</span>
              ให้เช่าหายห่วง 1,999 บาท แล้วใช่ไหม ?
            </p>
            <button class="btn btn-block btn-success-primary" (click)="handleInsuranceOpen()">สนใจซื้อ</button>
          </div>
          <div class="block-image">
            <img src="assets/images/shield-card.png" class="logo-shield" alt="">
          </div>
        </div> -->

        <!-- <div class="buy-insurance-type-self" *ngIf="insuranceType == '2'" >
          <div class="block-detail">
            <p>
              <span>สนใจซื้อประกันภัย</span>
              อยู่เองหายห่วง 999 บาท แล้วใช่ไหม ?
            </p>
            <button class="btn btn-block btn-success-primary" (click)="handleInsuranceOpen()">สนใจซื้อ</button>
          </div>
          <div class="block-image">
            <img src="assets/images/shield-card.png" class="logo-shield" alt="">
          </div>
        </div> -->


      </div>
    </div>
  </div>
</div>





<!-- contact -->
<div class="modal fade" id="contactModal" tabindex="-1" role="dialog" aria-labelledby="contactModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 304px">
    <div class="modal-content">
      <div class="modal-header" style="padding-bottom: 0px;">
        <img src="assets/images/banner-living-msig-logo.png" alt="" style="width: 200px; margin: auto;">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="z-index: 100; position: absolute; top: 5px; right: 10px !important;">
          <img src="assets/images/times.svg" alt="">
        </button>
      </div>
      <div class="modal-body">
        <!-- <p class="title-line">LINE ID</p> -->
        <p class="title-sup">ติดต่อสอบถาม</p>
        <div class="qr-code">
          <img src="assets/images/qr-code-line1.png" alt="">
        </div>
        <a >สแกนเพื่อ ADD LINE</a>
        <!-- <iframe src="http://line.me/ti/p/%40txw7722f" class="w-100" title="Contact Line Livinginsider"></iframe> -->
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="#" tabindex="-1" role="dialog" aria-labelledby="#" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="padding-bottom: 0px;">
        <!-- <img src="assets/images/web-living-icon.png" alt="Image" class="w-25"><img src="assets/images/msig-icon.png" alt="Image" width="15%"> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="z-index: 100;">
          <img src="assets/images/times.svg" alt="">
        </button>
      </div>
      <div class="modal-body">

      </div>
    </div>
  </div>
</div>
