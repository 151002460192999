<!--================ Start Header Menu Area =================-->
<section class="header_area w-100">
  <nav class="navbar navbar-expand-lg navbar-light bg-light navbar-custom">
    <div class="container">
      <div class="navbar-brand">
        <a href="#">
          <img src="assets/images/living.png" alt="Image">
        </a>
        <a href="#">
          <img src="assets/images/msig.png" alt="Image">
        </a>
      </div>
      <button class="navbar-toggler" type="button" (click)="toggleMenu()">
        <!-- <span class="navbar-toggler-icon"></span> -->
        <img src="assets/images/burger.png" alt="">
      </button>
      <div class="collapse navbar-collapse">
        <div class="navbar-nav">
          <div class="navar-item" *ngFor="let navbar of navbars">
            <a class="nav-item nav-link" href="{{navbar.link}}" *ngIf="navbar.name != 'การแจ้งเคลม'" >{{navbar.name}}</a>
            <a class="nav-item nav-link" href="#" *ngIf="navbar.name == 'การแจ้งเคลม'" (click)="requestClaim.openRequestClaim($event)">{{navbar.name}}</a>
          </div>
        </div>
      </div>
      <div class="navbar-claim">
        <div class="title-claim"><img src="assets/images/user-icon.png" alt="Image" style=""> <span>ประกันภัยของฉัน</span></div>
        <button type="button" class="btn" (click)="requestClaim.openRequestClaim($event)">แจ้งเคลม</button>
      </div>
    </div>

  </nav>

  <div class="navbar-mobile">
    <a href="{{(navbar.name == 'การแจ้งเคลม')?'#':navbar.link}}" (click)="requestClaim.openRequestClaim($event)" class="item" *ngFor="let navbar of navbars" >
      {{navbar.name}}
    </a>
  </div>

</section>

<app-request-claim #requestClaim></app-request-claim>
<!--================ End Header Menu Area =================-->

