<div class="card-insurance">
  <div class="overlay" *ngIf="insuranceStatus == 0"></div>
  <p class="title-header" *ngIf="postFrom == 1" >ประกันภัย ให้เช่าหายห่วง</p>
  <p class="title-header" *ngIf="postFrom == 2" >ประกันภัย อยู่เองหายห่วง</p>
  <div class="detail-insurance">
    <div class="row no-gutters">
      <div class="form-group col-12">
        <p class="title-sub">ที่อยู่เอาประกันภัย</p>
        <p>{{address}}</p>
      </div>
      <div class="form-group building-type col-12">
        <div class="block-condo" *ngIf="buildingType == 1" >
          <img src="assets/images/project-pin.png" alt="">
        </div>
        <div class="block-home" *ngIf="buildingType == 2" >
          <img src="assets/images/home.png" alt="">
        </div>
        <div class="block-detail">
          <p class="title-sub">ประเภทที่อยู่</p>
          <p>{{buildingName}}</p>
        </div>
      </div>
      <div class="form-group col-6">
        <p class="title-sub">วันเริ่มกรมธรรม์</p>
        <p *ngIf="dateStartStatus" >{{dateStart | thaidate}}</p>
        <p *ngIf="!dateStartStatus">-</p>
      </div>
      <div class="form-group col-6">
        <p class="title-sub">วันสิ้นสุดกรมธรรม์</p>
        <p *ngIf="!dateEndStatus">-</p>
        <p *ngIf="dateEnd >= dateNow" >{{dateEnd | thaidate}}</p>
        <p class="text-danger" *ngIf="dateEnd < dateNow" >กรมธรรม์หมดอายุ</p>
      </div>
    </div>

  </div>
  <div class="button-menu">
    <button class="btn btn-light-custom btn-block"
      [ngClass]="{'disabled': (dateEnd < dateNow || insuranceStatus == 0 || !dateEndStatus)}"
      (click)="handleOpenClaim()"
    >แจ้งเคลม</button>
    <button class="btn btn-light-custom btn-block" (click)="handleOpenHistory()">ประวัติ ({{countClaim}})</button>
    <button class="btn btn-light-custom btn-block" (click)="handleOpenDetail()">รายละเอียด</button>
  </div>
</div>
