import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { NumberFormatPipe } from './functions/number.pipe';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeadermenuComponent } from './headermenu/headermenu.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { CardCustomerReviewComponent } from './components/card-customer-review/card-customer-review.component';
import { ModalsComponent } from './modals/modals.component';
import { RequestClaimComponent } from './components/request-claim/request-claim.component';
import { BuyInsuranceComponent } from './components/buy-insurance/buy-insurance.component';
// Npm Component
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Select2Module } from 'ng-select2-component';
import { NgOtpInputModule } from 'ng-otp-input';
import { DpDatePickerModule } from 'ng2-date-picker';

// function
import { NumberDirective } from './functions/numberOnly';
import { ThaiDatePipe } from './functions/dateThai.pipe';

import { CardArticleComponent } from './components/card-article/card-article.component';
import { InvoiceComponent } from './page/invoice/invoice.component';
import { CardInsuranceComponent } from './components/card-insurance/card-insurance.component';
import { CardHistoryClaimComponent } from './components/card-history-claim/card-history-claim.component';
import { PaymentComponent } from './payments/payment-status/payment.component';

@NgModule({
  declarations: [
    AppComponent,
    ThaiDatePipe,
    HeadermenuComponent,
    HomeComponent,
    FooterComponent,
    CardCustomerReviewComponent,
    ModalsComponent,
    RequestClaimComponent,
    BuyInsuranceComponent,
    NumberDirective,
    CardArticleComponent,
    InvoiceComponent,
    CardInsuranceComponent,
    CardHistoryClaimComponent,
    NumberFormatPipe,
    PaymentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    Select2Module,
    NgOtpInputModule,
    DpDatePickerModule,
  ],
  providers: [DatePipe, NumberFormatPipe],
  bootstrap: [AppComponent],
  exports: [NumberFormatPipe]
})
export class AppModule { }
