import { Component, OnInit, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Event } from '@angular/router';
import { Report, Block, Confirm } from 'notiflix';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import {
  ApiRequestOTP,
  ApiConfirmOTP,
  ApiListInsurance,
  InsuranceList,
  ApiUploadImage,
  TEMPCLAIMINFO,
  UserDetail,
  TempRequestClaim,
  ApiClaimList,
  ClaimList
} from '../../interfaces/request-claim';

import { countDown, alertPopUp } from '../../functions/functionMain';
import { UploadFile, MessageAlert, API } from '../../interfaces/middle';
import { NumberFormatPipe } from '../../functions/number.pipe';

Report.Init({width: '250px', backOverlay: false, backgroundColor: '#ffffff', svgSize: '0px'});

declare var $: any;

interface TempImage {
  urlImage?: string;
  image?: string;
}
@Component({
  selector: 'app-request-claim',
  templateUrl: './request-claim.component.html',
  styleUrls: ['./request-claim.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ NumberFormatPipe ]
})
export class RequestClaimComponent implements OnInit, AfterViewInit {
  @ViewChild('otpInput') ngOtpInputRef: any;

  // @Output() openDetail: EventEmitter<any> = new EventEmitter<any>();
  env = environment;
  insuranceList: InsuranceList[] = [];
  timingRequestOPT = 60;
  responseRequestOTP: ApiRequestOTP;
  userDetail: UserDetail;
  historyClaimList: ClaimList[];
  pageSelector: string;
  pageTempParam: any;

  requestOTP: any = new FormGroup({
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('(^0)([0-9]){9}$'),
      ]),
  });

  confirmOTP: any = new FormGroup({
    otpToken: new FormControl('', [
      Validators.required,
    ]),
    otpActivate: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
    ]),
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('(^0)([0-9]){9}$'),
    ]),
  });

  requestClaim: any = new FormGroup({
    insuranceID: new FormControl('', [
      Validators.required,
    ]),
    accidentDate: new FormControl('', [
      Validators.required,
    ]),
    accidentDetail: new FormControl('', [
      Validators.required,
    ]),
    accidentPrice: new FormControl('', [

    ]),
    imagePhoto: new FormControl([], [
      Validators.required
    ]),
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('(^0)([0-9]){9}$')
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern("^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
    ]),
    acceptConfirm: new FormControl('', [
      Validators.required
    ])
  });

  tempClaimInfo: TEMPCLAIMINFO = {};
  tempRequestClaim: TempRequestClaim = {};
  tempImageRequestClaim: Array<TempImage> = [];
  viewImageHistory: ClaimList;

  @ViewChild('ngOtpInput', { static: false}) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '40px',
      height: '40px',
      'font-size': '16px',
      'font-family': 'Prompt',
      'border-radius': '8px',
      border: 'solid 1px #d1def5'
    }
  };

  configDatePicker = {
    theme : 'dp-material',
    closeOnSelect: true,
    format: 'DD-MM-YYYY',
    locale: 'th',
    max: this.datePipe.transform(new Date(), 'dd-MM-yyyy'),
    maxDate: this.datePipe.transform(new Date(), 'dd-MM-yyyy')
  };

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private formatPipe: NumberFormatPipe
  ) {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    //
    $(document).ready(() => {
      // $('.group-form-request-claim').hide();
      // $('.group-detail-request-claim').show();
      $('[datepicker]').datepicker({
        format: 'dd/mm/yyyy',
        todayBtn: true,
        language: 'th',
        thaiyear: true
      });

    });
  }

  openRequestClaim(e): void {
    // console.log(e);
    if($(e.target).attr('href') == '#' || $(e.target).attr('href') == undefined){
      $('#contactModal').modal();
    }

    // if (localStorage.getItem('session_token')) {
    //   this.userDetail = JSON.parse(localStorage.getItem('session_token'));
    //   this.formDetail();
    //   $('#insuranceListModal').modal();
    // } else {
    //   this.formRequestOTP();
    //   // this.formOTP();
    //   // $('#otpModal').modal();
    // }
  }

  handleRequestOTP(): void {
    const option: object = {
      params: new HttpParams()
              .set('phone', this.requestOTP.get('phone').value)
    };
    this.http.get<ApiRequestOTP>(`${this.env.api}plus_validate_otp.php`, option).subscribe(data  => {
      if (data?.result_code === 0) {
        this.confirmOTP.patchValue({
          otpToken: data.otp_token,
          phone: this.requestOTP.get('phone').value,
        });
        this.formOTP();
      } else {
        this.responseRequestOTP = data;
        this.requestOTP.get('phone').setErrors({'incorrect': true});
      }
    });
  }

  onOtpChange(value: any): void {
    this.confirmOTP.patchValue({
      otpActivate: value
    });

    this.handleSubmitConfirmOTP();
  }

  formRequestOTP(): void {
    $('#otpModal .form-otp').fadeOut(300, () => {
      $('#otpModal .form-input').fadeIn(300);
      $('#otpModal .modal-header').css({'display': 'flex'});
      $('#otpModal .modal-body').css({'display': 'block'});
      $('#otpModal').modal();
    });
  }

  formOTP(): void {
    $('#otpModal .form-input').fadeOut(300, () => {
      $('#otpModal .form-otp').fadeIn(300);
      this.requestOTPAgain();
    });
  }

  requestOTPAgain(): void {
    countDown(60, (res) => {
      this.timingRequestOPT = res;
    });
  }

  handleSubmitConfirmOTP(): void {

    const option: object = {
      params: new HttpParams()
              .set('phone', this.confirmOTP.get('phone').value)
              .set('otp_token', this.confirmOTP.get('otpToken').value)
              .set('activate_code', this.confirmOTP.get('otpActivate').value)
    };

    if (this.confirmOTP.get('otpActivate').valid) {
      Block.Pulse('#otpModal .modal-content');
      this.http.get<ApiConfirmOTP>(`${this.env.api}plus_validate_otp_check.php`, option).subscribe(data => {
        if (data.result_code === 0) {
          const session: object = {
            token: data.device_id,
            phone: this.confirmOTP.get('phone').value
          };

          this.userDetail = session;
          localStorage.setItem('session_token', JSON.stringify(session));
          // open list my insurance
          $('.modal').modal('hide');
          Block.Remove('#otpModal .modal-content');
          Block.Remove('#insuranceListModal .modal-content');

          this.openInsuranceList();

        } else {
          Block.Remove('#otpModal .modal-content');
          Report.Warning(
            'เกิดข้อผิดพลาด',
            data.result_msg,
            'รับทราบ');
        }
      });
    }
  }

  openInsuranceList(): void {
    setTimeout(() => {
      if (this.pageSelector == 'formDetail') {
        this.formDetail();
      } else if (this.pageSelector == 'pageDetailInsurance') {
        this.detailInsurance(this.pageTempParam);
      } else if (this.pageSelector == 'pageHistory') {
        this.historyInsurance(this.pageTempParam);
      } else if (this.pageSelector == 'pageRequestClaim') {
        this.claimInsurance(this.pageTempParam);
      } else {
        this.formDetail();
      }
      $('#insuranceListModal').modal();
    }, 500);
  }

  // Call Back Card
  detailInsurance(object: TEMPCLAIMINFO): void {
    !moment(object.dateStart).isValid() ?  object.dateStartStatus = false : object.dateStartStatus = true ;
    !moment(object.dateEnd).isValid() ?  object.dateEndStatus = false :  object.dateEndStatus = true;

    this.tempClaimInfo = object;
    this.pageTempParam = object;
    this.pageDetailInsurance();
  }

  pageDetailInsurance(): void {
    this.pageSelector = 'pageDetailInsurance';
    $('.modal-body, .modal-header').fadeOut(300, () => {
      $('.modal-detail-insurance').fadeIn(300);
    });
  }

  historyInsurance(insuranceID: string): void {
    this.pageTempParam = insuranceID;
    this.pageHistory();

    const option: object = {
      params: new HttpParams()
              .set('phone', this.userDetail.phone)
              .set('device_id', this.userDetail.token)
              .set('device_type', '1')
              .set('insurance_id', insuranceID)
    };

    this.http.get<ApiClaimList>(`${this.env.api}plus_insurance_claim_list.php`, option).subscribe(
      data => {
        if (data.result_code == 0) {
          const res = data.list.map((e) => {
            const photo = e.claim_photo as string;
            e.claim_photo = photo.split('||');
            e.claim_accident_date = new Date(e.claim_accident_date);
            e.claim_create_date = new Date(e.claim_create_date);
            return e;
          });
          this.historyClaimList = res;

        } else if (data.result_code == 1) {
          alertPopUp({
            title: 'เกิดข้อผิดพลาด',
            detail: data.result_msg,
            button: 'ปิด'
          });
        }
      },
      error => {
        if (error.status === 401) {
          this.expireInsurance();
        } else {
          const message: MessageAlert = {};
          message.title = 'เกิดข้อผิดพลาด';
          message.detail = 'เกิดข้อผิดพลาดในการเชื่อมต่อ กรุณาลองใหม่ภายหลัง';
          message.button = 'ปิด';
          Report.Warning(
            message.title,
            message.detail,
            message.button
          );
          this.closeDetail();
        }
      }
    );
  }

  pageHistory(): void {
    this.pageSelector = 'pageHistory'
    $('.modal-body, .modal-header').fadeOut(300, () => {
      $('.modal-history').fadeIn(300);
    });
  }

  pageViewImage(history: ClaimList): void {
    this.viewImageHistory = history;
    $('.modal-history').fadeOut(300, () => {
      $('.modal-view-image').fadeIn(300);

      $('.parent-container').magnificPopup({
        delegate: 'a', // child items selector, by clicking on it popup will open
        type: 'image',
        gallery: { enabled: true }
        // other options
      });

    });
  }

  claimInsurance(object: TEMPCLAIMINFO): void {
    !moment(object.dateStart).isValid() ?  object.dateStartStatus = false : object.dateStartStatus = true ;
    !moment(object.dateEnd).isValid() ?  object.dateEndStatus = false :  object.dateEndStatus = true;

    this.tempClaimInfo = object;
    this.pageTempParam = object;
    this.requestClaim.patchValue({
      insuranceID: object.insuranceID
    });
    this.pageRequestClaim();
  }
  // End Call Back Card

  pageRequestClaim(): void {
    this.pageSelector = 'pageRequestClaim';
    this.backToFormRequestClaim ();

    $('.modal-body, .modal-header').fadeOut(300, () => {
      $('.modal-claim-insurance').fadeIn(300);
    });
  }

  backToFormRequestClaim(): void {
    $('.group-detail-request-claim').fadeOut(300, () => {
      $('.group-form-request-claim').fadeIn(300);
    });
  }


  formDetail(): void {
    this.pageSelector = 'formDetail';
    this.fetchInsuranceList();

    $('.modal-detail-insurance, .modal-history, .modal-claim-insurance').fadeOut(300, () => {
      $('.modal-body, .modal-header').fadeIn(300);
    });
  }

  fetchInsuranceList(): void {
    const option: object = {
      params: new HttpParams()
              .set('phone', this.userDetail.phone)
              .set('device_id', this.userDetail.token)
    };

    this.http.get<ApiListInsurance>(`${this.env.api}plus_insurance_list.php`, option).subscribe(
      data => {
        if (data.result_code == 0) {
          let userName: string;
          data.list.map((e) => {
            userName = e.firstname + ' ' + e.lastname;
            e.insurance_end = new Date(e.insurance_end);
            e.insurance_start = new Date(e.insurance_start);
            e.insurance_createdate = new Date(e.insurance_createdate);
          });

          this.insuranceList = data.list;
          this.userDetail.name = userName;
        } else {
          alertPopUp({title: 'เกิดข้อผิดะพลาด', detail: data.result_msg, button: 'ปิด'});
        }
      },
      error => {
        if (error.status === 401) {
          this.expireInsurance();
        }
      }

    );
  }

  expireInsurance(): void {
    localStorage.removeItem('session_token');
    this.ngOtpInputRef.setValue('');
    alertPopUp({title: 'เกิดข้อผิดพลาด', detail: 'หมดเวลาการใช้งานกรุณายืนยันตัวตนใหม่อีกครั้ง', button: 'ปิด'});
    setTimeout(() => {
      $('.modal').modal('hide');
      setTimeout(() => {
        this.formRequestOTP();
      }, 700);
    }, 700);
  }


  closeDetail(): void {
    if (this.requestClaim.touched || this.requestClaim.get('imagePhoto').valid) {
      Confirm.Show(
        'คำเตือน',
        'ข้อมูลที่ได้ทำการกรอกไว้จะหายไปเมื่อกลับไปยังหน้าหลัก',
        'ยอมรับ',
        'ยกเลิก',
        () => {
          // Yes button callbackalert('Thank you.');
          this.requestClaim.reset();
          this.tempImageRequestClaim = [];
          this.formDetail();
        },
        () => {
          // No button callbackalert('If you say so...');
        });
    } else {
      this.formDetail();
    }

  }

  handleSaveRequestClaim(): void {
    this.validateAllFormFields(this.requestClaim);
    if (!this.requestClaim.get('acceptConfirm').value) {
      $('.form-claim').animate({ scrollTop: 1000 }, 1000);
    }

    if (this.requestClaim.valid) {
      let date = this.requestClaim.get('accidentDate').value;
      let numberPrice: any;
      date = date.split('-');
      date = `${date[2]}-${date[1]}-${date[0]}`;
      date = new Date(date);
      date = this.datePipe.transform(date, 'yyyy-MM-dd');
      numberPrice = this.requestClaim.get('accidentPrice').value;
      numberPrice = numberPrice.toString().replace(/,+/g, '');
      numberPrice = Number(numberPrice);
      this.tempRequestClaim = {
        acceptConfirm: this.requestClaim.get('acceptConfirm').value,
        accidentDate: date,
        accidentDetail: this.requestClaim.get('accidentDetail').value,
        accidentPrice: numberPrice,
        email: this.requestClaim.get('email').value,
        imagePhoto: this.requestClaim.get('imagePhoto').value,
        insuranceID: this.requestClaim.get('insuranceID').value,
        phone: this.requestClaim.get('phone').value
      };

      $('.group-form-request-claim').fadeOut(300, () => {
        $('.group-detail-request-claim').fadeIn(300);
      });
    }
  }

  handleSubmitRequestClaim(): void {

    this.validateAllFormFields(this.requestClaim);

    if (this.requestClaim.valid) {
      Block.Pulse('#insuranceListModal .modal-content');
      let numberPrice: any;
      numberPrice = this.requestClaim.get('accidentPrice').value;
      numberPrice = numberPrice.toString().replace(/,+/g, '');
      numberPrice = Number(numberPrice);
      const formData: any = new FormData();
      formData.append('phone', this.userDetail.phone);
      formData.append('device_id', this.userDetail.token);
      formData.append('device_type', 1);
      formData.append('insurance_id', this.requestClaim.get('insuranceID').value);
      formData.append('claim_accident_date', this.datePipe.transform(this.tempRequestClaim.accidentDate, 'yyyy-MM-dd'));
      formData.append('claim_comment', this.requestClaim.get('accidentDetail').value);
      formData.append('claim_damage_price', numberPrice);
      formData.append('claim_photo', this.requestClaim.get('imagePhoto').value.join('||'));
      formData.append('claim_tel', this.requestClaim.get('phone').value);
      formData.append('claim_email', this.requestClaim.get('email').value);

      this.http.post<API>(`${this.env.api}plus_insurance_claim_save.php`, formData).subscribe(
        data => {
          if (data.result_code === 0) {
            this.formDetail();
            this.insertRequestClaimSuccess();
          } else {
            alertPopUp({title: 'เกิดข้อผิดพลาด', detail: data.result_msg, button: 'ปิด'});
          }
        },
        error => {
          if (error.status === 401) {
            this.expireInsurance();
          } else {
            const message: MessageAlert = {};
            message.title = 'เกิดข้อผิดพลาด';
            message.detail = 'ไม่สามารถแจ้งเคลมได้ กรุณาลองใหม่ภายหลัง';
            message.button = 'ปิด';
            Report.Warning(
              message.title,
              message.detail,
              message.button
            );
          }
          }
      );
    }


  }

  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  // upload image to api
  onFileUpload(event): void {
    const size = 20971520;
    const file: UploadFile = event.target.files;
    let statusAccept = true;
    const message: MessageAlert = {};
    for (const [k, e] of Object.entries(file)) {
      e.size > size
      ? (
        statusAccept = false,
        message.title = 'เกิดข้อผิดพลาด',
        message.detail = 'ไม่สามารถอัพโหลดรูปภาพที่ขนาดเกิดกว่า 20 mb',
        message.button = 'ปิด'
      )
      : (statusAccept = true);

      (e.type == 'image/png' || e.type == 'image/x-png' || e.type == 'image/gif' || e.type == 'image/jpeg')
      ? ( statusAccept = true )
      : (
        statusAccept = false,
        message.title = 'เกิดข้อผิดพลาด',
        message.detail = 'จะต้องอัพโหลดไฟล์รูปเท่านั้น',
        message.button = 'ปิด'
      );
      //
    }

    if (statusAccept) {
      this.uploadImageAPI(file);
    } else {
      Report.Warning(
        message.title,
        message.detail,
        message.button
      );
    }
  }

  uploadImageAPI(fileUpload: UploadFile): void {
    for (const [key, file] of Object.entries(fileUpload)) {
      const formData: any = new FormData();
      let photoList: Array<string>;
      let tempPhotoList: Array<object>;
      formData.append('photo', file);
      formData.append('phone', this.userDetail.phone);
      formData.append('device_id', this.userDetail.token);
      formData.append('device_type', 1);

      this.http.post<ApiUploadImage>(`${this.env.api}plus_insurance_claim_add_photo.php`, formData).subscribe(
        data => {
          if (data.result_code === 0) {
            this.requestClaim.get('imagePhoto').value == null ? this.requestClaim.get('imagePhoto').value = [] : '';
            photoList =  this.requestClaim.get('imagePhoto').value;
            tempPhotoList = this.tempImageRequestClaim;
            const url = `${this.env.api}upload/${data.folder}/${data.photo}`;
            tempPhotoList.push({urlImage: url, image: data.photo});
            photoList.push(data.photo);
            this.tempImageRequestClaim = tempPhotoList;
            this.requestClaim.patchValue({
              imagePhoto: photoList
            });
          } else {
            alertPopUp({title: 'เกิดข้อผิดพลาด', detail: data.result_msg, button: 'ปิด'});
          }
        },
        error => {
          if (error.status === 401) {
            this.expireInsurance();
          }
        }
      );
    }

  }
  // end upload image to api

  removeImage(imageObject: TempImage): void {
    const image = this.requestClaim.get('imagePhoto').value.filter(e => e !== imageObject.image);
    this.tempImageRequestClaim = this.tempImageRequestClaim.filter(e => e.urlImage !== imageObject.urlImage);
    this.requestClaim.patchValue({
      imagePhoto: image
    });
  }

  insertRequestClaimSuccess(): void {
    $('.card-alert-success').show();
    this.requestClaim.reset();
  }

  closeAlertSuccess(): void {
    Block.Remove('#insuranceListModal .modal-content');
    this.tempImageRequestClaim = [];
    $('.card-alert-success').hide();

  }

  closeAlert(): void {
    $('.card-alert').hide();
    $('#insuranceListModal .modal-content').removeClass('overlay');

  }

  closeViewImage(): void {
    $('.modal-body, .modal-header, .modal-view-image').fadeOut(300, () => {
      $('.modal-history').fadeIn(300);
    });
  }


  validationDateFormat(e: any): void {
    if (e?._d > new Date()) {
      this.requestClaim.patchValue({
        accidentDate: this.datePipe.transform(new Date(), 'dd-MM-yyyy')
      });
    }
  }

  removeCommas(): void {
    let NumberPrice: string;
    NumberPrice = this.requestClaim.get('accidentPrice').value.toString().replace(/,+/g, '');
    this.requestClaim.patchValue({
      accidentPrice: NumberPrice,
    });
  }

  addCommas(): void {
    let NumberPrice: any;
    NumberPrice = this.requestClaim.get('accidentPrice').value;
    NumberPrice = NumberPrice.toString().replace(/^0+(?!$)/, '');
    NumberPrice = NumberPrice.toString().replace(/[a-zA-Z]+/, '');
    NumberPrice = NumberPrice.toString().replace(/,+/g, '');
    NumberPrice = Number(NumberPrice).toLocaleString('en-GB');
    NumberPrice == 0 ? NumberPrice = '' : '';
    // while (/(\d+)(\d{3})/.test(NumberPrice.toString())){
    //   NumberPrice = NumberPrice.toString().replace(/(\d)(?=(\d{3})+$)/, '$1,');
    // }
    this.requestClaim.patchValue({
      accidentPrice: NumberPrice,
    });
  }

  // forbiddenNames(control: FormControl): { [s: string]: boolean } {
  //   if (this.forbiddenUsernames.indexOf(control.value) !== -1) {
  //       return {'forbiddenNames': true};
  //   }
  //   return null;
  // }
}
