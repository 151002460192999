<div class="card-history">
  <div class="row">
    <div class="form-group col-6">
      <label for="">วันที่เกิดเหตุ</label>
      <p>{{ historyList.claim_accident_date | thaidate }}</p>
    </div>
    <div class="form-group col-6">
      <label for="">วันที่แจ้งเคลม</label>
      <p>{{ historyList.claim_create_date | thaidate }}</p>
    </div>
    <div class="form-group col-12">
      <label for="">ลักษณะเหตุการณ์</label>
      <p>{{ historyList.claim_comment }}</p>
    </div>
    <div class="form-group col-12">
      <label for="">ค่าความเสียหาย</label>
      <p>{{ historyList.claim_damage_price | number}} บาท</p>
    </div>
    <div class="form-group col-12">
      <label for="">ภาพถ่ายทรัพย์สินที่เสียหาย</label>
      <div class="block-image-item">
        <div class="image-item" (click)="openViewImage()" *ngFor="let item of historyList.claim_photo | keyvalue" [ngClass]="{'d-none': item.key > 3}">
          <img src="{{ item.value }}" alt="">
          <div class="overlay" *ngIf="historyList.claim_photo.length > 4 && item.key == 3" >+{{ historyList.claim_photo.length - 4 }}</div>
        </div>
      </div>
    </div>

  </div>
</div>
