import { Component, Input, OnInit } from '@angular/core';
declare var $:any;

const STARS = [

];

@Component({
  selector: 'app-card-customer-review',
  templateUrl: './card-customer-review.component.html',
  styleUrls: ['./card-customer-review.component.scss']
})
export class CardCustomerReviewComponent implements OnInit {


  @Input() userProfile: string;
  @Input() userName: string;
  @Input() userAddress: string;
  @Input() userImage: string;
  @Input() userDetail: string;
  @Input() userScore: number;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    $(document).ready(() => {

      $('.score-rating').each(function() {
        $(this).starRating({
          starSize: 26,
          totalStars: 5,
          strokeWidth: 0,
          readOnly: true,
          initialRating: $(this).attr('data-score'), //score
          activeColor: '#f5a623',
          emptyColor: '#D1DEF5',
          useGradient: false,

        });
      })


    })
  }


}
