import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  insuranceTypeModal = 1;
  insuranceType = '3';
  paymentStatus = 2;
  paymentAgain = 1;
  office_status = '0';
  env = environment;
  code = '';
  errorPayment = '';
  errorShort = '';

  constructor(
    private http: HttpClient,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.code = (typeof params.code !== 'undefined') ? params.code : '';
    });
    this.checkPaymentStatus();
  }

  checkPaymentStatus(): void {
    const option: Object = {
      params: new HttpParams()
              .set('insurance_code', this.code)
    }
    this.http.get(this.env.in_api + 'plus_check_success.php', option).subscribe(data => {
      if (data['result_code'] === 0) {
        this.paymentStatus = 1;
        this.paymentAgain = data['payment_again'];
        this.insuranceType = data['insurance_type'];
        this.office_status = data['benefit_post_office_status'];
        localStorage.removeItem('hisCode');
      }else{
        this.paymentStatus = 0;
        this.paymentAgain = data['payment_again'];
        this.insuranceType = data['insurance_type'];
        this.errorPayment = data['result_payment_code_msg'];
        this.errorShort = data['result_payment_code_msg_again'];
      }
      document.querySelector('.loading-ng-insurance').remove();
    })
  }

  formPaymentApiAgain(type: string): void {
    // const contact = JSON.parse(localStorage.getItem('hisCode'));
    const contact = (this.code != '') ? this.code : JSON.parse(localStorage.getItem('hisCode'));
    if (null != contact || contact != ''){
      const code = (this.code != '') ? this.code : contact.code;
      const option: Object = {
        params: new HttpParams()
          .set('insurance_code', code)
          .set('type_again', '1')
      }
      this.http.get(this.env.in_api + 'plus_form_payment.php', option).subscribe(data => {
        // อ่านค่า result จาก JSON response ที่ส่งออกมา
        if (data['result_code'] === 0) {
          if (Object.keys(data['detail']).length > 0){
            this.getFormPaymentAgain(data['detail']['form']);
          }
        }
      });
    }
  }

  getFormPaymentAgain(resp: any): void {
    const form: any = document.createElement('Form');
    let element: any;
    form.method = resp.attr.method;
    form.action = resp.attr.action;
    form.name = resp.attr.name;
    delete(resp.attr);
    document.body.appendChild(form);
    resp.input.forEach((o: { name: any; type: any; value: any; }, i: any) => {
      element = document.createElement('input');
      element.name = o.name;
      element.type = o.type;
      element.value = o.value;
      form.appendChild(element);
    });
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

}
