<section>
  <div class="container">
    <div class="invoice">
      <img class="logo" src="assets/images/banner-living-msig-logo.png" alt="">
      <p class="header-text">เอกสารยืนยันความสนใจซื้อประกันภัย ให้เช่าหายห่วงเพียง {{invoice?.insurance_type_price | number}} บาท </p>
      <div class="block-table">
        <div class="header-title">เรียนคุณ</div>
        <div class="detail-title">
          {{invoice?.firstname}} {{invoice?.lastname}}  ขอบคุณที่สนใจประกันภัยให้เช่าหายห่วง โปรดศึกษารายละเอียดการชำระเงิน
          ได้จากข้อมูลข้างล่างนี้
        </div>
          <div class="header-title">อ้างอิงเลขที่</div>
        <div class="detail-title d-flex justify-content-between">
          <p>{{invoice?.insurance_id}}</p>
          <p style="white-space: nowrap;"><span class="header-title">วันที่</span> {{invoice?.insurance_createdate | thaidate}}</p>
        </div>
        <!-- detail -->
        <div id="user-type" class="header-topic">
          รายละเอียดประเภททรัพย์สิน
        </div>
        <div class="header-title">ประเภทของที่อยู่ </div>
        <div class="detail-title">
          {{invoice?.building_type}}
          <span>**บริษัทไม่รับประกันภัยสิ่งปลูกสร้างที่เป็นไม้ล้วน</span>
        </div>
        <div class="header-title">ผู้เอาประกันภัยเป็น</div>
        <div class="detail-title">
          {{invoice?.insure_by}}
        </div>
        <!-- detail by -->
        <div id="user-detail" class="header-topic">
          รายละเอียดผู้ชื้อ
        </div>
        <div class="header-title">ชื่อ - นามสกุล</div>
        <div class="detail-title">
          {{invoice?.firstname}} {{invoice?.lastname}}
        </div>
        <div class="header-title">เลขที่บัตรประชาชน</div>
        <div class="detail-title">
          {{invoice?.id_card}}
        </div>
        <div class="header-title">เบอร์ติดต่อ</div>
        <div class="detail-title">
          {{invoice?.phone}}
        </div>
        <div class="header-title">อีเมล</div>
        <div class="detail-title">
          {{invoice?.email}}
        </div>
        <div class="header-title">ที่อยู่ที่จัดส่งเอกสาร</div>
        <div class="detail-title">
         {{invoice?.home_no}} {{invoice?.address}} {{invoice?.province}} {{invoice?.zipcode}}
        </div>
        <!-- detail benefit -->
        <div id="user-benefit"  class="header-topic">
          รายละเอียดผู้รับผลประโยชน์
        </div>
        <div class="header-title">ชื่อ - นามสกุล</div>
        <div class="detail-title">
          {{invoice?.benefit_firstname}} {{invoice?.benefit_lastname}}
        </div>
        <div class="header-title">เลขที่บัตรประชาชน</div>
        <div class="detail-title">
          {{invoice?.benefit_id_card}}
        </div>
        <div class="header-title">ที่อยู่ที่จัดส่งเอกสาร</div>
        <div class="detail-title">
         {{invoice?.benefit_home_no}} {{invoice?.benefit_address}} {{invoice?.benefit_province}} {{invoice?.benefit_zipcode}}
        </div>
         <!-- detail credit card -->
         <div id="payment-list" class="header-topic">
          รายละเอียดการชำระเงิน
        </div>
        <div class="block-payment">
          <div class="row no-gutters">
            <div class="col-3">
              <p class="header">ธนาคาร</p>
            </div>
            <div class="col-3">
              <p class="header">สาขา</p>
            </div>
            <div class="col-3">
              <p class="header">ประเภทบัญชี</p>
            </div>
            <div class="col-3">
              <p class="header">เลขบัญชี</p>
            </div>
          </div>
          <!-- item payment -->
          <div class="row no-gutters detail" *ngFor="let payment of payments" >
            <div class="col-3"><img src="{{payment.img}}" alt=""> {{payment.name}}</div>
            <div class="col-3">{{payment.branch}}</div>
            <div class="col-3">{{payment.type}}</div>
            <div class="col-3">{{payment.cardNo}}</div>
          </div>
        </div>

        <!-- how to buy -->
        <div class="header-topic" style="font-size:24px">
          ขั้นตอนการชำระเงิน
        </div>
        <div class="payment-process">
          <span>1. โอนเงินจำนวน 1,999 บาท เข้าบัญชีที่แจ้งไว้ด้านบน</span>
          <span>2. เมื่อทำรายการเสร็จแล้ว กรุณานำหลักฐานการโอนเงินแจ้งต่อเจ้าหน้าที่ตามข้อมูลติดต่อด้านล่าง</span>
        </div>
      </div>

        <!-- footer -->
        <div class="footer">
          <p class="title-header">หากมีข้อสงสัย หรือเกิดปัญหาการชำระเงินติดต่อ โทร 088-554-0725</p>
          <p>แอดไลน์สอบถาม  <a href="https://line.me/ti/p/@livinginsure" class="link">คลิกที่นี่</a></p>
          <p class="title-qrcode">สแกน QR เพื่อแอดไลน์</p>
          <img src="assets/images/qr-code-line1.png" alt="">
        </div>
    </div>
  </div>
</section>
