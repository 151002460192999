<a target="_blank" href="{{urlLink}}">
  <div class="card-article">
    <div class="block-img">
      <img src="{{image}}" alt="" >
    </div>
    <div class="block-detail">
      {{title}}
    </div>
  </div>
</a>
