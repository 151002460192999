import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from "../../../environments/environment";
// import { Notify, Report, Confirm, Loading, Block } from "notiflix";
import { Report, Block, Confirm } from 'notiflix';
import { countDown, alertPopUp } from '../../functions/functionMain';
import { ActivatedRoute } from '@angular/router';
import {
  ApiRequestOTP,
  ApiConfirmOTP,
  ApiListInsurance,
  InsuranceList,
  ApiUploadImage,
  TEMPCLAIMINFO,
  UserDetail,
  TempRequestClaim,
  ApiClaimList,
  ClaimList
} from '../../interfaces/request-claim';

export interface BooleanFn {
  (): boolean;
}

declare var $: any;

interface tempAddress {
  province?: string;
  province2?: string;
  district?: string;
  district2?: string;
  subdistrict?: string;
  subdistrict2?: string;
  postalCode?: string;
  postalCode2?: string;
}
interface tempAddressBuilding {

}
@Component({
  selector: 'app-buy-insurance',
  templateUrl: './buy-insurance.component.html',
  styleUrls: ['./buy-insurance.component.scss']
})
export class BuyInsuranceComponent implements OnInit {
  env = environment;
  @Input()
  insuranceType: string;

  timingRequestOPT = 60;
  responseRequestOTP: ApiRequestOTP;
  errorOtpBuy: string;
  dataUser: UserDetail;

  finalDate: any;
  today = new Date();
  currentYear = this.today.getUTCFullYear();
  // currentYear1 = new Date(this.today.setUTCFullYear(this.today.getUTCFullYear() - 20)).getUTCFullYear();
  // 100 Years from current year
  maxYear = new Date(this.today.setUTCFullYear(this.today.getUTCFullYear() - 100)).getUTCFullYear();
  isDateValid = false;
  dateValidationText = '';

  days: any = [{
    Id: '',
    Name: 'วัน'
  }];

  months: any = [{
    Id: '',
    FullName: '',
    Name: 'เดือน'
  }, {
    Id: 1,
    FullName: 'มกราคม',
    Name: 'ม.ค.'
  }, {
    Id: 2,
    FullName: 'กุมภาพันธ์',
    Name: 'ก.พ.'
  }, {
    Id: 3,
    FullName: 'มีนาคม',
    Name: 'มี.ค.'
  }, {
    Id: 4,
    FullName: 'เมษายน',
    Name: 'เม.ย.'
  }, {
    Id: 5,
    FullName: 'พฤษภาคม',
    Name: 'พ.ค.'
  }, {
    Id: 6,
    FullName: 'มิถุนายน',
    Name: 'มิ.ย.'
  }, {
    Id: 7,
    FullName: 'กรกฎาคม',
    Name: 'ก.ค.'
  }, {
    Id: 8,
    FullName: 'สิงหาคม',
    Name: 'ส.ค.'
  }, {
    Id: 9,
    FullName: 'กันยายน',
    Name: 'ก.ย.'
  }, {
    Id: 10,
    FullName: 'ตุลาคม',
    Name: 'ต.ค.'
  }, {
    Id: 11,
    FullName: 'พฤศจิกายน',
    Name: 'พ.ย.'
  }, {
    Id: 12,
    FullName: 'ธันวาคม',
    Name: 'ธ.ค.'
  }];

  nameMonthsP1: string = '';
  nameMonthsP2: string = '';

  years: any = [{
    Id: '',
    Name: 'ปี'
  }];

  isLeapYear = false;

  requestOTP: any = new FormGroup({
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('(^0)([0-9]){9}$'),
    ]),
  });

  confirmOTP: any = new FormGroup({
    otpToken: new FormControl('', [
      Validators.required,
    ]),
    otpActivate: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
    ]),
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('(^0)([0-9]){9}$'),
    ]),
  });

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '40px',
      height: '40px',
      'font-size': '16px',
      'font-family': 'Prompt',
      'border-radius': '8px',
      border: 'solid 1px #d1def5'
    }
  };

  userDetail: any = new FormGroup({
    postFrom: new FormControl('1', [
      Validators.required,
    ]),
    // insureOwnerFirstName: new FormControl('', [
    //   conditionalValidator(() => this.userDetail.get('postFrom').value === '2',
    //   Validators.required,
    //     'illuminatiError'),
    // ]),
    // insureOwnerLastName: new FormControl('', [
    //   conditionalValidator(() => this.userDetail.get('postFrom').value === '2',
    //   Validators.required,
    //     'illuminatiError'),
    // ]),
    firstName: new FormControl('', [
      Validators.required,
    ]),
    lastName: new FormControl('', [
      Validators.required,
    ]),
    genderType: new FormControl('1', [
      Validators.required,
    ]),
    nationalityType: new FormControl('1', [
      Validators.required,
    ]),
    day: new FormControl('', [
      Validators.required,
    ]),
    month: new FormControl('', [
      Validators.required,
    ]),
    year: new FormControl('', [
      Validators.required,
    ]),
    idCard: new FormControl('', [
      Validators.minLength(13),
      Validators.maxLength(13),
      conditionalValidator(() => this.userDetail.get('nationalityType').value === '1',
                              Validators.required,
                              'illuminatiError'),
    ]),
    nationalityName: new FormControl('', [
      conditionalValidator(() => this.userDetail.get('nationalityType').value === '2',
                              Validators.required,
                              'illuminatiError'),
    ]),
    idCardPassport: new FormControl('', [
      Validators.minLength(5),
      Validators.maxLength(50),
      conditionalValidator(() => this.userDetail.get('nationalityType').value === '2',
      Validators.required,
      'illuminatiError'),
    ]),
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern('(^0)([0-9]){9}$'),
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
    ]),
  });

  building: any = new FormGroup({
    buildingType: new FormControl('1', [
      Validators.required,
    ]),
    beneficiary: new FormControl('', []),
    province: new FormControl('', [
      Validators.required,
    ]),
    subdistrict: new FormControl('', [
      Validators.required,
    ]),
    district: new FormControl('', [
      Validators.required,
    ]),
    postalCode: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(5),
    ]),
    // houseNumber: new FormControl('', [
    //   Validators.required,
    // ]),
    addressDetail: new FormControl('', [
      Validators.required,
    ]),
    beneficiaryForm: new FormGroup({
      // beneficiary: new FormControl('', []),
      firstName: new FormControl('', [
        conditionalValidator(() => this.userDetail.get('postFrom').value === '2',
          Validators.required,
        ''),
      ]),
      lastName: new FormControl('', [
        conditionalValidator(() => this.userDetail.get('postFrom').value === '2',
          Validators.required,
        ''),
      ]),
      // genderTypeBf: new FormControl('1', [
      //   conditionalValidator(() => this.userDetail.get('postFrom').value === '2',
      //     Validators.required,
      //   ''),
      // ]),
      // nationalityTypeBf: new FormControl('1', [
      //   conditionalValidator(() => this.userDetail.get('postFrom').value === '2',
      //     Validators.required,
      //   ''),
      // ]),
      // day: new FormControl('', [
      //   conditionalValidator(() => this.userDetail.get('postFrom').value === '2',
      //     Validators.required,
      //   ''),
      // ]),
      // month: new FormControl('', [
      //   conditionalValidator(() => this.userDetail.get('postFrom').value === '2',
      //     Validators.required,
      //   ''),
      // ]),
      // year: new FormControl('', [
      //   conditionalValidator(() => this.userDetail.get('postFrom').value === '2',
      //     Validators.required,
      //   ''),
      // ]),
      idCard: new FormControl('', [
        Validators.minLength(13),
        Validators.maxLength(13),
        // conditionalValidator(() => this.building.get('beneficiaryForm').get('nationalityTypeBf').value === '1' && this.userDetail.get('postFrom').value === '2',
        // Validators.required,
        // 'illuminatiError'),
        conditionalValidator(() => this.userDetail.get('postFrom').value === '2',
          Validators.required,
        ''),
      ]),
      // nationalityName: new FormControl('', [
      //   conditionalValidator(() => this.building.get('beneficiaryForm').get('nationalityTypeBf').value === '2' && this.userDetail.get('postFrom').value === '2',
      //   Validators.required,
      //   ''),
      // ]),
      // idCardPassport: new FormControl('', [
      //   Validators.minLength(5),
      //   Validators.maxLength(50),
      //   conditionalValidator(() => this.building.get('beneficiaryForm').get('nationalityTypeBf').value === '2' && this.userDetail.get('postFrom').value === '2',
      //   Validators.required,
      //   'illuminatiError'),
      // ]),
    }),
  });

  address: any = new FormGroup({
    // addressSame : new FormControl('', []),
    province: new FormControl('', [
      Validators.required,
    ]),
    subdistrict: new FormControl('', [
      Validators.required,
    ]),
    district: new FormControl('', [
      Validators.required,
    ]),
    postalCode: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(5),
    ]),
    // houseNumber: new FormControl('', [
    //   Validators.required,
    // ]),
    addressDetail: new FormControl('', [
      Validators.required,
    ]),
  });

  province: any = [];
  nationality: any = [];
  nationalityKeyName: any = [];
  isConfirm: any = false;

  district: any = [
    {value: '0', label: 'โปรดเลือกจังหวัด', disabled: true}
  ];

  subdistrict: any = [
    {value: '0', label: 'โปรดเลือกอำเภอ/เขต', disabled: true}
  ];



  province2: any = [
    {value: '1', label: 'test'},
    {value: '2', label: 'tes2t2'},
    {value: '3', label: 'tes23t2'},
  ];

  district2: any = [
    {value: '0', label: 'โปรดเลือกจังหวัด', disabled: true}
  ];

  subdistrict2: any = [
    {value: '0', label: 'โปรดเลือกอำเภอ/เขต', disabled: true}
  ];

  postalCode: any = '';
  postalCode2: any = '';

  tempAddress: tempAddress = {
    province: '',
    province2: '',
    district: '',
    district2: '',
    subdistrict: '',
    subdistrict2: '',
    postalCode: '',
    postalCode2: '',
  };

  tempAddressBuilding = {
    addressSame: '',
    province: '',
    subdistrict: '',
    district: '',
    postalCode: '',
    // houseNumber: '',
    addressDetail: '',
  };

  boolChangeAddress = false;
  pageAddress = false;
  priceInsurance: string = '';
  closeConfirm = false;
  insuranceCode: string = '';
  insuranceId: string = '';
  textErrorOtp: number = 0;
  textErrorOtpMsg: string = '';
  handleBuildingType: string = '1';
  handlePostFrom: string = '1';
  handlePhone: number;
  product_token: string;
  constructor(
    private http: HttpClient,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.product_token = (typeof params.product_token !== 'undefined') ? params.product_token : '';
      if(this.product_token != ''){
        const host = window.location.host;
        if (host === 'localhost:4200'){
          $.cookie('product_token' , this.product_token , { domain: 'localhost' ,  path : '/' });
        }else{
          $.cookie('product_token' , this.product_token , { domain: '.livinginsider.com' ,  path : '/' });
        }
      }
    });
    console.log(this.product_token);
    this.getQueryProvince();
    this.getQueryNationality();
    this.genDay(31);
    this.genYear();

    // change option request value
    this.userDetail.get('nationalityType').valueChanges.subscribe((value: any) => {
      this.userDetail.get('idCard').updateValueAndValidity();
      this.userDetail.get('nationalityName').updateValueAndValidity();
      this.userDetail.get('idCardPassport').updateValueAndValidity();
    });

    // // change option request value
    // this.building.get('postFrom').valueChanges.subscribe((value: any) => {
    //   // this.building.get('insureOwnerName').updateValueAndValidity();
    //   this.building.get('insureOwnerFirstName').updateValueAndValidity();
    //   this.building.get('insureOwnerLastName').updateValueAndValidity();
    // });

    // change option request value
    // this.userDetail.get('postFrom').valueChanges.subscribe((value: any) => {
    //   // this.userDetail.get('insureOwnerName').updateValueAndValidity();
    //   this.userDetail.get('insureOwnerFirstName').updateValueAndValidity();
    //   this.userDetail.get('insureOwnerLastName').updateValueAndValidity();
    // });

    // change option request value
    this.userDetail.get('postFrom').valueChanges.subscribe((value: any) => {
      // this.userDetail.get('insureOwnerName').updateValueAndValidity();
      this.building.get('beneficiaryForm').get('firstName').updateValueAndValidity();
      this.building.get('beneficiaryForm').get('lastName').updateValueAndValidity();
      // this.building.get('beneficiaryForm').get('nationalityTypeBf').updateValueAndValidity();
      this.building.get('beneficiaryForm').get('idCard').updateValueAndValidity();
      // this.building.get('beneficiaryForm').get('nationalityName').updateValueAndValidity();
      // this.building.get('beneficiaryForm').get('idCardPassport').updateValueAndValidity();
      // this.building.get('beneficiaryForm').get('genderTypeBf').updateValueAndValidity();
      // this.building.get('beneficiaryForm').get('day').updateValueAndValidity();
      // this.building.get('beneficiaryForm').get('month').updateValueAndValidity();
      // this.building.get('beneficiaryForm').get('year').updateValueAndValidity();
    });
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit(): void {
    $(document).ready(() => {
      const self = this;
      $('#buyInsuranceModal').on('shown.bs.modal', () => {
        // self.ExpireInsuranceBuy();
        // const contact = JSON.parse(localStorage.getItem('session_token_buy'));
        const contact = JSON.parse(localStorage.getItem('hisCode'));
        if (null != contact){
          const code = contact.code;
          if(contact.insurance_type == self.insuranceType){
            self.getDataPhoneHistory(code);
          }else{
            localStorage.removeItem('hisCode');
          }
          // new code close
        }
      });

      $('#otpModalBuy').on('shown.bs.modal', () => {
        setTimeout(() => {
          this.requestOTP.patchValue({
            phone : this.handlePhone,
          });
        }, 250);
      });
      // this.FormConfirm()
      // $('#buyInsuranceModal').modal()
      // $("[select2]").each(function() {
      //   $(this).select2({
      //      placeholder: $(this).attr('placeholder')
      //  });
      // });

    });
  }

  genDay(day: number): void {
    for (let x = 1; x <= day; x++) {
      this.days.push({ Id: x, Name: x });
    }
  }

  genYear(): void {
    for (let x = this.currentYear; x >= this.maxYear; x--) {
      this.years.push({ Id: x, Name: x });
    }
  }

  checkLeapYear(typePage = 1): void {
    // LEAP YEAR CALCULATOR'
    let year: number;
    if (typePage == 1){
      year = this.userDetail.get('year').value;
    }else{
      year = this.building.get('beneficiaryForm').get('year').value;
    }

    if (typeof(year) != 'undefined') {
      if (year % 4 != 0) {
        this.isLeapYear = false;
      } else if (year % 100 != 0) {
        this.isLeapYear = true;
      } else if (year % 400 != 0) {
        this.isLeapYear = false;
      } else {
        this.isLeapYear = true;
      }
    }
  };

  checkDateIsValid(typePage = 1): void {
    let day: any;
    let month: any;
    if(typePage == 1){
      day = this.userDetail.get('day').value;
      month = (this.userDetail.get('month').value != '') ? this.userDetail.get('month').value : 0;
      this.nameMonthsP1 = this.months[month]['FullName'];
    }else{
      day = this.building.get('beneficiaryForm').get('day').value;
      month = (this.building.get('beneficiaryForm').get('month').value != '') ? this.building.get('beneficiaryForm').get('month').value : 0;
      this.nameMonthsP2 = this.months[month]['FullName'];
    }

    this.checkLeapYear(typePage);
    this.days = [{
      Id: '',
      Name: 'วัน'
    }];
    if (this.isLeapYear && month == '2') {
      this.genDay(29);
      if (day > 29){
        if(typePage == 1){
          this.userDetail.patchValue({
            day: '',
          });
        }else{
          this.building.get('beneficiaryForm').patchValue({
            day: '',
          });
        }
      }
    } else if (!this.isLeapYear && month == '2') {
      this.genDay(28);
      if (day > 28){
        if(typePage == 1){
          this.userDetail.patchValue({
            day: '',
          });
        }else{
          this.building.get('beneficiaryForm').patchValue({
            day: '',
          });
        }
      }
    } else {
      if (month == '1' ||
          month == '3' ||
          month == '5' ||
          month == '7' ||
          month == '8' ||
          month == '10' ||
          month == '12'
        )
      {
        this.genDay(31);
        if(typePage == 1){
          this.userDetail.patchValue({
            day,
          });
        }else{
          this.building.get('beneficiaryForm').patchValue({
            day,
          });
        }
      } else {
        this.genDay(30);
        if(typePage == 1){
          this.userDetail.patchValue({
            day,
          });
        }else{
          this.building.get('beneficiaryForm').patchValue({
            day,
          });
        }
      }
    }
  }

  getQueryProvince(): void {
    // this.http.get(this.env.in_api + 'plus_address.php').subscribe(data => {
    this.http.get(this.env.in_api + 'plus_address.php').subscribe(data => {
      this.province = data['list'];
      this.province2 = data['list'];
    });
  }

  getQueryNationality(): void {
    // this.http.get(this.env.in_api + 'plus_nationality.php').subscribe(data => {
    this.http.get(this.env.in_api + 'plus_nationality.php').subscribe(data => {
      this.nationality = data['list'];
      this.nationalityKeyName = data['list_sort'];
    });
  }

  handleClose(): void {
    $('.form-payment').hide();
    if (this.userDetail.dirty) {
      $('#buyInsuranceModal .modal-content').addClass('overlay')
      $('.alert-block').show();
    } else {
      this.reSetFormValue();
      this.FormUser();
      $('.modal').modal('hide')
    }

  }

  reSetFormValue(): void {
    this.userDetail.reset({
      postFrom: '1',
      firstName: '',
      lastName: '',
      nationalityTypeBf: '1',
      idCard: '',
      nationalityName: '',
      idCardPassport: '',
      year: '',
      month: '',
      day: '',
      genderTypeBf: '1',
    });

    this.building.reset({
      buildingType: '1',
      province: '',
      subdistrict: '',
      district: '',
      postalCode: '',
      // houseNumber: '',
      addressDetail: '',
      beneficiaryForm: {
        firstName: '',
        lastName: '',
        // nationalityTypeBf: '1',
        idCard: '',
        // nationalityName: '',
        // idCardPassport: '',
        // year: '',
        // month: '',
        // day: '',
        // genderTypeBf: '1',
      }
    });

    this.address.reset();
    this.handleBuildingType = '1';
    this.handlePostFrom = '1';

    // $('.form-building [formcontrolname="houseNumber"]').attr('disabled', false);
    $('.form-building [formcontrolname="addressDetail"]').attr('disabled', false);

    $('.form-building [formControlName="province"]').removeClass('none-event');
    $('.form-building [formControlName="province"] .select2-selection--single').css({ 'background-color': 'unset', 'opacity': 1 });

    $('.form-building [formControlName="district"]').removeClass('none-event');
    $('.form-building [formControlName="district"] .select2-selection--single').css({ 'background-color': 'unset', 'opacity': 1 });

    $('.form-building [formControlName="subdistrict"]').removeClass('none-event');
    $('.form-building [formControlName="subdistrict"] .select2-selection--single').css({ 'background-color': 'unset', 'opacity': 1 });
    $('.form-building [formcontrolname="postalCode"]').attr('disabled', false);
    // $('.form-address select[select2]').attr('disabled', false)

    $('.form-user .wording-complpte').hide();
    $('.form-user .wording-wrong').hide();
    $('[formGroupName="beneficiaryForm"] .wording-complpte').hide();
    $('[formGroupName="beneficiaryForm"] .wording-wrong').hide();

    $('.form-building [formGroupName="beneficiaryForm"] input').attr('readonly', false);
    $('.form-building [formGroupName="beneficiaryForm"] input[type="radio"]').attr('disabled', false);
    $('.form-building [formGroupName="beneficiaryForm"] select').attr('disabled', false);
    $('.form-building [formGroupName="beneficiaryForm"] select2').attr('disabled', false);
    $('[name=nationalityName]').removeClass('none-event');
    $('[name=nationalityName] .select2-selection--single').css({ 'background-color': 'unset', 'opacity': 1 });
    // $('.form-address [formcontrolname="houseNumber"]').attr('disabled', false);
    $('.form-address [formcontrolname="addressDetail"]').attr('disabled', false);
    $('.form-address [formcontrolname="subdistrict"]').attr('disabled', false);
    $('.form-address [formcontrolname="district"]').attr('disabled', false);
    $('.form-address [formControlName="province"]').removeClass('none-event');
    $('.form-address [formControlName="province"] .select2-selection--single').css({ 'background-color': 'unset', 'opacity': 1 });
    $('.form-address [formcontrolname="postalCode"]').attr('disabled', false);
  }

  handleSubmitUser(): void {
    this.ExpireInsuranceBuy();
    const email = this.userDetail.get('email').value;
    const checkEmail = confirm(`เพื่อความมั่นใจว่ากรมธรรม์จะถูกส่งถึงท่านโดยสมบูรณ์ กรุณายืนยัน Email ของท่านอีกครั้ง : ${email}`);
    if (this.userDetail.valid && checkEmail) {
      // set Value form Building
      // this.building.patchValue({
      //   buildingType: '1',
      //   postFrom: '1',
      // });
      // this.building.get('beneficiaryForm').patchValue({
      //     beneficiary: 1,
      //     firstName: this.userDetail.get('firstName').value,
      //     lastName: this.userDetail.get('lastName').value,
      //     idCard: this.userDetail.get('idCard').value,
      // })

      // $('.form-building [formGroupName="beneficiaryForm"] input').attr('readonly', true);

      // this.FormBuilding();
      this.FormAddress();
    }
  }

  handleSubmitAddress(): void {
    this.ExpireInsuranceBuy();
    if (this.address.valid) {

      this.FormBuilding();
      // this.FormConfirm();
    }
  }

  handleSubmitBuilding(): void {
    this.ExpireInsuranceBuy();
    if (this.building.valid) {
      this.province.forEach((e: { value: any; label: string; }) => {
        if (e.value == this.building.get('province').value) {
          this.tempAddress.province = e.label;
        }
      });

      this.province2.forEach((e: { value: any; label: string; }) => {
        if (e.value == this.address.get('province').value) {
          this.tempAddress.province2 = e.label;
        }
      });

      this.district.forEach((e: { value: any; label: string; }) => {
        if (e.value == this.building.get('district').value) {
          this.tempAddress.district = e.label;
        }
      });

      this.district2.forEach((e: { value: any; label: string; }) => {
        if (e.value == this.address.get('district').value) {
          this.tempAddress.district2 = e.label;
        }
      });

      this.subdistrict.forEach((e: { value: any; label: string; }) => {
        if (e.value == this.building.get('subdistrict').value) {
          this.tempAddress.subdistrict = e.label;
        }
      });

      this.subdistrict2.forEach((e: { value: any; label: string; }) => {
        if (e.value == this.address.get('subdistrict').value) {
          this.tempAddress.subdistrict2 = e.label;
        }
      });

      if(this.userDetail.get('postFrom').value === '1'){
        this.building.get('beneficiaryForm').patchValue({
          firstName: this.userDetail.get('firstName').value,
          lastName: this.userDetail.get('lastName').value,
          // nationalityTypeBf: this.userDetail.get('nationalityType').value,
          idCard: this.userDetail.get('idCard').value,
          // nationalityName: (this.userDetail.get('nationalityName').value != null) ? this.userDetail.get('nationalityName').value : '',
          // idCardPassport: (this.userDetail.get('idCardPassport').value != null) ? this.userDetail.get('idCardPassport').value : '',
          // genderTypeBf:this.userDetail.get('genderType').value,
          // day: this.userDetail.get('day').value,
          // month: this.userDetail.get('month').value,
          // year: this.userDetail.get('year').value
        });
        this.nameMonthsP2 = this.months[this.userDetail.get('month').value]['FullName'];
      }

      // this.FormAddress();
      this.FormConfirm();
    }
  }

  handleSubmitConfirm(): void {
    this.ExpireInsuranceBuy();
    $('#errConfirm').hide();

    if ($('#confirmAccept').prop('checked') && this.userDetail.valid && this.building.valid && this.address.valid) {
      this.isConfirm = true;
      $('#errConfirm').hide();
      let nationality_name = (this.userDetail.get('nationalityName').value != null) ? this.userDetail.get('nationalityName').value : '';
      let id_card_passport = (this.userDetail.get('idCardPassport').value != null) ? this.userDetail.get('idCardPassport').value : '';
      let insureOwnerFirstName = (this.building.get('beneficiaryForm').get('firstName').value != null) ? this.building.get('beneficiaryForm').get('firstName').value : '';
      let insureOwnerLastName = (this.building.get('beneficiaryForm').get('lastName').value != null) ? this.building.get('beneficiaryForm').get('lastName').value : '';
      // let insureOwnerFirstName = (this.building.get('insureOwnerFirstName').value != null) ? this.building.get('insureOwnerFirstName').value : '';
      // let insureOwnerLastName = (this.building.get('insureOwnerLastName').value != null) ? this.building.get('insureOwnerLastName').value : '';
      // let benefit_nationality_name = (this.building.get('beneficiaryForm').get('nationalityName').value != null) ? this.building.get('beneficiaryForm').get('nationalityName').value : '';
      // let benefit_id_card_passport = (this.building.get('beneficiaryForm').get('idCardPassport').value != null) ? this.building.get('beneficiaryForm').get('idCardPassport').value : '';

      let nameProvince = this.building.get('province').value;
      let nameProvince2 = this.address.get('province').value;

      let nameDistrict = this.building.get('district').value;
      this.district.forEach((e: { value: any; label: string; }) => {
        if (e.value == this.building.get('district').value) {
          nameDistrict = e.label;
        }
      });

      let nameDistrict2 = this.address.get('district').value;
      this.district2.forEach((e: { value: any; label: string; }) => {
        if (e.value == this.address.get('district').value) {
          nameDistrict2 = e.label;
        }
      });

      let nameSubdistrict = this.building.get('subdistrict').value;
      this.subdistrict.forEach((e: { value: any; label: string; }) => {
        if (e.value == this.building.get('subdistrict').value) {
          nameSubdistrict = e.label;
        }
      });

      let nameSubdistrict2 = this.address.get('subdistrict').value;
      this.subdistrict2.forEach((e: { value: any; label: string; }) => {
        if (e.value == this.address.get('subdistrict').value) {
          nameSubdistrict2 = e.label;
        }
      });
      var option: Object = {
        params: new HttpParams()
                .set('insurance_type', this.insuranceType) //1=ให้เช่าหายห่วง, 2=อยู่เองหายห่วง 3=ให้เช่าหายห่วง 2,199 4=ให้เช่าหายห่วง 3,399  5=ให้เช่าหายห่วง 5,199
                .set('firstname', this.userDetail.get('firstName').value)
                .set('lastname', this.userDetail.get('lastName').value)
                .set('gender_type', this.userDetail.get('genderType').value)
                .set('birdday', this.userDetail.get('year').value + '-' + this.userDetail.get('month').value + '-' + this.userDetail.get('day').value)
                .set('nationality_type', this.userDetail.get('nationalityType').value)
                .set('id_card', this.userDetail.get('idCard').value)
                .set('nationality_name', nationality_name)
                .set('id_card_passport', id_card_passport)
                .set('phone', this.userDetail.get('phone').value)
                .set('email', this.userDetail.get('email').value)
                .set('building_type', this.building.get('buildingType').value)
                .set('insure_by', this.userDetail.get('postFrom').value)
                .set('insure_owner_name', insureOwnerFirstName + '-' + insureOwnerLastName)
                // .set('home_no', this.building.get('houseNumber').value)
                .set('home_no', '')
                .set('address', this.building.get('addressDetail').value)
                .set('province', nameProvince)
                .set('subdistrict', nameSubdistrict)
                .set('district', nameDistrict)
                .set('zipcode', this.building.get('postalCode').value)
                .set('benefit_firstname', this.building.get('beneficiaryForm').get('firstName').value)
                .set('benefit_lastname', this.building.get('beneficiaryForm').get('lastName').value)
                // .set('benefit_gender_type', this.building.get('beneficiaryForm').get('genderTypeBf').value)
                // .set('benefit_birdday', this.building.get('beneficiaryForm').get('year').value + '-' + this.building.get('beneficiaryForm').get('month').value + '-' + this.building.get('beneficiaryForm').get('day').value)
                // .set('benefit_nationality_type', this.building.get('beneficiaryForm').get('nationalityTypeBf').value)
                // .set('benefit_nationality_name', benefit_nationality_name)
                .set('benefit_id_card', this.building.get('beneficiaryForm').get('idCard').value)
                // .set('benefit_id_card_passport', benefit_id_card_passport)
                // .set('benefit_home_no', this.address.get('houseNumber').value)
                .set('benefit_home_no', '')
                .set('benefit_address', this.address.get('addressDetail').value)
                .set('benefit_province', nameProvince2)
                .set('benefit_subdistrict', nameSubdistrict2)
                .set('benefit_district', nameDistrict2)
                .set('benefit_zipcode', this.address.get('postalCode').value)
                .set('agree_1', ($('#beneficiary').prop('checked') == true) ? '1' : '0')
                .set('agree_2', ($('#addressSame').prop('checked') == true) ? '1' : '0')
                .set('insuranceCode', this.insuranceCode) // code อัพเดท
                .set('insuranceId', this.insuranceId) // id อัพเดท
                .set('product_token', $.cookie('product_token'))
                .set('confirmPostOffice', ($('#confirmPostOffice').prop('checked') == true) ? '1' : '0') // จัดส่งไปรษณีย์ true, false
      }
      this.http.get(this.env.api + 'plus_add.php', option).subscribe(data => {
      // this.http.get(this.env.in_api + 'plus_add.php', option).subscribe(data => {
        // อ่านค่า result จาก JSON response ที่ส่งออกมา
        if (data['result_code'] == 0) {
          //new code
          // this.insuranceCode = data['insurance_code'];
          // const hisCode: object = {
          //   code : this.insuranceCode,
          // };
          // localStorage.setItem('hisCode', JSON.stringify(hisCode));
          //new code

          this.FormSuccess();
          // this.FormPaymentOption();
          // localStorage.removeItem('session_token_buy');

          //new code
          // this.handlePhone = this.userDetail.get('phone').value;
          // this.ExpireInsuranceBuy();
          // const contact = JSON.parse(localStorage.getItem('session_token_buy'));
          // if (null != contact){
          //   this.FormPaymentOption();
          // }else{
          //   $('.modal').modal('hide');
          //   setTimeout( () => {
          //     formRequestOTP();
          //   }, 200);
          // }
          this.reSetFormValue();
          //new code
        } else {
          alert('เกิดข้อผิดพลาดกรุณาลองใหม่')
        }
        this.isConfirm = false;
      });
    } else {
      $('#errConfirm').show();
      let element = document.getElementById("confirmAccept");
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  handleSubmitConfirmPro(): void {
    this.ExpireInsuranceBuy();
    $('#errConfirm').hide();

    if ($('#confirmAccept').prop('checked') && this.userDetail.valid && this.building.valid && this.address.valid) {
      this.isConfirm = true;
      $('#errConfirm').hide();
      let nationality_name = (this.userDetail.get('nationalityName').value != null) ? this.userDetail.get('nationalityName').value : '';
      let id_card_passport = (this.userDetail.get('idCardPassport').value != null) ? this.userDetail.get('idCardPassport').value : '';
      if(this.userDetail.get('nationalityType').value == 1){
        id_card_passport = '';
      }
      let insureOwnerFirstName = (this.building.get('beneficiaryForm').get('firstName').value != null) ? this.building.get('beneficiaryForm').get('firstName').value : '';
      let insureOwnerLastName = (this.building.get('beneficiaryForm').get('lastName').value != null) ? this.building.get('beneficiaryForm').get('lastName').value : '';
      // let insureOwnerFirstName = (this.building.get('insureOwnerFirstName').value != null) ? this.building.get('insureOwnerFirstName').value : '';
      // let insureOwnerLastName = (this.building.get('insureOwnerLastName').value != null) ? this.building.get('insureOwnerLastName').value : '';
      // let benefit_nationality_name = (this.building.get('beneficiaryForm').get('nationalityName').value != null) ? this.building.get('beneficiaryForm').get('nationalityName').value : '';
      // let benefit_id_card_passport = (this.building.get('beneficiaryForm').get('idCardPassport').value != null) ? this.building.get('beneficiaryForm').get('idCardPassport').value : '';

      let nameProvince = this.building.get('province').value;
      let nameProvince2 = this.address.get('province').value;

      let nameDistrict = this.building.get('district').value;
      this.district.forEach((e: { value: any; label: string; }) => {
        if (e.value == this.building.get('district').value) {
          nameDistrict = e.label;
        }
      });

      let nameDistrict2 = this.address.get('district').value;
      this.district2.forEach((e: { value: any; label: string; }) => {
        if (e.value == this.address.get('district').value) {
          nameDistrict2 = e.label;
        }
      });

      let nameSubdistrict = this.building.get('subdistrict').value;
      this.subdistrict.forEach((e: { value: any; label: string; }) => {
        if (e.value == this.building.get('subdistrict').value) {
          nameSubdistrict = e.label;
        }
      });

      let nameSubdistrict2 = this.address.get('subdistrict').value;
      this.subdistrict2.forEach((e: { value: any; label: string; }) => {
        if (e.value == this.address.get('subdistrict').value) {
          nameSubdistrict2 = e.label;
        }
      });
      let insuranceTypeCurrent = this.insuranceType;
      var option: Object = {
        params: new HttpParams()
                .set('insurance_type', this.insuranceType) //1=ให้เช่าหายห่วง, 2=อยู่เองหายห่วง 3=ให้เช่าหายห่วง 2,199 4=ให้เช่าหายห่วง 3,399  5=ให้เช่าหายห่วง 5,199
                .set('firstname', this.userDetail.get('firstName').value)
                .set('lastname', this.userDetail.get('lastName').value)
                .set('gender_type', this.userDetail.get('genderType').value)
                .set('birdday', this.userDetail.get('year').value + '-' + this.userDetail.get('month').value + '-' + this.userDetail.get('day').value)
                .set('nationality_type', this.userDetail.get('nationalityType').value)
                .set('id_card', this.userDetail.get('idCard').value)
                .set('nationality_name', nationality_name)
                .set('id_card_passport', id_card_passport)
                .set('phone', this.userDetail.get('phone').value)
                .set('email', this.userDetail.get('email').value)
                .set('building_type', this.building.get('buildingType').value)
                .set('insure_by', this.userDetail.get('postFrom').value)
                .set('insure_owner_name', insureOwnerFirstName + '-' + insureOwnerLastName)
                // .set('home_no', this.building.get('houseNumber').value)
                .set('home_no', '')
                .set('address', this.building.get('addressDetail').value)
                .set('province', nameProvince)
                .set('subdistrict', nameSubdistrict)
                .set('district', nameDistrict)
                .set('zipcode', this.building.get('postalCode').value)
                .set('benefit_firstname', this.building.get('beneficiaryForm').get('firstName').value)
                .set('benefit_lastname', this.building.get('beneficiaryForm').get('lastName').value)
                // .set('benefit_gender_type', this.building.get('beneficiaryForm').get('genderTypeBf').value)
                // .set('benefit_birdday', this.building.get('beneficiaryForm').get('year').value + '-' + this.building.get('beneficiaryForm').get('month').value + '-' + this.building.get('beneficiaryForm').get('day').value)
                // .set('benefit_nationality_type', this.building.get('beneficiaryForm').get('nationalityTypeBf').value)
                // .set('benefit_nationality_name', benefit_nationality_name)
                .set('benefit_id_card', this.building.get('beneficiaryForm').get('idCard').value)
                // .set('benefit_id_card_passport', benefit_id_card_passport)
                // .set('benefit_home_no', this.address.get('houseNumber').value)
                .set('benefit_home_no', '')
                .set('benefit_address', this.address.get('addressDetail').value)
                .set('benefit_province', nameProvince2)
                .set('benefit_subdistrict', nameSubdistrict2)
                .set('benefit_district', nameDistrict2)
                .set('benefit_zipcode', this.address.get('postalCode').value)
                .set('agree_1', ($('#beneficiary').prop('checked') == true) ? '1' : '0')
                .set('agree_2', ($('#addressSame').prop('checked') == true) ? '1' : '0')
                .set('insuranceCode', this.insuranceCode) // code อัพเดท
                .set('insuranceId', this.insuranceId) // id อัพเดท
                .set('product_token', $.cookie('product_token'))
                .set('confirmPostOffice', ($('#confirmPostOffice').prop('checked') == true) ? '1' : '0') // จัดส่งไปรษณีย์ true, false
      }
      this.http.get(this.env.in_api + 'plus_add.php', option).subscribe(data => {
      // this.http.get(this.env.in_api + 'plus_add.php', option).subscribe(data => {
        // อ่านค่า result จาก JSON response ที่ส่งออกมา
        if (data['result_code'] == 0) {
          //new code
          this.insuranceCode = data['insurance_code'];
          const hisCode: object = {
            code : this.insuranceCode,
            insurance_type: insuranceTypeCurrent
          };
          localStorage.setItem('hisCode', JSON.stringify(hisCode));
          //new code

          // this.FormSuccess();
          // this.FormPaymentOption();
          // localStorage.removeItem('session_token_buy');

          //new code
          this.handlePhone = this.userDetail.get('phone').value;
          this.ExpireInsuranceBuy();
          const contact = JSON.parse(localStorage.getItem('session_token_buy'));
          if (null != contact){
            this.FormPaymentOption();
          }else{
            $('.modal').modal('hide');
            setTimeout( () => {
              formRequestOTP();
            }, 200);
          }
          this.reSetFormValue();
          //new code
        } else {
          alert('เกิดข้อผิดพลาดกรุณาลองใหม่')
        }
        this.isConfirm = false;
      });
    } else {
      $('#errConfirm').show();
      let element = document.getElementById("confirmAccept");
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  FormUser(): void {
    $('.form-building, .form-address, .title-success, .form-success, .form-confirm, .header-confirm').fadeOut(300, () => {
      setTimeout(() => {
        $('.form-user, .group-policy, .header-detail').fadeIn(300);
      }, 500);
    });
  }

  FormBuilding(): void {
    this.pageAddress = false;
    // $(`[formControlName="buildingType"][value=${this.handleBuildingType}]`).click();
    // $(`[formControlName="postFrom"][value=${this.handlePostFrom}]`).click();
    $('.form-user, .form-address, .group-policy, .form-confirm, .header-confirm ').fadeOut(300, () => {
      setTimeout(() => {
        $('.form-building').fadeIn(300);
        if (this.building.get('beneficiary').value) {
          this.handleToggleUser();
          if(this.userDetail.get('postFrom').value === '1'){
            this.building.get('beneficiaryForm').patchValue({
              firstName: '',
              lastName: '',
              // nationalityTypeBf: '1',
              idCard: '',
              // nationalityName: '',
              // idCardPassport: '',
              // year: '',
              // month: '',
              // day: '',
              // genderTypeBf: '1',
            });
          }
        }
      }, 500);
    });
  }

  FormAddress(): void {
    this.pageAddress = true;
    this.boolChangeAddress = true;
    $('.form-user, .form-building, .form-confirm, .header-confirm, .group-policy').fadeOut(300, () => {
      setTimeout(() => {
        $('.form-address, .header-detail').fadeIn(300);
        // if(this.address.get('addressSame').value) {
        //   this.handleToggleAddress();
        // }
      }, 500);
    });
  }

  FormConfirm(): void {
    $('.form-user, .form-building, .form-address, .header-detail').fadeOut(300, () => {
      setTimeout(() => {
        $('.form-confirm, .header-confirm').fadeIn(300);
      }, 500);
    });
  }

  FormSuccess(): void {
    $('.form-user, .form-building, .form-address, .header-detail, .form-confirm, .header-confirm').fadeOut(300, () => {
      setTimeout(() => {
        $('.form-success, .header-detail, .title-success').fadeIn(300);
        $('.policy-msig~.border-bottom').hide();
        $('.policy-msig').hide();
      }, 500);
    });
  }

  FormPaymentOption(): void {
    $('.form-user, .form-building, .form-address, .header-detail, .form-confirm, .header-confirm').fadeOut(300, () => {
      setTimeout(() => {
        $('#buyInsuranceModal').modal('show');
        $('.form-payment, .header-detail').fadeIn(300);
      }, 500);
    });
  }

  // handleToggleUser(history = 0): void {
  //   if (this.building.get('beneficiary').value || history == 1) {
  //     this.building.get('beneficiaryForm').patchValue({
  //       firstName: this.userDetail.get('firstName').value,
  //       lastName: this.userDetail.get('lastName').value,
  //       genderTypeBf: this.userDetail.get('genderType').value,
  //       nationalityTypeBf: this.userDetail.get('nationalityType').value,
  //       day: this.userDetail.get('day').value,
  //       month: this.userDetail.get('month').value,
  //       year: this.userDetail.get('year').value,
  //       idCardPassport: this.userDetail.get('idCardPassport').value,
  //       nationalityName: this.userDetail.get('nationalityName').value,
  //       idCard: this.userDetail.get('idCard').value,
  //     });

  //     setTimeout( () => {
  //       $('.form-building [formGroupName="beneficiaryForm"] input').attr('readonly', true);
  //       $('.form-building [formGroupName="beneficiaryForm"] input[type="radio"]').attr('disabled', true);
  //       $('.form-building [formGroupName="beneficiaryForm"] select').attr('disabled', true);
  //       $('.form-building [formGroupName="beneficiaryForm"] select2').attr('disabled', true);
  //       $('[name=nationalityName]').addClass('none-event');
  //       $('[name=nationalityName] .select2-selection--single').css({'background-color': '#e9ecef', 'opacity': 1});
  //       let month = (this.building.get('beneficiaryForm').get('month').value != '') ? this.building.get('beneficiaryForm').get('month').value : 0;
  //       this.nameMonthsP2 = this.months[month]['FullName'];
  //     }, 200);
  //     $('[formGroupName="beneficiaryForm"] .wording-complpte').hide();
  //     $('[formGroupName="beneficiaryForm"] .wording-wrong').hide();

  //   } else {
  //     this.building.get('beneficiaryForm').patchValue({
  //       firstName: '',
  //       lastName: '',
  //       genderTypeBf: '1',
  //       nationalityTypeBf: '1',
  //       day: '',
  //       month: '',
  //       year: '',
  //       idCardPassport: '',
  //       nationalityName: '',
  //       idCard: '',
  //     })

  //     setTimeout(() => {
  //       $('.form-building [formGroupName="beneficiaryForm"] input').attr('readonly', false);
  //       $('.form-building [formGroupName="beneficiaryForm"] input[type="radio"]').attr('disabled', false);
  //       $('.form-building [formGroupName="beneficiaryForm"] select').attr('disabled', false);
  //       $('.form-building [formGroupName="beneficiaryForm"] select2').attr('disabled', false);
  //       $('[name=nationalityName]').removeClass('none-event');
  //       $('[name=nationalityName] .select2-selection--single').css({'background-color': 'unset', 'opacity': 1});
  //     }, 200);
  //   }
  // }

  handleToggleUser(history = 0): void {
    if (this.building.get('beneficiary').value || history == 1) {
      $('.form-building [formcontrolname="beneficiary"]').attr('disabled', true);
      this.building.patchValue({
        // houseNumber: this.address.get('houseNumber').value,
        addressDetail: this.address.get('addressDetail').value,
        province: this.address.get('province').value
      });

      setTimeout( ()=>{
        this.building.patchValue({
          district: this.address.get('district').value,
        });
      }, 1000);

      setTimeout( ()=>{
        this.building.patchValue({
          subdistrict: this.address.get('subdistrict').value,
        });
      }, 1400);

      setTimeout( () => {
        $('.form-building [formcontrolname="beneficiary"]').attr('disabled', false);
        // $('.form-building [formcontrolname="houseNumber"]').attr('disabled', true);
        $('.form-building [formcontrolname="addressDetail"]').attr('disabled', true);

        $('.form-building [formControlName="province"]').addClass('none-event');
        $('.form-building [formControlName="province"] .select2-selection--single').css({'background-color': '#e9ecef', 'opacity': 1});

        $('.form-building [formControlName="district"]').addClass('none-event');
        $('.form-building [formControlName="district"] .select2-selection--single').css({'background-color': '#e9ecef', 'opacity': 1});

        $('.form-building [formControlName="subdistrict"]').addClass('none-event');
        $('.form-building [formControlName="subdistrict"] .select2-selection--single').css({'background-color': '#e9ecef', 'opacity': 1});
        $('.form-building [formcontrolname="postalCode"]').attr('disabled', true);
      }, 1000);
    } else {
      this.building.patchValue({
        province: '',
        subdistrict: '',
        district: '',
        postalCode: '',
        // houseNumber: '',
        addressDetail: '',
      })

      setTimeout(() => {
        // $('.form-building [formcontrolname="houseNumber"]').attr('disabled', false);
        $('.form-building [formcontrolname="addressDetail"]').attr('disabled', false);

        $('.form-building [formControlName="province"]').removeClass('none-event');
        $('.form-building [formControlName="province"] .select2-selection--single').css({ 'background-color': 'unset', 'opacity': 1 });

        $('.form-building [formControlName="district"]').removeClass('none-event');
        $('.form-building [formControlName="district"] .select2-selection--single').css({ 'background-color': 'unset', 'opacity': 1 });

        $('.form-building [formControlName="subdistrict"]').removeClass('none-event');
        $('.form-building [formControlName="subdistrict"] .select2-selection--single').css({ 'background-color': 'unset', 'opacity': 1 });
        $('.form-building [formcontrolname="postalCode"]').attr('disabled', false);
        // $('.form-address select[select2]').attr('disabled', false)
      }, 200);
    }
  }

  handleToggleAddress(): void {
    if (this.address.get('addressSame').value) {
      this.boolChangeAddress = false;
      this.address.patchValue(this.tempAddressBuilding)
      this.boolChangeAddress = true;
      setTimeout( () => {
        // $('.form-address [formcontrolname="houseNumber"]').attr('disabled', true);
        $('.form-address [formcontrolname="addressDetail"]').attr('disabled', true);
        $('.form-address [formcontrolname="subdistrict"]').attr('disabled', true);
        $('.form-address [formcontrolname="district"]').attr('disabled', true);
        $('.form-address [formControlName="province"]').addClass('none-event');
        $('.form-address [formControlName="province"] .select2-selection--single').css({'background-color': '#e9ecef', 'opacity': 1});
        $('.form-address [formcontrolname="postalCode"]').attr('disabled', true);
      }, 200);
    } else {

      this.address.patchValue({
        province: '',
        subdistrict: '',
        district: '',
        postalCode: '',
        // houseNumber: '',
        addressDetail: '',
      })
      setTimeout(() => {
        // $('.form-address [formcontrolname="houseNumber"]').attr('disabled', false);
        $('.form-address [formcontrolname="addressDetail"]').attr('disabled', false);
        $('.form-address [formcontrolname="subdistrict"]').attr('disabled', false);
        $('.form-address [formcontrolname="district"]').attr('disabled', false);

        $('.form-address [formControlName="province"]').removeClass('none-event');
        $('.form-address [formControlName="province"] .select2-selection--single').css({ 'background-color': 'unset', 'opacity': 1 });
        $('.form-address [formcontrolname="postalCode"]').attr('disabled', false);
        // $('.form-address select[select2]').attr('disabled', false)
      }, 200);
    }
  }

  handleChangeAddress(e: any): void {
    if (this.boolChangeAddress) {
      if (
        // this.building.get('houseNumber') != this.address.get('houseNumber') &&
        this.building.get('addressDetail') != this.address.get('addressDetail') &&
        this.building.get('province') != this.address.get('province') &&
        this.building.get('district') != this.address.get('district') &&
        this.building.get('postalCode') != this.address.get('postalCode')
      ) {
        this.address.patchValue({
          addressSame: '',
        });
      } else {
        this.address.patchValue({
          addressSame: '1',
        });
      }
    }

  }

  handleChangePostcode(e: { value: string; }, type: string): void {
    if (e.value != '' && e.value != null && e.value != undefined) {
      let value = e.value;
      if(type == '1'){
        if(this.building.get('beneficiary').value == 1){
          this.building.patchValue({
            postalCode: this.address.get('postalCode').value,
          });
        }else{
          this.district.forEach(element => {
            if(value != '' && value == element.value){
              this.building.patchValue({
                postalCode: element.postcode,
              });
            }
          });
        }
      }else{
        this.district2.forEach(element => {
          if(value != '' && value == element.value){
            // this.postalCode2 = element.postcode;
            this.address.patchValue({
              postalCode: element.postcode,
            });
          }
        });
      }
    }
  }

  handleChangeProvice(e: { value: string; }, type: string): void {
    if (e.value != '' && e.value != null && e.value != undefined) {
      var option: Object = {
        params: new HttpParams()
                .set('province_id', e.value)
      }
      this.http.get(this.env.in_api + 'plus_address.php', option).subscribe(data => {
        if (type == '1') {
          this.district = data['list'];
          // this.district2 = data['list'];
          // if (this.building.get('beneficiary').value == '1') {
            this.building.get('district').reset('')
            this.building.get('postalCode').reset('')
            this.building.get('subdistrict').reset('')
          // }
          // this.building.get('district').reset('')
          // this.building.get('postalCode').reset('')
        } else {
          this.district2 = data['list'];
          this.district = data['list'];
          this.address.get('district').reset('')
          this.address.get('postalCode').reset('')
          this.address.get('subdistrict').reset('')
          // if (this.address.get('addressSame').value != '1') {
          //   this.district2 = data['list'];
          //   this.district = data['list'];
          //   this.address.get('district').reset('')
          //   this.address.get('postalCode').reset('')
          // }

        }
      })
    }
  }

  handleChangeDistrict(e: { value: string; }, type: string): void {
    if (e.value != '' && e.value != null && e.value != undefined) {
      let province = '';
      if(type == '1'){
        province = this.building.get('province').value;
      }else{
        province = this.address.get('province').value;
      }
      var option: Object = {
        params: new HttpParams()
                .set('province_id', province)
                .set('amphur_id', e.value)
      }

      this.http.get(this.env.in_api + 'plus_address.php', option).subscribe(data => {
        if (type == '1') {
          this.subdistrict = data['list'];
          if(this.building.get('beneficiary').value != 1){
            this.building.get('subdistrict').reset('')
          }
          // this.postalCode2 = data['list'];
          // this.building.get('postalCode').reset('')
        } else {
          this.subdistrict2 = data['list'];
          this.subdistrict = data['list'];
          this.address.get('subdistrict').reset('');

          // this.address.get('postalCode').reset('')
          // if (this.address.get('addressSame').value != '1') {
          //   this.postalCode2 = data['list'];
          //   this.postalCode = data['list'];
          //   this.address.get('postalCode').reset('')
          // }

        }
      })
    }
  }

  handleChangePostFrom(): void {
    if (this.building.get('postFrom').value == '2') {
      // this.building.removeControl('insureOwnerName');
      this.building.get('insureOwnerName').setValidators([Validators.required]);
      // this.building.addControl('insureOwnerName',[Validators.required]);
    } else {
      this.building.get('insureOwnerName').setValidators([]);
      // this.building.removeControl('insureOwnerName');
      // this.building.addControl('insureOwnerName',[]);

    }
  }

  confirmAlert(e: any): void {
    if (e) {
        this.reSetFormValue();
        this.FormUser();
        $('.alert-block').hide()
        $('.modal').modal('hide')
    } else {
      $('.alert-block').hide()
    }
    $('#buyInsuranceModal .modal-content').removeClass('overlay')

  }

  openCondition(): void {
    $('#buyInsuranceModal .modal-content').addClass('overlay')
    $('#conditionInsurance').show();

  }

  closeCondition(): void {
    $('#conditionInsurance').hide();
    $('#buyInsuranceModal .modal-content').removeClass('overlay')

  }

  handleRequestOTP(): void {
    const option: object = {
      params: new HttpParams()
              .set('phone', this.requestOTP.get('phone').value)
    };
    this.http.get<ApiRequestOTP>(`${this.env.in_api}plus_validate_otp_buy.php`, option).subscribe(data  => {
      if (data?.result_code === 0) {
        this.confirmOTP.patchValue({
          otpToken: data.otp_token,
          phone: this.requestOTP.get('phone').value,
        });
        this.formOTP();
      } else {
        setTimeout(() => {
          this.responseRequestOTP = data;
          this.errorOtpBuy = data.result_msg;
          $('.btnOtpBuy').prop('disabled', false);
          this.requestOTP.get('phone').setErrors({incorrect: true});
        }, 200);
      }
    });
  }

  onOtpChange(value: any): void {
    this.confirmOTP.patchValue({
      otpActivate: value
    });
    // const otp = this.confirmOTP.get('otpActivate').value;
    // if(otp.length >= 6 ){
    //   this.handleSubmitConfirmOTP();
    // }
  }

  formOTP(): void {
    $('#otpModalBuy .form-input').fadeOut(300, () => {
      $('.require-phone').hide();
      $('.require-phone-otp').show();
      $('#otpModalBuy .form-otp').fadeIn(300);
      this.requestOTPAgain();
    });
  }

  requestOTPAgain(): void {
    countDown(60, (res) => {
      this.timingRequestOPT = res;
    });
  }

  changeEmail() {
    let email = this.userDetail.get('email').value;
    let lowerEmail = email.toLowerCase();
    this.userDetail.patchValue({
      email: lowerEmail
    });
  }

  handleSubmitConfirmOTP(): void {
    this.textErrorOtp = 0;
    const option: object = {
      params: new HttpParams()
              .set('phone', this.confirmOTP.get('phone').value)
              .set('otp_token', this.confirmOTP.get('otpToken').value)
              .set('activate_code', this.confirmOTP.get('otpActivate').value)
    };

    if (this.confirmOTP.get('otpActivate').valid) {
      Block.Pulse('#otpModalBuy .modal-content');
      this.http.get<ApiConfirmOTP>(`${this.env.in_api}plus_validate_otp_buy_check.php`, option).subscribe(data => {
        if (data.result_code === 0) {
          let timeExpire = new Date();
          timeExpire.setMinutes(timeExpire.getMinutes() + 15);
          timeExpire = new Date(timeExpire);
          const session: object = {
            // token: data.device_id,
            phone: this.confirmOTP.get('phone').value,
            expire: timeExpire
          };

          const seesionPhone: any = {
            phone : this.confirmOTP.get('phone').value
          };

          this.dataUser = session;
          this.userDetail.patchValue({phone: this.confirmOTP.get('phone').value});

          localStorage.setItem('session_token_buy', JSON.stringify(session));
          localStorage.setItem('seesionPhone', JSON.stringify(seesionPhone));
          // open list my insurance
          $('.modal').modal('hide');
          Block.Remove('#otpModalBuy .modal-content');
          this.FormPaymentOption();
          // form otp
          // setTimeout( () => {
          //   $('#buyInsuranceModal').modal('show');
          //   this.getDataPhoneHistory(this.confirmOTP.get('phone').value);
          // }, 650);

          // Block.Remove('#insuranceListModal .modal-content');

          // this.openInsuranceList();
        } else {
          Block.Remove('#otpModalBuy .modal-content');
          this.textErrorOtp = 1;
          this.textErrorOtpMsg = data.result_msg;
          // Report.Warning(
          //   'เกิดข้อผิดพลาด',
          //   data.result_msg,
          //   'รับทราบ');
          // $('.notiflix-block-wrap').remove();
        }
      });
    }
  }

  getDataPhoneHistory(code: any): void{
    const option: Object = {
      params: new HttpParams()
              .set('code', code)
              .set('insurance_type', this.insuranceType)
    };
    //code new close
    this.http.get(this.env.in_api + 'plus_detail_history.php', option).subscribe(data => {
      // อ่านค่า result จาก JSON response ที่ส่งออกมา
      if (data['result_code'] === 0) {
        if (Object.keys(data['detail']).length > 0){
          this.setDataHistory(data['detail']);
        }
      }else{
        localStorage.removeItem('hisCode');
      }
    });
    //code new close
  }

  setDataHistory(data: any): void{
    this.insuranceCode = data.insurance_code;
    this.insuranceId = data.insurance_id;
    const [year1, month1, day1] = data.birthday.split('-');
    this.nameMonthsP1 = this.months[parseInt(month1)]['FullName'];
    // const [insureFirstName, insureLastName] = data.insure_owner_name.split('-');
    this.userDetail.patchValue({
      // new
      postFrom: data.insure_by,
      // insureOwnerFirstName: insureFirstName,
      // insureOwnerLastName: insureLastName,
      // new
      firstName: data.firstname,
      lastName: data.lastname,
      genderType: data.gender_type,
      year: year1,
      month: parseInt(month1),
      day: parseInt(day1),
      nationalityType: data.nationality_type,
      idCard: data.id_card,
      nationalityName: data.nationality_name,
      idCardPassport: data.id_card_passport,
      phone: data.phone,
      email: data.email
    });

    // address
    this.address.patchValue({
      buildingType: data.building_type,
      // subdistrict: data.subdistrict,
      province: data.province,
      // district: data.district,
      postalCode: data.zipcode,
      // houseNumber: data.home_no,
      addressDetail: data.address,
    });

    setTimeout( ()=>{
      this.address.patchValue({
        district: data.district,
      });
    }, 1000);

    setTimeout( ()=>{
      this.address.patchValue({
        subdistrict: data.subdistrict,
      });
    }, 1400);
    // address

    // building
    this.building.patchValue({
      // addressSame: data.benefit_home_no,
      province: data.benefit_province,
      // subdistrict: data.benefit_subdistrict,
      // district: data.benefit_district,
      postalCode: data.benefit_zipcode,
      // houseNumber: data.benefit_home_no,
      addressDetail: data.benefit_address,
    });

    setTimeout( ()=>{
      this.building.patchValue({
        district: data.benefit_district,
      });
    }, 1000);

    setTimeout( ()=>{
      this.building.patchValue({
        subdistrict: data.benefit_subdistrict,
      });
    }, 1400);
    // building

    this.handleBuildingType = data.building_type;
    this.handlePostFrom = data.insure_by;

    if(data.insure_by == '2'){
      const [year2, month2, day2] = data.benefit_birthday.split('-');
      this.nameMonthsP2 = this.months[parseInt(month2)]['FullName'];
      this.building.get('beneficiaryForm').patchValue({
        // beneficiary: 1,
        firstName: data.benefit_firstname,
        lastName: data.benefit_lastname,
        // genderTypeBf: data.benefit_gender_type,
        // year: year2,
        // month: parseInt(month2),
        // day: parseInt(day2),
        // nationalityTypeBf: data.benefit_nationality_type,
        idCard: data.benefit_id_card,
        // nationalityName: data.benefit_nationality_name,
        // idCardPassport: data.benefit_id_card_passport
      });
    }

    if (data.agree_1 == 1) {
      $('#beneficiary').prop('checked', true);
      this.building.patchValue({
        beneficiary: data.agree_1,
      });
      this.handleToggleUser(1);
    }

    if(data.benefit_post_office_status == 1){
      $('#confirmPostOffice').prop('checked', true);
    }
    // setTimeout(() => {
    //   if (data.agree_1 == 1) {
    //     $('#addressSame').prop('checked', true);
    //     setTimeout( () => {
    //       $('.form-address [formcontrolname="houseNumber"]').attr('disabled', true);
    //       $('.form-address [formcontrolname="addressDetail"]').attr('disabled', true);
    //       $('.form-address [formcontrolname="subdistrict"]').attr('disabled', true);
    //       $('.form-address [formcontrolname="district"]').attr('disabled', true);
    //       $('.form-address [formControlName="province"]').addClass('none-event');
    //       $('.form-address [formControlName="province"] .select2-selection--single').css({'background-color': '#e9ecef', 'opacity': 1});
    //       $('.form-address [formcontrolname="postalCode"]').attr('disabled', true);
    //     }, 200);
    //   }
    // }, 250);
  }

  ExpireInsuranceBuy(): void {
    if (localStorage.getItem('session_token_buy')) {
      const session = JSON.parse(localStorage.getItem('session_token_buy'));
      const timeExpire = new Date(session.expire);
      const timeNow = new Date();
      const phoneNow = this.handlePhone;
      const phone = session.phone;
      if (timeNow >= timeExpire || phone != phoneNow){
        this.clearTimeOtp();
      }
    }
  }

  clearTimeOtp(): void {
    localStorage.removeItem('session_token_buy');
    // $('.modal').modal('hide');
    // setTimeout(() => {
    //   alertPopUp({title: 'เกิดข้อผิดพลาด', detail: 'หมดเวลาการใช้งานกรุณายืนยันตัวตนใหม่อีกครั้ง', button: 'ปิด'});
    //   setTimeout(() => {
    //     $('#otpModalBuy').modal('show');
    //     formRequestOTP();
    //   }, 700);
    // }, 700);
  }

  formPaymentApi(type: string): void {
    const option: Object = {
      params: new HttpParams()
              .set('insurance_code', this.insuranceCode)
              .set('payment_type', type)
    }
    this.http.get(this.env.in_api + 'plus_form_payment.php', option).subscribe(data => {
      // อ่านค่า result จาก JSON response ที่ส่งออกมา
      if (data['result_code'] === 0) {
        if (Object.keys(data['detail']).length > 0){
          this.getFormPayment(data['detail']['form']);
        }
      }
    });
  }

  getFormPayment(resp: any): void {
    const form: any = document.createElement('Form');
    let element: any;
    form.method = resp.attr.method;
    form.action = resp.attr.action;
    form.name = resp.attr.name;
    delete(resp.attr);
    document.body.appendChild(form);
    $.each(resp.input, function(i: any, o: { name: any; type: any; value: any; }){
      element = document.createElement('input');
      element.name = o.name;
      element.type = o.type;
      element.value = o.value;
      form.appendChild(element);
    });
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

  clearBeneficiaryFormName(is: any): void {
    if(is == 2){
      this.building.get('beneficiaryForm').patchValue({
        firstName: '',
        lastName: '',
        idCard: '',
      });
    }
  }

  checkCardId(number: any): void {
    let idCard: any;
    setTimeout(() => {
      if (number == 1) {
        $('.form-user .wording-complpte').hide();
        $('.form-user .wording-wrong').hide();
        idCard = this.userDetail.get('idCard').value;
      } else {
        idCard = this.building.get('beneficiaryForm').get('idCard').value;
        $('[formGroupName="beneficiaryForm"] .wording-complpte').hide();
        $('[formGroupName="beneficiaryForm"] .wording-wrong').hide();
        $('[formGroupName="beneficiaryForm"] .wording-wrong-duplicate').hide();
      }
      if (idCard.length > 12) {
        const result = this.Script_checkID(idCard);
        if(result === false){
          if (number == 1) {
            $('.form-user .wording-wrong').show();
            this.userDetail.get('idCard').setErrors({incorrect: true});
          } else {
            $('[formGroupName="beneficiaryForm"] .wording-wrong').show();
            this.building.get('beneficiaryForm').get('idCard').setErrors({incorrect: true});
          }
        }else{
          if (number == 1) {
            $('.form-user .wording-complpte').show();
            this.userDetail.get('idCard').setErrors(null);
          } else {
            $('[formGroupName="beneficiaryForm"] .wording-complpte').show();
            this.building.get('beneficiaryForm').get('idCard').setErrors(null);
          }
        }
        let postFrom = this.userDetail.get('postFrom').value;
        let userDetailId = this.userDetail.get('idCard').value;
        let beneficiaryFormId = this.building.get('beneficiaryForm').get('idCard').value;
        if(postFrom == 2 && userDetailId == beneficiaryFormId){
          $('[formGroupName="beneficiaryForm"] .wording-complpte').hide();
          $('[formGroupName="beneficiaryForm"] .wording-wrong').hide();
          this.building.get('beneficiaryForm').get('idCard').setErrors({incorrect: true});
          $('[formGroupName="beneficiaryForm"] .wording-wrong-duplicate').show();
        }else{
          $('[formGroupName="beneficiaryForm"] .wording-complpte').hide();
          $('[formGroupName="beneficiaryForm"] .wording-wrong').hide();
          $('[formGroupName="beneficiaryForm"] .wording-wrong-duplicate').hide();
          $('[formGroupName="beneficiaryForm"] .wording-complpte').show();
          if(beneficiaryFormId < 13){
            $('[formGroupName="beneficiaryForm"] .wording-complpte').hide();
          }
          this.building.get('beneficiaryForm').get('idCard').setErrors(null);
        }
      }
    }, 100)
  }

  Script_checkID(id: any): any {
    var sum: any, i: any;
    if(! this.IsNumeric(id)) return false;
    if(id.substring(0,1)== 0) return false;
    if(id.length != 13) return false;
    for(i=0, sum=0; i < 12; i++)
        sum += parseFloat(id.charAt(i))*(13-i);
    if((11-sum%11)%10!=parseFloat(id.charAt(12))) return false;
    return true;
  }

  IsNumeric(input: any): any{
    var RE = /^-?(0|INF|(0[1-7][0-7]*)|(0x[0-9a-fA-F]+)|((0|[1-9][0-9]*|(?=[\.,]))([\.,][0-9]+)?([eE]-?\d+)?))$/;
    return (RE.test(input));
  }
}

export function formRequestOTP(): void {
  $('#otpModalBuy .form-otp').fadeOut(300, () => {
    $('.require-phone-otp').hide();
    $('.require-phone').show();
    $('#otpModalBuy .form-input').fadeIn(300);
    $('#otpModalBuy .modal-header').css({'display': 'flex'});
    $('#otpModalBuy .modal-body').css({'display': 'block'});
    $('#otpModalBuy').modal();
  });
}

export function checkExpireOtpBuy(): void {
  const fun = new BuyInsuranceComponent(null, null);
  fun.ExpireInsuranceBuy();
}

export function conditionalValidator(predicate: BooleanFn, validator: ValidatorFn, errorNamespace?: string): ValidatorFn {
  return (formControl => {
    if (!formControl.parent) {
      return null;
    }
    let error = null;
    if (predicate()) {
      error = validator(formControl);
    }
    if (errorNamespace && error) {
      const customError = {};
      customError[errorNamespace] = error;
      error = customError;
    }
    return error;
  });
}

