import { Report } from 'notiflix';
import { MessageAlert } from '../interfaces/middle';

function countDown(timing: number, callback: (res: number) => void): void {
  const downloadTimer = setInterval(() => {
    timing -= 1;
    if(timing <= 0){
      clearInterval(downloadTimer);
      callback(timing);
    } else {
      callback(timing);
    }
  }, 1000);
}

function alertPopUp(data: MessageAlert): void {
  Report.Warning(
    data.title,
    data.detail,
    data.button
  );
}


export {countDown, alertPopUp};
