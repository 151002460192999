import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constructor(
    private deviceService: DeviceDetectorService
  ) { }

  urlLine:string = "https://line.me/ti/p/@livinginsure";
  policy: number;
  insurance_type_check: any = "";

  ngOnInit(): void {
    // this.handleCheckPolicyFooter();
    const contact = JSON.parse(localStorage.getItem('hisCode'));
      if(window.location.pathname != '/payments/status'){
        if (null != contact){
          if(contact.insurance_type){
            this.insurance_type_check = contact.insurance_type;
          }else{
            $('.chat-icon').css('bottom', '20px');
          }
        }else{
          $('.chat-icon').css('bottom', '20px');
        }
      }
    if(localStorage.getItem('cook_accept_all') == "1"){
      this.remove_privacyCookie()
      $('.icon-accpet-cookie-smile').css('display','block')
    }else{
      $('#popup_privacyCookie').css('display','block');
    }
  }

  ngAfterViewInit(): void {
    // $(document).ready(() => {
    //   var $window = $(window).width();
    //   $(window).resize(function() {
    //     $window = $(window).width();
    //     if ($window > 768) {
    //       $('.chat-icon').css({
    //         'bottom': '82px'
    //       })
    //     } else {
    //       $('.chat-icon').css({
    //         'bottom': '150px'
    //       })
    //     }
    //   })
    // })
  }

  removeFooter(): void {
    $('.footer-buy-insurance').remove();
    $('.chat-icon').css('bottom', '20px');
  }

  handleOpenInsurace(type, interestedBuy = 0): void {
    if($(window).width() <= 767.98){
      $(`.plan-insurance-new-mobile .plan-mobile-list .plan-btn-price .plan-btn-${type}`).click();
    }else{
      $(`.plan-insurance-new .plan-list-${type} .btn-price`).click();
    }
  }

  handleOpenDetailInsurace(): void {

  }

  handleOpenContact(): void {
    if (this.deviceService.isDesktop()) {
      $('#contactModal').modal();
    } else {
      window.open(this.urlLine, '_blank')
    }
  }

  handlePolicyFooter(): void {
    const host = window.location.host;
    if (host === 'localhost:4200'){
      $.cookie('_privacy' , 1 , { domain: 'localhost' ,  path : '/' });
    }else{
      $.cookie('_privacy' , 1 , { domain: '.livinginsider.com' ,  path : '/' });
    }
    this.policy = 1;
  }

  handleCheckPolicyFooter(): void {
    const host = window.location.host;
    if ($.cookie('_privacy') === undefined || $.cookie('_privacy') == 0){
      if (host === 'localhost:4200'){
        $.cookie('_privacy' , 0 , { domain: 'localhost' ,  path : '/' });
      }else{
        $.cookie('_privacy' , 0 , { domain: '.livinginsider.com' ,  path : '/' });
      }
      this.policy = 0;
    }else{
      this.policy = 1;
    }
  }


	remove_privacyCookie(){
		$('#popup_privacyCookie').remove();
		// $('.create_post_btn_footer').css('display','block');

    $('.icon-accpet-cookie-smile').css('display','block');

    $('#Change_Preferences_cookie').modal('hide');
	}

	accetp_cookie(){
    localStorage.setItem('cook_accept_all', '1');
    localStorage.setItem('cook_accept_stats', '1');
    localStorage.setItem('cook_accept_ads', '1');
    this.remove_privacyCookie()
	}

	Change_Preferences_cookie(){
		$('#Change_Preferences_cookie').modal('show');
		if(localStorage.getItem('cook_accept_stats')==='1'){
			$('[name=Analytics_cookies]').prop( "checked",true)
		}else {
			$('[name=Analytics_cookies]').prop( "checked",false)
		}
		if(localStorage.getItem('cook_accept_ads')==='1'){
			$('[name=Basic_cookies]').prop( "checked",true)
		}else {
			$('[name=Basic_cookies]').prop( "checked",false)
		}
	}
	Confirm_My_Choices(){
		localStorage.setItem('cook_accept_all','1')
		localStorage.setItem('cook_accept_stats','0')
		localStorage.setItem('cook_accept_ads','0')
		if($('[name=Analytics_cookies]').prop( "checked") === true){
			localStorage.setItem('cook_accept_stats','1')
		}
		if($('[name=Basic_cookies]').prop( "checked") === true){
			localStorage.setItem('cook_accept_ads','1')
		}
		$('#Change_Preferences_cookie').modal('hide');
		this.remove_privacyCookie();
    $('.icon-accpet-cookie-smile').css('display','block');
	}
}
