import { Component, Input, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss']
})
export class ModalsComponent implements OnInit {

  @Input()
  insuranceType: string;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    $(document).ready(() => {
      // $('#detail-buy-insurance').modal()
    })
  }

  handleInsuranceOpen():void {
    if ($('#comfirmBuy').prop('checked') == true) {
      $('.modal').modal('hide');
      setTimeout(() => {
        $('.policy-msig~.border-bottom').show();
        $('.policy-msig').show();
        $('#buyInsuranceModal').modal('show');
        setTimeout(() => {
          let formUser = $('#buyInsuranceModal .form-user');
          $('#buyInsuranceModal .form-user').animate({
            scrollTop: formUser.top - 30
          }, "slow");
        }, 150);
      }, 600);
    } else {
      $('.buy-insurance-wronging').show();
    }
  }

  comfirmBuy(): void {
    $('.buy-insurance-wronging').hide();
  }




}
