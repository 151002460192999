import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-article',
  templateUrl: './card-article.component.html',
  styleUrls: ['./card-article.component.scss']
})
export class CardArticleComponent implements OnInit {
  @Input() image: string;
  @Input() title: string;
  @Input() urlLink: string;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }
}
