<!-- ********* Footer Area Start ********* -->
<section class="footer_area" id="footer">
  <div class="container">
    <div class="row no-gutters justify-content-between footer-contact">
      <div class="col-md-5 col-lg-6 block-img">
        <div class="col-12">
          <img src="assets/images/web-living-logo.png" alt="Image" style="width: 50%;">
        </div>
        <div class="col-12">
          <div class="block-other-banner">
            <!-- <img src="assets/images/icon-31.png" alt="Image" style=""> -->
            <img src="assets/images/icon-32.png" alt="Image" style="">
            <img src="assets/images/icon-33.png" alt="Image" style="">
          </div>
        </div>
      </div>
      <div class="col-md-7 col-lg-6 float-right block-detail">
        <div class="col-12">
          <p class="title-footer">สอบถามข้อมูลเพิ่มเติมได้ที่</p>
        </div>
        <div class="col-12">
          <div class="row block-contact no-gutters">
            <div class="col-md-6 col-sm-12 d-flex align-items-center" style="white-space: nowrap;"><img src="assets/images/phone.png" alt="Image"> <div class="contact-phone"><div><a href="tel:+02 491 2901">02 491 2901</a> หรือ</div> <a href="tel:+088 554 0725">088 554 0725</a></div></div>
            <div class="col-md-6 col-sm-12" style="white-space: nowrap;"><img src="assets/images/mail.png" alt="Image">admin@Livinginsider.com</div>
            <div class="col-md-6 col-sm-12" style="white-space: nowrap;"><img src="assets/images/line.png" alt="Image">@livinginsure</div>
            <!-- <div class="col-md-6 col-sm-12" style="white-space: nowrap;"><img src="assets/images/web.png" alt="Image">www.livinginsider.com</div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- SEO -->
    <p class="text-seo" style="font-family: Sarabun;">การปล่อยเช่าต้องได้รับการคุ้มครองที่มากกว่า ประกันภัย ให้เช่าหายห่วง คุ้มครองครบ จบทุกปัญหาซื้อประกันคอนโด ซื้อประกันบ้าน ประกันบ้าน ประกันคอนโด ต้องที่นี่ Livinginsider ให้เช่าหมดห่วง ให้เช่าอุ่นใจ ให้เช่าสบายใจ ให้เช่าเบาใจ นำเสนอความคุ้มครองที่มากกว่า ใช้ได้ทั้งบ้านและคอนโด คุ้มครองครอบคลุมและคุ้มครองได้ครบ ทั้ง ภัยธรรมชาติ อัคคีภัย การโจรกรรมทรัพย์สิน รวมถึงความเสียหายของเฟอร์นิเจอร์ต่างๆที่เกิดจากความประมาทของผู้เช่า</p>
    <!-- /SEO -->
    <div class="report-public" style="margin: 0px 0 36px; text-align: center;font-family: Sarabun;color: #292b54;">
      Living Insider เป็นเพียงผู้ประชาสัมพันธ์ ผลิตภัณฑ์ให้กับ บริษัท เอ็ม เอส ไอ จี ประกันภัย (ประเทศไทย) จำกัด (มหาชน) เท่านั้น การเสนอขายและการพิจารณารับประกันภัยทั้งหมด ดำเนินการโดย บริษัท เอ็ม เอส ไอ จี ประกันภัย (ประเทศไทย) จำกัด (มหาชน)
    </div>
    <div class="row no-gutters justify-content-between footer-license" >
      <span class="">ลิขสิทธิ์ถูกต้อง 2020 Livinginsider</span>
      <!-- <span class="">Broker license no: ว00002/2562</span> -->
      <span class="">2015 livinginsider.com All right reserved</span>
    </div>
    <p class="footer-address"><span>บริษัท ลิฟวิ่ง อินไซเดอร์ จำกัด</span> เลขที่ 213/3 ชั้น 3 อาคาร ดี. พี. ถนนรัชดาภิเษก แขวงดินแดง เขตดินแดง กรุงเทพมหานคร 10400</p>
  </div>



</section>
<!-- ********* End explain area ********* -->

<!-- Chat -->
<!-- <div class="chat-icon" (click)="handleOpenContact()">
  <div class="pc-show">
    <img src="assets/images/contact-follow.svg" alt="ติดต่อสอบถาม">
    <span>ติดต่อสอบถาม</span>
  </div>
  <div class="mobile-show">
    <img src="assets/images/contact-line.svg" width="56px" alt="">

  </div>
</div> -->
<div class="widget-action" (click)="handleOpenContact()">
  <a class="widget-button" data-widget-action="">
    <img class="video-background-round" src="assets/images/adsVideoPost.gif">
  </a>
</div>


<!-- <div [className]="policy == 1 ? 'footer-policy-cookie d-none' : 'footer-policy-cookie'">
  <div class="block-group-policy">
    <img src="assets/images/footerpolicy.png" alt="" class="policy-cookie c-desktop">
    <img src="assets/images/footerpolicy-mobile.png" alt="" class="policy-cookie-mobile c-mobile">
    <div class="policy-cookie-detail">
      เว็บไซต์ LivingInsider ใช้คุกกี้เพื่อประสบการณ์การใช้งานที่ดี การคลิกปุ่ม “ยอมรับ”
      หรือใช้งานเว็บไซต์ของเราถือเป็นการยอมรับ <a href="https://www.livinginsider.com/manual.php?action=MzM=" rel="nofollow" target="_blank">นโยบายคุ้มครองข้อมูลส่วนบุคคลและการใช้งานคุกกี้</a>
    </div>
    <button class="policy-comfirm" (click)="handlePolicyFooter()">ยอมรับ</button>
  </div>
</div> -->

<!-- <div [className]="policy == 1 ? 'footer-policy-cookie d-none' : 'footer-policy-cookie'">
  <div class="block-group-policy">
    <img src="assets/images/footerpolicy.png" alt="" class="policy-cookie c-desktop">
    <img src="assets/images/footerpolicy-mobile.png" alt="" class="policy-cookie-mobile c-mobile">
    <div class="policy-cookie-detail">
      เราใช้คุกกี้เพื่อให้ท่านได้ข้อมูลที่ต้องการ แสดงเนื้อหาและโฆษณาให้ตรงกับความสนใจ รวมถึงเพื่อวิเคราะห์การเข้าใช้งานเว็บไซต์และทำความเข้าใจว่าผู้ใช้งานมาจากที่ใด หากกด “ยอมรับ” ถือเป็นการยอมรับ นโยบายของเรา  <a href="https://www.livinginsider.com/manual.php?action=MzM=" rel="nofollow" target="_blank">นโยบายคุ้มครองข้อมูลส่วนบุคคล</a>
    </div>
    <button class="policy-comfirm" (click)="handlePolicyFooter()">ยอมรับ</button>
  </div>
</div> -->

<!-- footer sale -->
<div class="footer-buy-insurance" *ngIf="insurance_type_check != ''">
  <div class="container">
    <button class="close" (click)="removeFooter()"><img src="assets/images/times-white-gray.png" alt=""></button>
    <div class="row no-gutters">
      <div class="col-lg-6 col-md-8 align-items-center">
        <div class="block-detail">
          <img src="assets/images/shield-card.png" class="protect-icon" alt="">
          <p class="title">
            <span>สนใจซื้อประกันภัย</span>
            <span *ngIf="insurance_type_check == '3'">ให้เช่าหายห่วง 2,199 บาท แล้วใช่ไหม ?</span>
            <span *ngIf="insurance_type_check == '4'">ให้เช่าหายห่วง 3,399 บาท แล้วใช่ไหม ?</span>
            <span *ngIf="insurance_type_check == '5'">ให้เช่าหายห่วง 5,199 บาท แล้วใช่ไหม ?</span>
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-4">
        <div class="block-button">
          <a href="#plan-insurance-msig" (click)="handleOpenInsurace(3);" *ngIf="insurance_type_check == '3'">
            <button class="btn btn-success-primary">
              สนใจซื้อ <i class="fa fa-angle-right"></i>
            </button>
          </a>
          <a href="#plan-insurance-msig" (click)="handleOpenInsurace(4);" *ngIf="insurance_type_check == '4'">
            <button class="btn btn-success-primary">
              สนใจซื้อ <i class="fa fa-angle-right"></i>
            </button>
          </a>
          <a href="#plan-insurance-msig" (click)="handleOpenInsurace(5);" *ngIf="insurance_type_check == '5'">
            <button class="btn btn-success-primary">
              สนใจซื้อ <i class="fa fa-angle-right"></i>
            </button>
          </a>
          <a target="_blank" href="assets/pdf/plan_insurance.pdf">
            <button class="btn btn-outline-light">
              รายละเอียด <i class="fa fa-angle-right"></i>
            </button>
          </a>
          </div>
        </div>
    </div>
  </div>

</div>
<!-- footer sale -->


<!-- new cookie -->
<!-- <a href="javascript:void(0);" (click)="Change_Preferences_cookie()">
  <div class="icon-accpet-cookie-smile" style="display: none;">
    <img src="assets/images/smile-cookie.gif" alt="icon-cookie">
  </div>
</a>

<div class="popup_privacyCookie _new_accpet_cookie" id="popup_privacyCookie" style="display: none;">
  <div class="PopupBodyCookie p-relative ">
    <div class="container">
      <div class="col-md-12 col-sm-12 col-12">
        <div class="row">
          <div class="row main-accpet-cookie">
            <div class="col-md-10 col-sm-9 col-12">
              <div class="box-text-cookie">
                <div class="icon-accpet-cookie">
                  <img src="assets/images/smile-cookie.gif" alt="icon-cookie">
                </div>
                <div class="detail-accpet-cookie">
                  <span>เราใช้คุกกี้เพื่อให้ท่านได้ข้อมูลที่ต้องการ แสดงเนื้อหาและโฆษณาให้ตรงกับความสนใจ  รวมถึงเพื่อวิเคราะห์การเข้าใช้งานเว็บไซต์และทำความเข้าใจว่าผู้ใช้งานมาจากที่ใด หากกด “ยอมรับทั้งหมด” ถือเป็นการยอมรับ นโยบายของเรา</span>
                    <a href="javascript:void(0)" (click)="Change_Preferences_cookie();">
                      <span class="box-link-cookie">
                        การตั้งค่าคุกกี้
                      </span>
                    </a>
                </div>
              </div>
            </div>

            <div class="col-md-2 col-sm-3 col-12">
              <div class="icon-accpet-cookie">
                <a href="javascript:void(0);" (click)="accetp_cookie()">
                  <span class="comfirm-accpet-cookie mt-7">ยอมรับทั้งหมด</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" tabindex="-1" role="dialog" id="Change_Preferences_cookie">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">การตั้งค่าความเป็นส่วนตัว</h4>
      </div>
      <div class="modal-body">
        <div class="box-btn-Accept-cookie">
          <a href="javascript:void(0)" type="button" class="comfirm-accpet-cookie" (click)="accetp_cookie()">ยอมรับทั้งหมด</a>
        </div>

        <div class="box-detile-cookie">
          <div class="form-group">
            <div class="box-head-and-swidth">
              <div class="box-title">
                <label class="title-box-cookie">
                  คุกกี้พื้นฐานที่จำเป็น &nbsp;
                  <span class="title-box-cookie">(เปิดใช้งานตลอดเวลา)</span>
                </label>

              </div>
            </div>
            <p class="box-text-cookie-detail">
              เพื่อช่วยให้การทำงานหลักของเว็บไซต์ใช้งานได้ รวมถึงการเข้าถึงพื้นที่ที่ปลอดภัยต่าง ๆ ของเว็บไซต์ หากไม่มีคุกกี้นี้เว็บไซต์จะไม่สามารถทำงานได้อย่างเหมาะสม และจะใช้งานได้โดยการตั้งค่าเริ่มต้น โดยไม่สามารถปิดการใช้งานได้
            </p>
          </div>
          <hr>
          <div class="form-group">
            <div class="box-head-and-swidth">
              <label class="title-box-cookie">คุกกี้ในส่วนวิเคราะห์</label>
              <div class="material-switch pull-right" >
                <input id="Analytics_cookies" name="Analytics_cookies" type="checkbox"/>
                <label for="Analytics_cookies" class="label-primary"></label>
              </div>
            </div>
            <p class="box-text-cookie-detail">
              จะช่วยให้เว็บไซต์เข้าใจรูปแบบการใช้งานของผู้เข้าชมและจะช่วยปรับปรุงประสบการณ์การใช้งาน โดยการเก็บรวบรวมข้อมูลและรายงานผลการใช้งานของผู้ใช้งาน
            </p>
          </div>
          <hr>
          <div class="form-group">
            <div class="box-head-and-swidth">
              <label class="title-box-cookie"> คุกกี้ในส่วนการตลาด</label>
              <div class="material-switch pull-right">
                <input id="Basic_cookies" name="Basic_cookies" type="checkbox"/>
                <label for="Basic_cookies" class="label-primary"></label>
              </div>
            </div>
            <p class="box-text-cookie-detail">
              ใช้เพื่อติดตามพฤติกรรมผู้เข้าชมเว็บไซต์เพื่อแสดงโฆษณาที่เหมาะสมสำหรับผู้ใช้งานแต่ละรายและเพื่อเพิ่มประสิทธิผลการโฆษณาสำหรับผู้เผยแพร่และผู้โฆษณาสำหรับบุคคลที่สาม
            </p>
          </div>
        </div>

      </div>

      <div class="modal-footer">
        <a href="https://www.livinginsider.com/manual.php?action=MzM=" target="_blank">
          <span class="box-link-cookie">นโยบายคุ้มครองข้อมูลส่วนบุคคล</span>
        </a> &nbsp;
        <button type="button" class="box-accpet" (click)="Confirm_My_Choices()"> ยืนยันตัวเลือกของฉัน</button>
      </div>
    </div>
  </div>
</div> -->

