import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ClaimList } from "../../interfaces/request-claim";

import { environment } from "../../../environments/environment";
@Component({
  selector: 'app-card-history-claim',
  templateUrl: './card-history-claim.component.html',
  styleUrls: ['./card-history-claim.component.scss']
})
export class CardHistoryClaimComponent implements OnInit {

  @Input() historyList: ClaimList;
  @Output() openImage: EventEmitter<any> = new EventEmitter<any>();

  env = environment;
  constructor() { }

  ngOnInit(): void {
    const photo = this.historyList.claim_photo as Array<string>;
    this.historyList.claim_photo = photo.map((e) => {
      const url = `${this.env.api}upload/${this.historyList.claim_folder}/${e}`;
      return url;
    });
  }


  openViewImage(): void {
    this.openImage.emit(this.historyList);
  }





}
