import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';

import { PaymentComponent } from './payments/payment-status/payment.component';

import { InvoiceComponent } from "./page/invoice/invoice.component";

import { RequestClaimComponent } from "./components/request-claim/request-claim.component";

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'invoice/:code', component: InvoiceComponent},
  { path: 'test', component: RequestClaimComponent },
  { path: 'payments/status', component: PaymentComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
