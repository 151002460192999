import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { formatNumber } from '@angular/common';

interface Invoice {
  insure_by?: string;
  insurance_code?: string;
  insurance_id?: string;
  insurance_type_price?: number;
  firstname?: string;
  lastname?: string;
  building_type?: string;
  email?: string;
  phone?: string;
  home_no?: string;
  address?: string;
  district?: string;
  province?: string;
  zipcode?: string;
  id_card?: string;
  benefit_firstname?: string;
  benefit_lastname?: string;
  benefit_id_card?: string;
  benefit_home_no?: string;
  benefit_address?: string;
  benefit_district?: string;
  benefit_province?: string;
  benefit_zipcode?: string;
  insurance_createdate?: string;

}

const PAYMENTS = [
  {img: 'assets/images/payment-1.png', name: 'ธนาคคารกสิกรไทย', branch: 'ถ.เพชรบุรีตัดใหม่', type: 'กระแสรายวัน', cardNo : '0281087444'},
  {img: 'assets/images/payment-2.png', name: 'ธนาคคารกรุงศรีอยุธยา', branch: 'ถ.เพชรบุรีตัดใหม่', type: 'กระแสรายวัน', cardNo : '0340038263'},
  {img: 'assets/images/payment-3.png', name: 'ธนาคคารกรุงเทพ', branch: 'อาคารวานิช', type: 'กระแสรายวัน', cardNo : '2203019498'},
  {img: 'assets/images/payment-4.png', name: 'ธนาคคารไทยพาณิชย์', branch: 'สยามสแควร์', type: 'กระแสรายวัน', cardNo : '0383074605'},
  {img: 'assets/images/payment-5.png', name: 'ธนาคคารทหารไทย', branch: 'อาคารมนูญผล', type: 'กระแสรายวัน', cardNo : '1961021696'},
  {img: 'assets/images/payment-6.png', name: 'ธนาคคารกรุงไทย', branch: 'อโศก', type: 'กระแสรายวัน', cardNo : '0156053047'},
]

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  env = environment;
  invoice:Invoice;
  payments = PAYMENTS;
  public code: string;

  constructor(
    private http:HttpClient,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.code = this.route.snapshot.paramMap.get('code');
    this.getQueryInvoice()
  }

  ngAfterViewInit(): void {

  }

  getQueryInvoice():void {
    const option = {
      params: new HttpParams()
              .set('code', this.code)
    }
    this.http.get(this.env.api+'plus_detail.php', option).subscribe(data => {
      if (data['result_code'] == 0 && data['detail'] != null) {
        const res = data['detail'];
        res.insurance_createdate =  new Date(res.insurance_createdate);
        this.invoice = res;
        // this.invoice.insurance_createdate = new Date(this.invoice.insurance_createdate)
      } else {
        window.location.href="/";
      }
    })
  }
  // https://apitest.livinginsider.com/plus_detail.php?code=5f9809ee17581
}
