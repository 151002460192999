import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import * as moment from 'moment';

import { TEMPCLAIMINFO } from "../../interfaces/request-claim";
declare var $: any;

@Component({
  selector: 'app-card-insurance',
  templateUrl: './card-insurance.component.html',
  styleUrls: ['./card-insurance.component.scss']
})
export class CardInsuranceComponent implements OnInit, AfterViewInit {

  @Input()  insuranceID: number;
  @Input()  insuranceType: number;
  @Input()  postFrom: number;
  @Input()  buildingType: number;
  @Input()  buildingName: string;
  @Input()  firstName: string;
  @Input()  lastName: string;
  @Input()  email: string;
  @Input()  phone: string;
  @Input()  idCard: string;
  @Input()  address: string;
  @Input()  homeNo: string;
  @Input()  district: string;
  @Input()  province: string;
  @Input()  zipCode: string;
  @Input()  dateStart: Date;
  @Input()  dateEnd: Date;
  @Input()  countClaim: number;
  @Input()  insuranceStatus: number;
  @Input()  insuranceMSIGCode: string;
  @Input()  benefitFirstName: string;
  @Input()  benefitLastName: string;
  @Input()  benefitIDCard: string;
  @Input()  benefitAddress: string;
  @Input()  benefitHomeNo: string;
  @Input()  benefitDistrict: string;
  @Input()  benefitProvince: string;
  @Input()  benefitZipCode: string;
  @Input()  insureBy: number;
  @Input()  tempDetail: TEMPCLAIMINFO;
  @Output() openDetail: EventEmitter<any> = new EventEmitter<any>();
  @Output() openHistory: EventEmitter<any> = new EventEmitter<any>();
  @Output() openClaim: EventEmitter<any> = new EventEmitter<any>();
  dateStartStatus: boolean = true;
  dateEndStatus: boolean = true;
  dateNow: Date = new Date();
  constructor() { }

  ngOnInit(): void {
    !moment(this.dateStart).isValid() ?  this.dateStartStatus = false : '';
    !moment(this.dateEnd).isValid() ?  this.dateEndStatus = false : '';
    this.checkBuildingNumber(this.buildingName);
  }

  ngAfterViewInit(): void {
  }

  handleOpenDetail(): void {
    const object: TEMPCLAIMINFO = {
      insuranceID: this.insuranceID,
      insuranceType: this.insuranceType,
      postFrom: this.postFrom,
      buildingType: this.buildingType,
      buildingName: this.buildingName,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      idCard: this.idCard,
      homeNo: this.homeNo,
      address: this.address,
      district: this.district,
      province: this.province,
      zipCode: this.zipCode,
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      benefitFirstName: this.benefitFirstName,
      benefitLastName: this.benefitLastName,
      benefitIDCard: this.benefitIDCard,
      benefitAddress: this.benefitAddress,
      benefitHomeNo: this.benefitHomeNo,
      benefitDistrict: this.benefitDistrict,
      benefitProvince: this.benefitProvince,
      benefitZipCode: this.benefitZipCode,
      insuranceMSIGCode: this.insuranceMSIGCode,
      insureBy: this.insureBy
    };
    this.openDetail.emit(object);
  }

  handleOpenHistory(): void {
    this.openHistory.emit(this.insuranceID);
  }

  handleOpenClaim(): void {
    if (this.insuranceStatus == 1) {
      if (this.dateEnd < this.dateNow) {
        $('#insuranceListModal .modal-content').addClass('overlay');
        $('.card-alert').show();
      } else {
        const object: TEMPCLAIMINFO = {
          insuranceID: this.insuranceID,
          insuranceType: this.insuranceType,
          postFrom: this.postFrom,
          address: this.address,
          buildingType: this.buildingType,
          buildingName: this.buildingName,
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          countClaim: this.countClaim,
          insuranceMSIGCode: this.insuranceMSIGCode
        };
        this.openClaim.emit(object);
      }
    }


  }

  checkBuildingNumber(text: string): void {
    if (text === '1') {
      text = 'คอนโด';
    } else if (text === '2' ) {
      text = 'บ้าน';
    }
    this.buildingName = text;
  }



}
