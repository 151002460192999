import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { RequestClaimComponent } from "../components/request-claim/request-claim.component";

declare var $: any;
const NAVBARS = [
  {link: '#home-page', name: 'หน้าหลัก'},
  {link: '#plan-insurance-msig', name: 'แผนประกันภัย'},
  {link: '#commented-area', name: 'การแจ้งเคลม'},
  {link: '#insurance-qa', name: 'คำถามที่พบบ่อย'},
  {link: '#footer', name: 'ติดต่อเรา'},
]

@Component({
  selector: 'app-headermenu',
  templateUrl: './headermenu.component.html',
  styleUrls: ['./headermenu.component.scss']
})
export class HeadermenuComponent implements OnInit {

  navbars = NAVBARS;
  requestStatus: number = 0;
  urlLine: string = "https://line.me/ti/p/@livinginsure";

  constructor(
    private deviceService: DeviceDetectorService
  ) { }

  ngOnInit(): void {
  }

  public toggleMenu(): void {
    if ($('.navbar-mobile').is(':visible')) {
      $('.navbar-mobile').slideUp();
    } else {
      $('.navbar-mobile').slideDown();
    }
  }

  handleContact(el): void {
    // console.log(el);
    // if (this.requestStatus === 0) {
    this.requestStatus = 1;
    // }
    // if (this.deviceService.isDesktop()) {
    //   $('#contactModal').modal();
    // } else {
    //   window.open(this.urlLine, '_blank')
    // }
  }




}
